import React from 'react';
import { routes } from 'routes';

const WriterDetailPage = React.lazy(
    () => import(/* webpackChunkName: "writer-management-detail" */ 'pages/admin/writer/Detail')
);

const MediaDetailPage = React.lazy(
    () => import(/* webpackChunkName: "media-management-detail" */ 'pages/admin/media/Detail')
);

const BusinessDetailPage = React.lazy(
    () => import(/* webpackChunkName: "business-management-detail" */ 'pages/admin/business/Detail')
);

const UserDetailPage = React.lazy(
    () => import(/* webpackChunkName: "user-management-detail" */ 'pages/admin/user/Detail')
);

const PackageDetailPage = React.lazy(
    () => import(/* webpackChunkName: "package-management-detail" */ 'pages/admin/package/Detail')
);

const OrderDetailPage = React.lazy(
    () => import(/* webpackChunkName: "order-management-detail" */ 'pages/admin/order/Detail')
);

export const privateRoutesAdmin = [
    {
        path: routes.admin.content(),
        component: React.lazy(() => import(/* webpackChunkName: "content-management" */ 'pages/admin/Content')),
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.writer.list(),
        component: React.lazy(() => import(/* webpackChunkName: "writer-management-list" */ 'pages/admin/writer/List')),
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.writer.detail() + `/:id`,
        component: WriterDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.writer.new(),
        component: WriterDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.media.list(),
        component: React.lazy(() => import(/* webpackChunkName: "media-management-list" */ 'pages/admin/media/List')),
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.media.detail() + `/:id`,
        component: MediaDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.media.new(),
        component: MediaDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.business.list(),
        component: React.lazy(
            () => import(/* webpackChunkName: "business-management-list" */ 'pages/admin/business/List')
        ),
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.business.detail() + `/:id`,
        component: BusinessDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.business.new(),
        component: BusinessDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.user.list(),
        component: React.lazy(() => import(/* webpackChunkName: "user-management-list" */ 'pages/admin/user/List')),
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.user.detail() + `/:id`,
        component: UserDetailPage,
        exact: true,
    },
    {
        path: routes.admin.registrationManagement.user.new(),
        component: UserDetailPage,
        exact: true,
    },
    {
        path: routes.admin.packageManagement.list(),
        component: React.lazy(
            () => import(/* webpackChunkName: "package-management-list" */ 'pages/admin/package/List')
        ),
        exact: true,
    },
    {
        path: routes.admin.packageManagement.detail() + `/:id`,
        component: PackageDetailPage,
        exact: true,
    },
    {
        path: routes.admin.packageManagement.edit() + `/:id`,
        component: PackageDetailPage,
        exact: true,
    },
    {
        path: routes.admin.packageManagement.new(),
        component: PackageDetailPage,
        exact: true,
    },
    {
        path: routes.admin.orderManagement.list(),
        component: React.lazy(() => import(/* webpackChunkName: "order-management-list" */ 'pages/admin/order/List')),
        exact: true,
    },
    {
        path: routes.admin.orderManagement.detail() + `/:id`,
        component: OrderDetailPage,
        exact: true,
    },
    {
        path: routes.admin.orderManagement.edit() + `/:id`,
        component: OrderDetailPage,
        exact: true,
    },
    {
        path: routes.admin.moderateComments.list(),
        component: React.lazy(() => import(/* webpackChunkName: "comment-list" */ 'pages/admin/comments/List')),
        exact: true,
    },
    {
        path: routes.admin.moderateComments.new(),
        component: React.lazy(() => import(/* webpackChunkName: "comment-new" */ 'pages/admin/comments/New')),
        exact: true,
    },
    {
        path: routes.admin.packageLocationManagement.list(),
        component: React.lazy(
            () => import(/* webpackChunkName: "package-location" */ 'pages/admin/packageLocation/List')
        ),
        exact: true,
    },
    {
        path: '*',
        component: React.lazy(() => import(/* webpackChunkName: "not-found" */ 'pages/error/NotFound')),
    },
];

export default privateRoutesAdmin;
