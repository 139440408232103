import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'constants/index';

dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);

export const getDuration = (startTime = new Date(), endTime: any) => {
    return dayjs.duration(dayjs(endTime, DEFAULT_TIME_FORMAT).diff(dayjs(startTime)));
};

export const dateFormat = (
    input: any,
    { format = DEFAULT_DATE_FORMAT, locale = 'vi', timezone = 'Asia/Ho_Chi_Minh' } = {}
) => {
    dayjs.locale(locale);
    const dateObject = dayjs(input);

    if (dateObject.isValid()) {
        const formattedDate = dateObject.tz(timezone).format(format);
        return formattedDate;
    }

    return input;
};

export const dayJS = dayjs;
