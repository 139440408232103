import React from 'react';
import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;

const Error = ({ message }: { message: any }) => (
    <Result
        status="error"
        title="Có lỗi xảy ra"
        subTitle="Vui lòng kiểm tra lại thông tin dưới đây."
        extra={[
            <Button type="primary" key="reload" onClick={window.location.reload}>
                Tải lại trang
            </Button>,
        ]}
    >
        <div className="desc">
            <Paragraph>
                <Text
                    strong
                    style={{
                        fontSize: 16,
                    }}
                >
                    Danh sách lỗi:
                </Text>
            </Paragraph>
            <Paragraph>
                <CloseCircleOutlined />
                {message}
            </Paragraph>
        </div>
    </Result>
);

export default Error;
