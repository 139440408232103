import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';

import Header from './Header';
import Footer from './Footer';

const { Content } = Layout;

export interface AdminLayoutProps {
    children: React.ReactNode;
}

const UserLayout: FunctionComponent<AdminLayoutProps> = ({ children }) => {
    return (
        <Layout>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </Layout>
    );
};

export default UserLayout;
