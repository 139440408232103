import React, { Suspense } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { useCheckAuthQuery, useCheckAuthAdminQuery } from 'services/graphql/generated';
import { localS } from 'utils/helpers';
import { useAuth } from 'utils/hooks';
import { routes } from 'routes';
import UserLayout from 'components/layout/user/UserLayout';
import GlobalLoading from 'components/common/Loading';
import PrivateRouteUser from './PrivateRouteUser';
import PrivateRouteAdmin from './PrivateRouteAdmin';
import publicRoutes from './ListPublicRoutes';
import privateRoutesAdmin from './ListAdminRoutes';
import privateRoutesUser from './ListUserRoutes';

const LoginPageUser = React.lazy(() => import(/* webpackChunkName: "login-user" */ 'pages/user/login'));

const LoginPageAdmin = React.lazy(() => import(/* webpackChunkName: "login-admin" */ 'pages/admin/login'));

const FacebookCallbackPage = React.lazy(
    () => import(/* webpackChunkName: "facebook-callback" */ 'pages/user/login/FacebookCallback')
);

const LinkedinCallbackPage = React.lazy(
    () => import(/* webpackChunkName: "linkedin-callback" */ 'pages/user/login/LinkedinCallback')
);

const PaypalCallbackPage = React.lazy(
    () => import(/* webpackChunkName: "paypal-callback" */ 'pages/user/payment/PaypalCallback')
);

const RegisterPage = React.lazy(() => import(/* webpackChunkName: "register" */ 'pages/user/register'));

const ChangeEmailPage = React.lazy(() => import(/* webpackChunkName: "account-change-email" */ 'pages/user/Account'));

const ResetEmailPage = React.lazy(
    () => import(/* webpackChunkName: "account-reset-email" */ 'pages/user/account/ResetEmail')
);

const history = createBrowserHistory();

const withAuth = (Component: React.ComponentType<any>) => {
    const { pathname } = history.location;
    const isAdmin = pathname.includes('/admin');

    return () => {
        const [{ isLoggedIn }, setAuth] = useAuth();
        const { loading } = (isAdmin ? useCheckAuthAdminQuery : useCheckAuthQuery)({
            variables: { token: { token: localS.get('token') } },
            onCompleted: (result: any) => {
                const { success, user, role, permission } = result[isAdmin ? 'checkAuthAdmin' : 'checkAuth'];

                if (success) {
                    setAuth({ user, role, permission, isLoggedIn: true });
                }
            },
        });

        if (loading) {
            return <GlobalLoading />;
        }

        return <Component isLoggedIn={isLoggedIn} />;
    };
};

const AppRoutes: React.FunctionComponent<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
    const { pathname } = history.location;
    const redirectPathUser = [
        routes.root(),
        routes.user.login(),
        routes.user.register(),
        routes.user.confirmAccount(),
        routes.user.changeEmail(),
        routes.user.resetEmail(),
    ].includes(pathname)
        ? routes.user.homepage()
        : pathname;

    const redirectPathAdmin = [routes.admin.login()].includes(pathname) ? routes.admin.content() : pathname;

    return (
        <Suspense fallback={<GlobalLoading global />}>
            <Router>
                <Switch>
                    <Route path={routes.user.login()} exact>
                        {isLoggedIn ? <Redirect to={redirectPathUser} /> : <LoginPageUser />}
                    </Route>
                    <Route path={routes.user.register()} exact>
                        {isLoggedIn ? <Redirect to={redirectPathUser} /> : <RegisterPage />}
                    </Route>
                    <Route path={routes.user.confirmAccount()} exact>
                        {isLoggedIn ? <Redirect to={redirectPathUser} /> : <LoginPageUser />}
                    </Route>
                    <Route path={routes.user.resetPassword()} exact>
                        {isLoggedIn ? <Redirect to={redirectPathUser} /> : <LoginPageUser />}
                    </Route>
                    <Route path={routes.user.changeEmail()} exact>
                        <ChangeEmailPage />
                    </Route>
                    <Route path={routes.user.resetEmail()} exact>
                        <ResetEmailPage />
                    </Route>
                    <Route path={routes.user.facebookCallback()} exact>
                        <FacebookCallbackPage />
                    </Route>
                    <Route path={routes.user.linkedinCallback()} exact>
                        <LinkedinCallbackPage />
                    </Route>
                    <Route path={routes.user.paypalCallback()} exact>
                        <PaypalCallbackPage />
                    </Route>
                    {publicRoutes.map(({ component: Component, ...rest }) => (
                        <Route key={rest.path} {...rest}>
                            <UserLayout>
                                <Component />
                            </UserLayout>
                        </Route>
                    ))}
                    {privateRoutesUser.map(({ component: Component, ...rest }) => (
                        <PrivateRouteUser key={rest.path} {...rest} isLoggedIn={isLoggedIn}>
                            <Component />
                        </PrivateRouteUser>
                    ))}
                    <Route path={routes.admin.login()} exact>
                        {isLoggedIn ? <Redirect to={redirectPathAdmin} /> : <LoginPageAdmin />}
                    </Route>
                    {privateRoutesAdmin.map(({ component: Component, ...rest }) => (
                        <PrivateRouteAdmin key={rest.path} {...rest} isLoggedIn={isLoggedIn}>
                            <Component />
                        </PrivateRouteAdmin>
                    ))}
                </Switch>
            </Router>
        </Suspense>
    );
};

export default withAuth(AppRoutes);
