import Dexie, { Table } from 'dexie';

export interface CartOrderItem {
    id?: number;
    country?: any;
    countryId?: string;
    city?: any;
    cityId?: any;
    mediaType?: string;
    package?: any;
    packageId?: string;
    orderItemURL?: File;
    remark?: string;
    quantity: number;
}

class OrderItemFileListDexie extends Dexie {
    items!: Table<CartOrderItem>;

    constructor() {
        super('tuihand');
        this.version(1).stores({
            items: '++id, country, countryId, city, cityId, mediaType, package, packageId, remark, orderItemURL, quantity',
        });
    }
}

export const db = new OrderItemFileListDexie();

export const addItem = async (orderItem: CartOrderItem) => {
    await db.items.add({
        ...orderItem,
    });
};

export const updateItem = async (id: number, orderItem: CartOrderItem) => {
    await db.items.update(id, {
        ...orderItem,
    });
};

export const removeItem = async (id: number) => {
    await db.items.delete(id);
};

export const getItem = async (id: number) => {
    const item = await db.items.get(id);
    return item;
};

export const getCartCount = async () => {
    let count = 0;
    await db.items.each((item) => {
        count = item.quantity + count;
    });
    return count;
};

export const getAllItems = async () => {
    return await db.items.toArray();
};

export const clearCartDb = async () => {
    await db.items.clear();
};

export const filterDb = async (filters: any) => {
    return await db.items.filter(filters).toArray();
};
