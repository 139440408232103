import React from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';

import styles from 'components/common/Loading.module.scss';

const Loading = ({ global }: any) => {
    const root: any = document.getElementById('root');

    const Loading = ({ global }: any) => (
        <div
            className={cn(styles.loading, {
                [styles.global]: global,
            })}
        >
            <Spin indicator={<LoadingOutlined spin className={styles.loadingIcon} />} tip="Loading" />
        </div>
    );

    if (global) {
        return ReactDOM.createPortal(<Loading global={true} />, root);
    }

    return <Loading />;
};

export default Loading;
