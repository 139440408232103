import React, { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';

import AdminLayout from 'components/layout/admin/AdminLayout';
import { routes } from 'routes';

export interface PrivateRouteProps {
    isLoggedIn: boolean;
    path: string;
    children: React.ReactNode;
}

const PrivateRouteAdmin: FunctionComponent<PrivateRouteProps> = ({ isLoggedIn, children, ...props }) => (
    <Route
        {...props}
        render={({ location }) => {
            return isLoggedIn ? (
                <AdminLayout>{children}</AdminLayout>
            ) : (
                <Redirect
                    to={{
                        pathname: location.pathname.includes('admin') ? routes.admin.login() : routes.user.homepage(),
                        state: { from: location },
                    }}
                />
            );
        }}
    />
);

export default PrivateRouteAdmin;
