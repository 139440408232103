import React, { FunctionComponent, useState } from 'react';
import { Layout, BackTop } from 'antd';

import BreadcrumbLayout from './BreadcrumbLayout';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from 'components/common/Footer';
import styles from './AdminLayout.module.scss';

export interface AdminLayoutProps {
    children: React.ReactNode;
}

const links = [
    {
        key: 'privacyPolicy',
        title: 'Privacy Policy',
        href: '',
    },
    {
        key: 'terms',
        title: 'Terms & Conditions',
        href: '',
    },
    {
        key: 'contact',
        title: 'Contact Us',
        href: '',
    },
];

export const CollapseContext = React.createContext<any | undefined>(undefined);

const AdminLayout: FunctionComponent<AdminLayoutProps> = ({ children }) => {
    const [collapsed, setCollapse] = useState<boolean>(false);
    const _handleCollapse = () => setCollapse(!collapsed);

    return (
        <Layout className={styles.layout}>
            <Sidebar collapsed={collapsed} />
            <div className={styles.container} id="adminLayoutContainer">
                <Header collapsed={collapsed} />
                <BreadcrumbLayout />
                <CollapseContext.Provider value={{ collapsed, _handleCollapse }}>
                    <Layout.Content className={styles.content}>{children}</Layout.Content>
                </CollapseContext.Provider>
                <BackTop className={styles.backTop} />
                <Footer links={links} copyright="&copy;&nbsp;&nbsp;2021 FortuneTimes" />
            </div>
        </Layout>
    );
};

export default AdminLayout;
