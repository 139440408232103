/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, FunctionComponent } from 'react';
import styles from 'components/common/Footer.module.scss';

export interface FooterProps {
    links?: Array<{
        key?: string;
        title: React.ReactNode;
        href: string;
        blankTarget?: boolean;
    }>;
    copyright?: React.ReactNode;
    style?: React.CSSProperties;
}

const Footer: FunctionComponent<FooterProps> = ({ links, copyright }) => (
    <footer className={styles.footer}>
        {copyright && <>{copyright}</>}
        <span className={styles.links}>
            {copyright ? ' | ' : ''}
            {links &&
                links.map((link, index) => (
                    <Fragment key={link.key}>
                        <a title={link.key} target={link.blankTarget ? '_blank' : '_self'} href={link.href}>
                            {link.title}
                        </a>
                        {index + 1 < links.length ? ' | ' : ''}
                    </Fragment>
                ))}
        </span>
    </footer>
);

export default Footer;
