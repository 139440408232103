const config: any = {
    IS_DEV: process.env.NODE_ENV === 'development',
    IS_STAG: process.env.REACT_APP_ENV === 'staging',
    IS_PROD: process.env.NODE_ENV === 'production',
    GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT || '/graphql',
    RESTAPI_ENDPOINT: process.env.RESTAPI_ENDPOINT || '/api',
    SECRET: process.env.SECRET || 'ixN0-Vqnj9JAQzE(u*Z59xj#8ZKujr%w', // 32 chars required
    SECRET2: process.env.SECRET2 || '5hjPruZauQWed9EOcNHbQlGNITdcsMOO',
    SECRET_ENCRYPT: process.env.SECRET_ENCRYPT || 'w1z9YG3vJbVThy9a', // 16 chars required,
    UPLOAD_FOLDER: process.env.UPLOAD_FOLDER || 'tuihand',
    FACEBOOK_CLIENT_ID: '292819955610382',
    FACEBOOK_CLIENT_SECRET: '54239aed3da6ab145109cb8e25fb3fd6',
};

config.PORT = process.env.PORT || (config.IS_STAG ? '' : config.IS_PROD ? '' : `:${8000}`);

config.API_PORT = process.env.API_PORT || (config.IS_STAG ? '' : config.IS_PROD ? `:${8001}` : `:${8001}`);

config.WEB_PROTOCOL = process.env.WEB_PROTOCOL || `http${config.IS_STAG ? 's' : config.IS_PROD ? '' : ''}`;

config.SOCKET_PROTOCOL = process.env.SOCKET_PROTOCOL || `ws${config.IS_STAG ? 's' : config.IS_PROD ? '' : ''}`;

config.CLIENT_HOST_NAME =
    process.env.CLIENT_HOST_NAME ||
    (config.IS_STAG ? 'staging.dabvrsr02vw6p.amplifyapp.com' : config.IS_PROD ? 'www.tuihand.com' : 'localhost');

config.CLIENT_HTTP_URI =
    process.env.CLIENT_HTTP_URI || `${config.WEB_PROTOCOL}://${config.CLIENT_HOST_NAME}${config.PORT}`;

config.API_HOST_NAME =
    process.env.API_HOST_NAME ||
    (config.IS_STAG ? '18-140-150-238.nip.io' : config.IS_PROD ? '8.214.21.181' : 'localhost');

config.RESTAPI_HTTP_URI =
    process.env.RESTAPI_HTTP_URI ||
    `${config.WEB_PROTOCOL}://${config.API_HOST_NAME}${config.API_PORT}${config.RESTAPI_ENDPOINT}`;

config.GRAPHQL_URI =
    process.env.GRAPHQL_URI ||
    `${config.WEB_PROTOCOL}://${config.API_HOST_NAME}${config.API_PORT}${config.GRAPHQL_ENDPOINT}`;

config.GRAPHQL_WS_URI =
    process.env.GRAPHQL_WS_URI ||
    `${config.SOCKET_PROTOCOL}://${config.API_HOST_NAME}${config.API_PORT}${config.GRAPHQL_ENDPOINT}`;

config.IMAGE_HOST = process.env.IMAGE_HOST || `${config.WEB_PROTOCOL}://${config.API_HOST_NAME}${config.API_PORT}/`;

config.RECAPTCHA_KEY = config.IS_STAG
    ? '6LfUiyIfAAAAAIu5uEBxFfCSDGK6-RjbwJ4vNw4z'
    : config.IS_PROD
    ? '6LfuqeAfAAAAACuHwip4B4jI4N80HqTRYts5x_pe'
    : '6LfUiyIfAAAAAIu5uEBxFfCSDGK6-RjbwJ4vNw4z';

config.RECAPTCHA_URL =
    config.IS_STAG || config.IS_PROD
        ? 'https://www.google.com/recaptcha/api.js?render='
        : 'https://www.google.com/recaptcha/api.js?render=';

export default config;
