import { routes } from 'routes';

export interface IBreadcrumbs {
    key: string;
    title?: string;
    href?: string;
}

export interface IBreadcrumbsRoute {
    key: string;
    breadcrumbs?: Array<IBreadcrumbs>;
}

const breadCrumbsRoute: IBreadcrumbsRoute[] = [
    {
        key: routes.admin.content(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Content Management',
            },
            {
                key: '2',
                title: 'Content List',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.business.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Business Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.business.new(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Business Registration',
                href: routes.admin.registrationManagement.business.list(),
            },
            {
                key: '3',
                title: 'New Business Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.business.detail(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Business Registration',
                href: routes.admin.registrationManagement.business.list(),
            },
            {
                key: '3',
                title: 'Edit Business Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.user.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'User Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.user.new(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'User Registration',
                href: routes.admin.registrationManagement.user.list(),
            },
            {
                key: '3',
                title: 'New User Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.user.detail(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'User Registration',
                href: routes.admin.registrationManagement.user.list(),
            },
            {
                key: '3',
                title: 'Edit User',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.writer.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Writer Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.writer.new(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Writer Registration',
                href: routes.admin.registrationManagement.writer.list(),
            },
            {
                key: '3',
                title: 'New Writer Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.writer.detail(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Writer Registration',
                href: routes.admin.registrationManagement.writer.list(),
            },
            {
                key: '3',
                title: 'Edit Writer Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.media.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Media Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.media.new(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Media Registration',
                href: routes.admin.registrationManagement.media.list(),
            },
            {
                key: '3',
                title: 'New Media Registration',
            },
        ],
    },
    {
        key: routes.admin.registrationManagement.media.detail(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Registration Management',
            },
            {
                key: '2',
                title: 'Media Registration',
                href: routes.admin.registrationManagement.media.list(),
            },
            {
                key: '3',
                title: 'Edit Media Registration',
            },
        ],
    },
    {
        key: routes.admin.orderManagement.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Order Management',
            },
            {
                key: '2',
                title: 'Order List Management',
            },
        ],
    },
    {
        key: routes.admin.orderManagement.detail(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Order Management',
            },
            {
                key: '2',
                title: 'Order List Management',
                href: routes.admin.orderManagement.list(),
            },
            {
                key: '3',
                title: 'Order Details',
            },
        ],
    },
    {
        key: routes.admin.orderManagement.edit(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Order Management',
            },
            {
                key: '2',
                title: 'Order List Management',
                href: routes.admin.orderManagement.list(),
            },
            {
                key: '3',
                title: 'Edit Order',
            },
        ],
    },
    {
        key: routes.admin.packageManagement.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Package Management',
            },
            {
                key: '2',
                title: 'Package List Management',
            },
        ],
    },
    {
        key: routes.admin.packageManagement.new(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Package Management',
            },
            {
                key: '2',
                title: 'Package List Management',
                href: routes.admin.packageManagement.list(),
            },
            {
                key: '3',
                title: 'New Package',
            },
        ],
    },
    {
        key: routes.admin.packageManagement.detail(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Package Management',
            },
            {
                key: '2',
                title: 'Package List Management',
                href: routes.admin.packageManagement.list(),
            },
            {
                key: '3',
                title: 'Package Details',
            },
        ],
    },
    {
        key: routes.admin.packageManagement.edit(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Package Management',
            },
            {
                key: '2',
                title: 'Package List Management',
                href: routes.admin.packageManagement.list(),
            },
            {
                key: '3',
                title: 'Edit Package',
            },
        ],
    },
    {
        key: routes.admin.packageLocationManagement.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Package Location Management',
            },
            {
                key: '2',
                title: 'Package Location List Management',
            },
        ],
    },
    {
        key: routes.admin.moderateComments.list(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Moderate Comments',
            },
            {
                key: '2',
                title: 'Comments List',
            },
        ],
    },
    {
        key: routes.admin.moderateComments.new(),
        breadcrumbs: [
            {
                key: '1',
                title: 'Moderate Comments',
            },
            {
                key: '2',
                title: 'New Comments',
            },
        ],
    },
];

export default breadCrumbsRoute;
