import { ApolloClient, ApolloLink, NormalizedCacheObject, gql } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { message } from 'antd';

import cache from './cache';
import config from '../../../config';

export const typeDefs = gql`
    extend type Query {
        isLoggedIn: Boolean!
    }
`;

const uploadLink = createUploadLink({
    uri: config.GRAPHQL_URI,
    credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message: description, locations, path }) => {
            message.error({
                content: description,
                duration: 3, //3s
            });
            // eslint-disable-next-line no-console
            return console.error(`[GraphQL error]: Message: ${description}, Location: ${locations}, Path: ${path}`);
        });

    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([errorLink, uploadLink]);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link,
    cache,
    typeDefs,
    connectToDevTools: true,
});
