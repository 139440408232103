import React from 'react';
import { routes } from 'routes';

export const publicRoutes = [
    {
        path: routes.user.homepage(),
        component: React.lazy(() => import(/* webpackChunkName: "homepage" */ 'pages/user/Homepage')),
        exact: true,
    },
    {
        path: routes.user.contact(),
        component: React.lazy(() => import(/* webpackChunkName: "contact" */ 'pages/user/Contact')),
        exact: true,
    },
    {
        path: routes.user.package.list() + `/:id`,
        component: React.lazy(() => import(/* webpackChunkName: "package-list" */ 'pages/user/package/List')),
        exact: true,
    },
    {
        path: routes.user.package.detail() + `/:id`,
        component: React.lazy(() => import(/* webpackChunkName: "package-detail" */ 'pages/user/package/Detail')),
        exact: true,
    },
    {
        path: routes.user.package.customize(),
        component: React.lazy(() => import(/* webpackChunkName: "package-customize" */ 'pages/user/package/Customize')),
        exact: true,
    },
    {
        path: routes.user.speech(),
        component: React.lazy(() => import(/* webpackChunkName: "speech" */ 'pages/user/Speech')),
        exact: true,
    },
];

export default publicRoutes;
