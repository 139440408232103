export function isInt(n: any) {
    return Number(n) === n && n % 1 === 0;
}

export function truncateDecimal(n: any) {
    return isInt(n) ? n : n?.toFixed(2);
}

export function moneyFormat(
    amount = 0 as number,
    currency = 'đ' as string,
    decimalCount = 0 as number,
    decimal = '.' as string,
    thousands = ',' as string
) {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        const i = isInt(amount)
            ? parseInt(Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
            : Math.abs(Number(amount) || 0).toString();
        const j = Math.abs(Number(amount) || 0) >= 1000 ? (i.length > 3 ? i.length % 3 : 0) : 0;

        return (
            negativeSign +
            `${currency} ` +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - parseInt(i))
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (e) {
        throw new Error('Money format is fail!');
    }
}
