import { Upload, message } from 'antd';
import config from '../../config';

export const getFileName = (url: any) => url.split(/(\\|\/)/g).pop();

export const formFileHandler = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export const beforeUploadFileHandler = (file: any) => {
    const fileLimit = 2 * 1024 * 1024; // 2MB
    const isFileBig = file.size > fileLimit;
    const emptyFile = file.size <= 0;
    if (isFileBig) {
        message.error(`${file.name} is larger than 2MB`);
    }
    if (emptyFile) {
        message.error(`${file.name} is empty`);
    }
    return (!emptyFile && !isFileBig) || Upload.LIST_IGNORE;
};

export const upload = {
    setDefaultFileList: (urls: any) => {
        if (!urls || urls instanceof File) {
            return [];
        }

        if (Array.isArray(urls)) {
            if (urls.some((file) => file instanceof File)) {
                return [];
            }
            return urls.map((url) => {
                if (typeof url === 'string') {
                    const name = getFileName(url);
                    return { uid: name, url, name };
                }
                return url;
            });
        } else {
            const name = getFileName(urls);
            return [{ uid: name, url: urls, name }];
        }
    },
    singleFileUpload: async (uploadType = 'unknown', file: any, id: any) => {
        try {
            const formData = new FormData();
            formData.append('type', uploadType);
            formData.append('userId', id);
            formData.append('file', file);

            return new Promise((resolve, reject) => {
                fetch(`${config.RESTAPI_HTTP_URI}/uploadCloudinary/single`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((res) => resolve(res.json()))
                    .catch((err) => reject(err));
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            throw new Error('Lỗi trong quá trình upload!');
        }
    },
    multipleFileUpload: async (uploadType = 'unknown', fileList: any, id: any) => {
        return Promise.all(fileList.map((file: any) => upload.singleFileUpload(uploadType, file, id)));
    },
    deleteFile: async (file: any) => {
        try {
            const deleted: any = await new Promise((resolve, reject) => {
                fetch(`${config.RESTAPI_HTTP_URI}/uploadCloudinary/single?link=${file.url}`, {
                    method: 'DELETE',
                })
                    .then((res) => resolve(res.json()))
                    .catch((err) => {
                        reject(err);
                    });
            });

            if (deleted.http_code === 400) {
                return false;
            }

            if (deleted.result === 'ok') {
                return true;
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            throw new Error('Lỗi trong quá trình xóa file!');
        }
    },
};
