import React from 'react';

import weChatImg from 'assets/images/social/wechat.png';

import styles from './WeChatPopoverContent.module.scss';

const Content = () => (
    <div className={styles.content}>
        <span>Please scan QR using WeChat</span>
        <div>
            <img src={weChatImg} className={styles.qr} />
        </div>
    </div>
);

export default Content;
