import React, { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';

import UserLayout from 'components/layout/user/UserLayout';
import { routes } from 'routes';

export interface PrivateRouteProps {
    isLoggedIn: boolean;
    path: string;
    children: React.ReactNode;
}

const PrivateRouteUser: FunctionComponent<PrivateRouteProps> = ({ isLoggedIn, children, ...props }) => (
    <Route
        {...props}
        render={({ location }) => {
            return isLoggedIn ? (
                <UserLayout>{children}</UserLayout>
            ) : (
                <Redirect
                    to={{
                        pathname: routes.user.login(),
                        state: { from: location },
                    }}
                />
            );
        }}
    />
);

export default PrivateRouteUser;
