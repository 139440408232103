import short from 'short-uuid';

export * from './localStorage';
export * from './moneyFormat';
export * from './datetime';
export * from './upload';

export const recursiveMap = (arr: any, key: any, callback: any) =>
    arr.map((cat: any) => ({
        ...callback(cat),
        [key]: cat[key] && cat[key].length > 0 ? recursiveMap(cat[key], key, callback) : [],
    }));

export const uuidTranslator = short();

export const removeAccent = (str: string) => str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

const addressParseSingle = (address: any) => {
    return address
        ? `${address.street}, ${address.wardName}, ${address.districtName}, ${address.cityName}`
        : 'Chưa có địa chỉ';
};

export const addressParse = (address: any) => {
    if (Array.isArray(address)) {
        return addressParseSingle(address.find((add) => Boolean(add.isDefault)));
    }
    return addressParseSingle(address);
};
