import { useReducer } from 'react';

type State = {
    token: string;
    verified: boolean;
};

const initialState = {
    token: '',
    verified: false,
};

type Action = { type: 'RESET' } | { type: 'UPDATE'; value: Partial<State> };

const reaptchaReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'RESET':
            return { ...initialState };
        case 'UPDATE':
            return { ...state, ...action.value };
        default:
            return state;
    }
};

export const useReaptchaReducer = ({ resetCaptcha }: any) => {
    const [state, dispatch] = useReducer(reaptchaReducer, initialState);

    const resetRecaptcha = () => {
        resetCaptcha();
        dispatch({
            type: 'UPDATE',
            value: {
                verified: false,
            },
        });
    };

    const onVerify = (token: string) => {
        dispatch({
            type: 'UPDATE',
            value: {
                token,
                verified: true,
            },
        });
    };

    const onExpire = () => {
        dispatch({ type: 'UPDATE', value: { verified: false } });
    };

    return {
        captchaState: state,
        onVerify,
        onExpire,
        resetRecaptcha,
    };
};

export default useReaptchaReducer;
