import React, { FunctionComponent, useEffect } from 'react';
import { Avatar, Layout, Menu, notification, Button, Badge, Popover } from 'antd';
import {
    UserOutlined,
    LinkedinOutlined,
    FacebookOutlined,
    ShoppingCartOutlined,
    WechatOutlined,
} from '@ant-design/icons';
import { useLiveQuery } from 'dexie-react-hooks';
import { Link, useLocation, useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ReactLanguageSelect from 'react-languages-select';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import { SOCIAL } from 'constants/index';
import { useGetProfileLazyQuery, useGetPackageLocationsQuery } from 'services/graphql/generated';
import Content from 'components/common/WeChatPopoverContent';
import Loading from 'components/common/Loading';
import Error from 'components/common/Error';
import { localS } from 'utils/helpers';
import { getCartCount, clearCartDb } from 'utils/helpers/cartFileStorage';
import { useAuth } from 'utils/hooks';
import { useLogoutMutation } from 'services/graphql/generated';
import { routes } from 'routes';
import logo from 'assets/images/logo.png';
import us from 'assets/images/us.svg';
import ch from 'assets/images/ch.svg';
import styles from './Header.module.scss';

const Header: FunctionComponent = () => {
    const history = useHistory();
    const [auth, setAuth] = useAuth();

    const {
        loading: loadingGetPackageLocations,
        error: errorGetPackageLocations,
        data: dataGetPackageLocations,
    } = useGetPackageLocationsQuery();

    const [getProfile]: any = useGetProfileLazyQuery({
        fetchPolicy: 'no-cache',
        onCompleted({ getProfile }) {
            const { success, result } = getProfile || {};

            if (success) {
                const businessRegistration: any =
                    result?.businessRegistration?.isDel === false ? result?.businessRegistration ?? null : null;
                localS.set('business-registration', businessRegistration);
            }
        },
    });

    const cartCount = useLiveQuery(async () => await getCartCount(), []) || 0;
    const location = useLocation();
    const [hookLogout] = useLogoutMutation({
        onCompleted() {
            localS.clear();
            clearCartDb();
            setAuth({ isLoggedIn: false });
            notification.success({ message: 'Logout successfully.' });
        },
        onError: () => {
            notification.error({ message: 'Something happened!' });
        },
    });

    const _handlePressRelease = () => {
        if (localS.get('business-registration')) {
            notification.warn({
                message: 'You already finished register business information.',
            });
        } else {
            history.push(routes.user.company());
        }
    };

    useEffect(() => {
        if (auth.isLoggedIn) {
            getProfile({ variables: { id: auth?.user?.id } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    if (loadingGetPackageLocations) {
        return <Loading global={true} />;
    }

    if (errorGetPackageLocations) {
        return <Error message={errorGetPackageLocations} />;
    }

    const packageLocationList = dataGetPackageLocations?.getPackageLocations?.result ?? [];
    localS.set('package-locations', packageLocationList);

    return (
        <>
            <Link to={routes.user.homepage()} className={styles.mobileLogo} style={{ textAlign: 'center' }}>
                <img className={styles.logo} src={logo} />
            </Link>
            <Layout.Header
                className={cn(styles.top, { [styles.mobile]: isMobile })}
                id={isMobile ? 'topMenuMobile' : 'topMenu'}
            >
                <div className={styles.social}>
                    FOLLOW US:{' '}
                    <Popover placement="bottomRight" content={Content} trigger="click">
                        <a>
                            <WechatOutlined className={styles.socialItem} />
                        </a>
                    </Popover>
                    {/* <a href="#" target="_blank" rel="noreferrer">
                        <InstagramOutlined className={styles.socialItem} />
                    </a> */}
                    <a href={SOCIAL.linkedIn} target="_blank" rel="noreferrer">
                        <LinkedinOutlined className={styles.socialItem} />
                    </a>
                    <a href={SOCIAL.facebook} target="_blank" rel="noreferrer">
                        <FacebookOutlined className={styles.socialItem} />
                    </a>
                    {isMobile && (
                        <ReactLanguageSelect
                            defaultLanguage="en"
                            languages={['en', 'ch']}
                            customLabels={{
                                en: (
                                    <>
                                        <img src={us} style={{ width: '20px', marginRight: '10px' }} />
                                        EN
                                    </>
                                ),
                                ch: (
                                    <>
                                        <img src={ch} style={{ width: '20px', marginRight: '10px' }} />
                                        中文
                                    </>
                                ),
                            }}
                            selectedSize={14}
                            optionsSize={14}
                        />
                    )}
                </div>
                <Menu mode="horizontal" className={styles.topMenu} defaultSelectedKeys={[location.pathname]}>
                    <Menu.Item key={routes.user.writer()}>
                        <Link to={routes.user.writer()} className={styles.topLink}>
                            WRITER
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={routes.user.media()}>
                        <Link to={routes.user.media()} className={styles.topLink}>
                            MEDIA
                        </Link>
                    </Menu.Item>
                    {auth.user ? (
                        <>
                            <Menu.SubMenu
                                key="sub2"
                                icon={<Avatar size="large" src={auth.user.avatar} icon={<UserOutlined />} />}
                                title={auth.user.fullName}
                            >
                                <Menu.Item key="logout" danger onClick={() => hookLogout()}>
                                    Logout
                                </Menu.Item>
                            </Menu.SubMenu>
                            <Menu.Item key={routes.user.cart()}>
                                <Badge count={cartCount}>
                                    <Link to={routes.user.cart()}>
                                        <ShoppingCartOutlined />
                                    </Link>
                                </Badge>
                            </Menu.Item>
                        </>
                    ) : (
                        <>
                            <Menu.Item key="register">
                                <Link to={routes.user.register()}>REGISTER</Link>
                            </Menu.Item>
                            <Menu.Item key="login">
                                <Link to={routes.user.login()}>LOGIN</Link>
                            </Menu.Item>
                        </>
                    )}
                    {!isMobile && (
                        <Menu.Item key="language">
                            <ReactLanguageSelect
                                defaultLanguage="en"
                                languages={['en', 'ch']}
                                customLabels={{
                                    en: (
                                        <>
                                            <img src={us} style={{ width: '20px', marginRight: '10px' }} />
                                            EN
                                        </>
                                    ),
                                    ch: (
                                        <>
                                            <img src={ch} style={{ width: '20px', marginRight: '10px' }} />
                                            中文
                                        </>
                                    ),
                                }}
                                selectedSize={14}
                                optionsSize={14}
                            />
                        </Menu.Item>
                    )}
                </Menu>
            </Layout.Header>
            <Layout.Header className={cn(styles.navigator)}>
                {!isMobile && (
                    <Link to={routes.user.homepage()}>
                        <img className={styles.logo} src={logo} />
                    </Link>
                )}
                <div className={styles.linkWrapper}>
                    <Menu mode="horizontal" className={styles.bottomMenu} defaultSelectedKeys={[location.pathname]}>
                        <Menu.Item className={styles.navigatorLink} key={routes.user.homepage()}>
                            <Link to={routes.user.homepage()}>HOME</Link>
                        </Menu.Item>
                        <Menu.SubMenu className={styles.navigatorLink} title="PACKAGE" key="package">
                            {packageLocationList?.map((packageLocation: any) => {
                                return (
                                    <Menu.Item key={routes.user.package.list() + `/${packageLocation.id}`}>
                                        <Link to={routes.user.package.list() + `/${packageLocation.id}`}>
                                            {packageLocation.name}
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                        </Menu.SubMenu>
                        <Menu.Item className={styles.navigatorLink} key={routes.user.account() + `/information`}>
                            <Link to={routes.user.account() + `/information`}>ACCOUNT</Link>
                        </Menu.Item>
                        <Menu.Item className={styles.navigatorLink} key={routes.user.contact()}>
                            <Link to={routes.user.contact()}>CONTACT</Link>
                        </Menu.Item>
                    </Menu>
                </div>
                <div className={styles.pressReleaseWrapper}>
                    <Button
                        type="primary"
                        shape="round"
                        className={styles.pressRelease}
                        size="large"
                        onClick={_handlePressRelease}
                    >
                        Press Release Distribution
                    </Button>
                </div>
            </Layout.Header>
        </>
    );
};

export default Header;
