import React from 'react';
import { routes } from 'routes';

export const privateRoutesUser = [
    {
        path: routes.user.company(),
        component: React.lazy(() => import(/* webpackChunkName: "company" */ 'pages/user/Company')),
        exact: true,
    },
    {
        path: routes.user.media(),
        component: React.lazy(() => import(/* webpackChunkName: "media" */ 'pages/user/media')),
        exact: true,
    },
    {
        path: routes.user.writer(),
        component: React.lazy(() => import(/* webpackChunkName: "writer" */ 'pages/user/writer')),
        exact: true,
    },
    {
        path: routes.user.payment(),
        component: React.lazy(() => import(/* webpackChunkName: "payment" */ 'pages/user/payment')),
        exact: true,
    },
    {
        path: routes.user.cart(),
        component: React.lazy(() => import(/* webpackChunkName: "cart" */ 'pages/user/cart')),
        exact: true,
    },
    {
        path: routes.user.account() + `/:menu`,
        component: React.lazy(() => import(/* webpackChunkName: "account" */ 'pages/user/Account')),
        exact: true,
    },
    {
        path: routes.user.order.detail() + `/:id`,
        component: React.lazy(() => import(/* webpackChunkName: "order-detail" */ 'pages/user/account/OrderDetail')),
        exact: true,
    },
];

export default privateRoutesUser;
