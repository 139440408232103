import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';

import RoutesWithAuth from 'routes/RoutesWithAuth';
import { client } from 'services/graphql/core';

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import 'react-sortable-tree/style.css';
import 'react-dropdown-tree-select/dist/styles.css';
import 'react-languages-select/css/react-languages-select.css';

import './index.scss';

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <RoutesWithAuth />
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
