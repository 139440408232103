/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type Address = {
    __typename?: 'Address';
    cityId: Scalars['Int'];
    cityName: Scalars['String'];
    districtId: Scalars['Int'];
    districtName: Scalars['String'];
    isDefault?: Maybe<Scalars['Boolean']>;
    street: Scalars['String'];
    wardId: Scalars['Int'];
    wardName: Scalars['String'];
};

export type Api = {
    __typename?: 'Api';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    requiredRole?: Maybe<Array<Maybe<Scalars['String']>>>;
    updatedAt: Scalars['DateTime'];
};

export type ApiResponse = {
    __typename?: 'ApiResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Api>;
    success: Scalars['Boolean'];
};

export type ApisResponse = {
    __typename?: 'ApisResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<Api>>>;
    success: Scalars['Boolean'];
};

export type Auth = {
    __typename?: 'Auth';
    createdAt: Scalars['DateTime'];
    device?: Maybe<Scalars['String']>;
    expiredDate: Scalars['DateTime'];
    id: Scalars['Int'];
    ipAddress?: Maybe<Scalars['String']>;
    isDel: Scalars['Boolean'];
    sessionId?: Maybe<Scalars['String']>;
    token: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    user: User;
    userId?: Maybe<Scalars['Int']>;
};

export type BusinessItem = {
    __typename?: 'BusinessItem';
    businessType?: Maybe<BusinessType>;
    businessTypeId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type BusinessItemResponse = {
    __typename?: 'BusinessItemResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<BusinessItem>;
    success: Scalars['Boolean'];
};

export type BusinessRegistration = {
    __typename?: 'BusinessRegistration';
    annualSales: Array<Maybe<Scalars['String']>>;
    businessRegistrationURL?: Maybe<Scalars['String']>;
    companyAddress: Scalars['String'];
    companyIndustryClassifications?: Maybe<Array<Maybe<CompanyIndustryClassification>>>;
    companyName: Scalars['String'];
    companyWeb: Scalars['String'];
    contactPersonName: Scalars['String'];
    contactPersonTitle: Scalars['String'];
    country: Scalars['String'];
    createdBy?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    facebookAccount: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    numberOfEmployees: Scalars['Int'];
    otherContent?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
    registrationNumber: Scalars['String'];
    typesCompany: Array<Maybe<Scalars['String']>>;
    updatedBy?: Maybe<Scalars['String']>;
    userCreated?: Maybe<User>;
    weChatAccount: Scalars['String'];
};

export type BusinessRegistrationPagination = {
    __typename?: 'BusinessRegistrationPagination';
    docs?: Maybe<Array<Maybe<BusinessRegistration>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type BusinessRegistrationResponse = {
    __typename?: 'BusinessRegistrationResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<BusinessRegistration>;
    success: Scalars['Boolean'];
};

export type BusinessRegistrationsResponse = {
    __typename?: 'BusinessRegistrationsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<BusinessRegistrationPagination>;
    success: Scalars['Boolean'];
};

export type BusinessType = {
    __typename?: 'BusinessType';
    businessItems?: Maybe<Array<Maybe<BusinessItem>>>;
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type BusinessTypeItems = {
    id: Scalars['String'];
    items: Array<Scalars['String']>;
};

export type BusinessTypeResponse = {
    __typename?: 'BusinessTypeResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<BusinessType>;
    success: Scalars['Boolean'];
};

export type BusinessTypesResponse = {
    __typename?: 'BusinessTypesResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<BusinessType>>>;
    success: Scalars['Boolean'];
};

export type City = {
    __typename?: 'City';
    countryId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type CityResponse = {
    __typename?: 'CityResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<City>;
    success: Scalars['Boolean'];
};

export type ClearTestDataInput = {
    secret: Scalars['String'];
    username?: Maybe<Scalars['String']>;
};

export type Comment = {
    __typename?: 'Comment';
    approvedBy?: Maybe<Scalars['String']>;
    content: Scalars['String'];
    createdAt: Scalars['DateTime'];
    createdBy: Scalars['String'];
    hide?: Maybe<Scalars['Boolean']>;
    id: Scalars['String'];
    isApproved?: Maybe<Scalars['Boolean']>;
    isDel?: Maybe<Scalars['Boolean']>;
    package?: Maybe<Package>;
    packageId: Scalars['String'];
    rateStar: Scalars['Float'];
    rejectedBy?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    updatedBy?: Maybe<Scalars['String']>;
    userApproved?: Maybe<User>;
    userCreated?: Maybe<User>;
    userRejected?: Maybe<User>;
    userUpdated?: Maybe<User>;
};

export type CommentOrderResponse = {
    __typename?: 'CommentOrderResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type CommentPagination = {
    __typename?: 'CommentPagination';
    docs?: Maybe<Array<Maybe<Comment>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type CommentResponse = {
    __typename?: 'CommentResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Comment>;
    success: Scalars['Boolean'];
};

export type CommentsResponse = {
    __typename?: 'CommentsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<CommentPagination>;
    success: Scalars['Boolean'];
};

export type CompanyIndustryClassification = {
    __typename?: 'CompanyIndustryClassification';
    businessItems?: Maybe<Array<Scalars['String']>>;
    businessRegistrationId?: Maybe<Scalars['String']>;
    businessType?: Maybe<Array<Maybe<BusinessType>>>;
    businessTypeId: Scalars['String'];
    customBusinessId?: Maybe<Scalars['String']>;
    id: Scalars['String'];
};

export type CompanyIndustryClassificationInformation = {
    __typename?: 'CompanyIndustryClassificationInformation';
    businessItems?: Maybe<Array<Maybe<Scalars['String']>>>;
    businessTypeId: Scalars['String'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['String'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type Contact = {
    __typename?: 'Contact';
    createdAt: Scalars['DateTime'];
    email: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    message: Scalars['String'];
    name: Scalars['String'];
    phone: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type CountUnreadResponse = {
    __typename?: 'CountUnreadResponse';
    count: Scalars['Int'];
};

export type CountriesResponse = {
    __typename?: 'CountriesResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<Country>>>;
    success: Scalars['Boolean'];
};

export type Country = {
    __typename?: 'Country';
    cities?: Maybe<Array<Maybe<City>>>;
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type CountryResponse = {
    __typename?: 'CountryResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Country>;
    success: Scalars['Boolean'];
};

export type Coupon = {
    __typename?: 'Coupon';
    code: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    updatedAt: Scalars['DateTime'];
    value: Array<Maybe<CouponValue>>;
};

export type CouponResponse = {
    __typename?: 'CouponResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Coupon>;
    success: Scalars['Boolean'];
};

export type CouponValue = {
    __typename?: 'CouponValue';
    unit: Scalars['String'];
    value: Scalars['Int'];
};

export type CustomBusiness = {
    __typename?: 'CustomBusiness';
    city: Scalars['String'];
    createdBy?: Maybe<Scalars['String']>;
    customBusinessURL?: Maybe<Scalars['String']>;
    formatPublications: Array<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    needTranslateService: Scalars['Boolean'];
    platformPublications: Array<Scalars['String']>;
    postingLocation: Array<Scalars['String']>;
    publishingLanguage: Array<Scalars['String']>;
    readerAge: Array<Scalars['String']>;
    readerOccupations: Array<Scalars['String']>;
    updatedBy?: Maybe<Scalars['String']>;
};

export type CustomBusinessResponse = {
    __typename?: 'CustomBusinessResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<CustomBusiness>;
    success: Scalars['Boolean'];
};

export type DeleteBusinessRegistrationResponse = {
    __typename?: 'DeleteBusinessRegistrationResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteCityResponse = {
    __typename?: 'DeleteCityResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteCountryResponse = {
    __typename?: 'DeleteCountryResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteMediaResponse = {
    __typename?: 'DeleteMediaResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteOrderItemResponse = {
    __typename?: 'DeleteOrderItemResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteOrdersResponse = {
    __typename?: 'DeleteOrdersResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeletePackageResponse = {
    __typename?: 'DeletePackageResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type DeleteWriterResponse = {
    __typename?: 'DeleteWriterResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type FormatPublication = {
    __typename?: 'FormatPublication';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type FormatPublicationResponse = {
    __typename?: 'FormatPublicationResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<FormatPublication>;
    success: Scalars['Boolean'];
};

export type FormatPublicationsResponse = {
    __typename?: 'FormatPublicationsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<FormatPublication>>>;
    success: Scalars['Boolean'];
};

export type InputActivationAccount = {
    activeToken: Scalars['String'];
    email: Scalars['String'];
};

export type InputAdminChangePassword = {
    confirmPassword: Scalars['String'];
    password: Scalars['String'];
};

export type InputAdminForgotPassword = {
    email: Scalars['String'];
};

export type InputApiCreate = {
    name: Scalars['String'];
    requiredRole: Array<Maybe<Scalars['String']>>;
};

export type InputApiDelete = {
    id: Scalars['String'];
};

export type InputApiGet = {
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    requiredRole?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputApiUpdate = {
    name: Scalars['String'];
    requiredRole: Array<Maybe<Scalars['String']>>;
};

export type InputAuthLogin = {
    identify: Scalars['String'];
    password: Scalars['String'];
    recaptcha_token: Scalars['String'];
    remember_me?: Maybe<Scalars['Boolean']>;
};

export type InputAuthRegister = {
    email: Scalars['String'];
    fullName: Scalars['String'];
    password: Scalars['String'];
    recaptcha_token: Scalars['String'];
};

export type InputAuthToken = {
    token?: Maybe<Scalars['String']>;
};

export type InputBusinessItemCreate = {
    businessTypeId: Scalars['String'];
    name: Scalars['String'];
};

export type InputBusinessRegistrationCreate = {
    annualSales: Array<Maybe<Scalars['String']>>;
    businessTypeItems?: Maybe<Array<Maybe<BusinessTypeItems>>>;
    companyAddress: Scalars['String'];
    companyName: Scalars['String'];
    companyWeb: Scalars['String'];
    contactPersonName: Scalars['String'];
    contactPersonTitle: Scalars['String'];
    country: Scalars['String'];
    email: Scalars['String'];
    facebookAccount: Scalars['String'];
    numberOfEmployees: Scalars['Int'];
    otherContent?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
    registrationNumber: Scalars['String'];
    typesCompany: Array<Maybe<Scalars['String']>>;
    weChatAccount: Scalars['String'];
};

export type InputBusinessRegistrationId = {
    id: Scalars['String'];
};

export type InputBusinessRegistrationUpdate = {
    annualSales: Array<Maybe<Scalars['String']>>;
    businessTypeItems?: Maybe<Array<Maybe<BusinessTypeItems>>>;
    companyAddress: Scalars['String'];
    companyName: Scalars['String'];
    companyWeb: Scalars['String'];
    contactPersonName: Scalars['String'];
    contactPersonTitle: Scalars['String'];
    country: Scalars['String'];
    email: Scalars['String'];
    facebookAccount: Scalars['String'];
    id: Scalars['String'];
    numberOfEmployees: Scalars['Int'];
    otherContent?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
    registrationNumber: Scalars['String'];
    typesCompany: Array<Maybe<Scalars['String']>>;
    weChatAccount: Scalars['String'];
};

export type InputBusinessTypeCreate = {
    name: Scalars['String'];
};

export type InputCityCreate = {
    countryId: Scalars['String'];
    name: Scalars['String'];
};

export type InputCityUpdate = {
    countryId: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
};

export type InputCommentCreate = {
    content: Scalars['String'];
    packageId: Scalars['String'];
    rateStar: Scalars['Float'];
};

export type InputCommentOrder = {
    comment?: Maybe<Scalars['String']>;
    orderId: Scalars['String'];
};

export type InputContact = {
    email: Scalars['String'];
    message: Scalars['String'];
    name: Scalars['String'];
    phone: Scalars['String'];
};

export type InputCountryCreate = {
    name: Scalars['String'];
};

export type InputCountryUpdate = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type InputCouponCreate = {
    code: Scalars['String'];
    value: Array<Maybe<InputCouponValue>>;
};

export type InputCouponValue = {
    unit: Scalars['String'];
    value: Scalars['Int'];
};

export type InputCreateCompanyIndustryClassificationInformation = {
    businessItems?: Maybe<Array<Maybe<Scalars['String']>>>;
    businessTypeId: Scalars['String'];
};

export type InputCustomBusinessCreate = {
    businessTypeItems: Array<BusinessTypeItems>;
    city: Scalars['String'];
    formatPublications: Array<Scalars['String']>;
    needTranslateService: Scalars['Boolean'];
    platformPublications: Array<Scalars['String']>;
    postingLocation: Array<Scalars['String']>;
    publishingLanguage: Array<Scalars['String']>;
    readerAge: Array<Scalars['String']>;
    readerOccupations: Array<Scalars['String']>;
};

export type InputFacebookUser = {
    email?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    providerKey: Scalars['String'];
};

export type InputFormatPublicationCreate = {
    name: Scalars['String'];
};

export type InputHideComment = {
    commentIds?: Maybe<Array<Scalars['String']>>;
    hide?: Maybe<Scalars['Boolean']>;
};

export type InputManuscriptReviewCreate = {
    isPass: Scalars['Boolean'];
    orderItemId: Scalars['String'];
    pressRelease: Scalars['String'];
    reason: Scalars['String'];
};

export type InputMediaCreate = {
    address: Scalars['String'];
    contactPersonName: Scalars['String'];
    contactPersonTitle: Scalars['String'];
    email: Scalars['String'];
    established: Scalars['String'];
    mediaName: Scalars['String'];
    mediaWeb: Scalars['String'];
    newsCategoriesCovered: Scalars['String'];
    receivingAccount: Scalars['String'];
    registrationNumber: Scalars['String'];
};

export type InputMediaListReviewCreate = {
    firstMedia: Scalars['String'];
    orderItemId: Scalars['String'];
    secondMedia: Scalars['String'];
    thirdMedia: Scalars['String'];
};

export type InputMediaUpdate = {
    address: Scalars['String'];
    contactPersonName: Scalars['String'];
    contactPersonTitle: Scalars['String'];
    email: Scalars['String'];
    established: Scalars['String'];
    id: Scalars['String'];
    mediaName: Scalars['String'];
    mediaWeb: Scalars['String'];
    newsCategoriesCovered: Scalars['String'];
    receivingAccount: Scalars['String'];
    registrationNumber: Scalars['String'];
};

export type InputOrderCreate = {
    couponCode?: Maybe<Scalars['String']>;
    orderItems: Array<InputOrderItemCreate>;
    otherCost: Scalars['Int'];
    paymentInformation?: Maybe<InputPaymentInformationCreate>;
};

export type InputOrderItemCreate = {
    cityId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    mediaType?: Maybe<Scalars['String']>;
    orderItemURL?: Maybe<Scalars['String']>;
    packageId: Scalars['String'];
    quantity: Scalars['Int'];
    remark?: Maybe<Scalars['String']>;
};

export type InputOrderItemUpdate = {
    cityId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    mediaType?: Maybe<MediaType>;
    orderItemId: Scalars['String'];
    orderItemURL?: Maybe<Scalars['String']>;
};

export type InputOrderUpdate = {
    endDate?: Maybe<Scalars['DateTime']>;
    orderId?: Maybe<Scalars['String']>;
    status?: Maybe<OrderStatus>;
};

export type InputPackageCreate = {
    content?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    otherIndex?: Maybe<Array<Maybe<Scalars['String']>>>;
    packageLocationId?: Maybe<Scalars['String']>;
    parentPackageId?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    supplement?: Maybe<Scalars['String']>;
};

export type InputPackageLocationCreate = {
    name: Scalars['String'];
};

export type InputPackageLocationUpdate = {
    name?: Maybe<Scalars['String']>;
    packageLocationId: Scalars['String'];
};

export type InputPackageUpdate = {
    content?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    name: Scalars['String'];
    otherIndex?: Maybe<Array<Maybe<Scalars['String']>>>;
    packageLocationId?: Maybe<Scalars['String']>;
    parentPackageId?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    supplement?: Maybe<Scalars['String']>;
};

export type InputPagingRequest = {
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagination?: Maybe<Scalars['Boolean']>;
    populate?: Maybe<Scalars['JSON']>;
    query?: Maybe<Scalars['JSON']>;
    sort?: Maybe<Scalars['JSON']>;
};

export type InputPaymentInformationCreate = {
    address: Scalars['String'];
    companyName: Scalars['String'];
    contactPersonName: Scalars['String'];
    email: Scalars['String'];
    paymentDate?: Maybe<Scalars['DateTime']>;
    phoneNumber: Scalars['String'];
};

export type InputPermissionCreate = {
    name: Scalars['String'];
};

export type InputPermissionDeleteRecover = {
    id: Scalars['String'];
};

export type InputPermissionGet = {
    id: Scalars['String'];
};

export type InputPermissionUpdate = {
    id: Scalars['String'];
    name?: Maybe<Scalars['String']>;
};

export type InputPlatformPublicationCreate = {
    name: Scalars['String'];
};

export type InputPublishLinkReviewCreate = {
    firstPublishLink: Scalars['String'];
    orderItemId: Scalars['String'];
    secondPublishLink: Scalars['String'];
    thirdPublishLink: Scalars['String'];
};

export type InputPublishTimeReviewCreate = {
    firstPublicationTime: Scalars['String'];
    orderItemId: Scalars['String'];
    secondPublicationTime: Scalars['String'];
    thirdPublicationTime: Scalars['String'];
};

export type InputReaderOccupationCreate = {
    name: Scalars['String'];
};

export type InputReportReviewCreate = {
    orderItemId: Scalars['String'];
};

export type InputResendActivationAccount = {
    email: Scalars['String'];
};

export type InputRoleCreate = {
    name: Scalars['String'];
};

export type InputRoleDeleteRecover = {
    id: Scalars['String'];
};

export type InputRoleGet = {
    id: Scalars['String'];
};

export type InputRoleUpdate = {
    id: Scalars['String'];
    name?: Maybe<Scalars['String']>;
};

export type InputUserChangeEmail = {
    email: Scalars['String'];
};

export type InputUserChangePass = {
    confirmPass: Scalars['String'];
    id: Scalars['String'];
    newPass: Scalars['String'];
    oldPass: Scalars['String'];
};

export type InputUserConfirmAccount = {
    newEmail: Scalars['String'];
    resetEmailToken: Scalars['String'];
};

export type InputUserCreate = {
    email: Scalars['String'];
    fullName: Scalars['String'];
    permissionId?: Maybe<Array<Maybe<Scalars['String']>>>;
    roleId: Scalars['String'];
};

export type InputUserForgotPass = {
    email?: Maybe<Scalars['String']>;
};

export type InputUserGet = {
    id: Scalars['String'];
    populate?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputUserId = {
    id: Scalars['String'];
};

export type InputUserResetEmail = {
    changeEmailToken: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
};

export type InputUserUpdate = {
    activeToken?: Maybe<Scalars['String']>;
    activeTokenExpires?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    permissionId?: Maybe<Array<Maybe<Scalars['String']>>>;
    roleId?: Maybe<Scalars['String']>;
    status?: Maybe<UserStatus>;
};

export type InputUserUpdatePassViaMail = {
    confirmPass: Scalars['String'];
    email: Scalars['String'];
    newPass: Scalars['String'];
    resetPasswordToken: Scalars['String'];
};

export type InputUserVerifyResetPassToken = {
    resetPasswordToken: Scalars['String'];
};

export type InputWriterCreate = {
    address: Scalars['String'];
    company: Scalars['String'];
    email: Scalars['String'];
    name: Scalars['String'];
    nationality: Scalars['String'];
    newsCategory: Scalars['String'];
    profession: Scalars['String'];
    receivingAccount: Scalars['String'];
};

export type InputWriterUpdate = {
    address: Scalars['String'];
    company: Scalars['String'];
    email: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
    nationality: Scalars['String'];
    newsCategory: Scalars['String'];
    profession: Scalars['String'];
    receivingAccount: Scalars['String'];
};

export type ListMediaResponse = {
    __typename?: 'ListMediaResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<MediaPagination>;
    success: Scalars['Boolean'];
};

export type ManuscriptReview = {
    __typename?: 'ManuscriptReview';
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    isPass: Scalars['Boolean'];
    pressRelease: Scalars['String'];
    reason: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type ManuscriptReviewResponse = {
    __typename?: 'ManuscriptReviewResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<ManuscriptReview>;
    success: Scalars['Boolean'];
};

export type Media = {
    __typename?: 'Media';
    address?: Maybe<Scalars['String']>;
    contactPersonName?: Maybe<Scalars['String']>;
    contactPersonTitle?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    createdBy?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    established?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    isDel?: Maybe<Scalars['Boolean']>;
    mediaName?: Maybe<Scalars['String']>;
    mediaURL?: Maybe<Array<Maybe<Scalars['String']>>>;
    mediaWeb?: Maybe<Scalars['String']>;
    newsCategoriesCovered?: Maybe<Scalars['String']>;
    receivingAccount?: Maybe<Scalars['String']>;
    registrationNumber?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    updatedBy?: Maybe<Scalars['String']>;
    userCreated?: Maybe<User>;
};

export type MediaListReview = {
    __typename?: 'MediaListReview';
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    firstMedia: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    orderItemId: Scalars['String'];
    secondMedia: Scalars['String'];
    thirdMedia: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type MediaListReviewResponse = {
    __typename?: 'MediaListReviewResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<MediaListReview>;
    success: Scalars['Boolean'];
};

export type MediaPagination = {
    __typename?: 'MediaPagination';
    docs?: Maybe<Array<Maybe<Media>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type MediaResponse = {
    __typename?: 'MediaResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Media>;
    success: Scalars['Boolean'];
};

export enum MediaType {
    Broadcast = 'broadcast',
    Facebook = 'facebook',
    HeadlineNumber = 'headline_number',
    Linkedin = 'linkedin',
    Magazine = 'magazine',
    NewMedia = 'new_media',
    Newspaper = 'newspaper',
    Television = 'television',
    Twitter = 'twitter',
    Website = 'website',
    WechatPublicAccount = 'wechat_public_account',
    Weibo = 'weibo',
    Youtube = 'youtube',
}

export type MediasResponse = {
    __typename?: 'MediasResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<MediaPagination>;
    success: Scalars['Boolean'];
};

export type Mutation = {
    __typename?: 'Mutation';
    activationAccount: UserResponse;
    approveComments: StatusResponse;
    changeAdminPassword: UserResponse;
    changeEmail: UserResponse;
    changePass: UserResponse;
    clearTestData: AdminUtilsResponse;
    confirmAccount: UserResponse;
    createApi?: Maybe<ApiResponse>;
    createBusinessItem: BusinessItemResponse;
    createBusinessRegistration: BusinessRegistrationResponse;
    createBusinessType: BusinessTypeResponse;
    createCity: CityResponse;
    createComment: CommentResponse;
    createContact: ContactResponse;
    createCountry: CountryResponse;
    createCoupon: CouponResponse;
    createCustomBusiness: CustomBusinessResponse;
    createFormatPublication: FormatPublicationResponse;
    createManuscriptReview: ManuscriptReviewResponse;
    createMedia: MediaResponse;
    createMediaListReview: MediaListReviewResponse;
    createOrder: OrderResponse;
    createPackage: PackageResponse;
    createPackageLocation: PackageLocationResponse;
    createPermission: PermissionResponse;
    createPlatformPublication: PlatformPublicationResponse;
    createPublishLinkReview: PublishLinkReviewResponse;
    createPublishTimeReview: PublishTimeReviewResponse;
    createReaderOccupation: ReaderOccupationResponse;
    createReportReview: ReportReviewResponse;
    createRole: RoleResponse;
    createUser: UserResponse;
    createWriter: WriterResponse;
    deleteApi?: Maybe<ApiResponse>;
    deleteBusinessRegistrations: DeleteBusinessRegistrationResponse;
    deleteCities: DeleteCityResponse;
    deleteComments: StatusResponse;
    deleteCountries: DeleteCountryResponse;
    deleteMedias: DeleteMediaResponse;
    deleteOrderItem: DeleteOrderItemResponse;
    deleteOrders: DeleteOrdersResponse;
    deletePackages: DeletePackageResponse;
    deletePermission: PermissionResponse;
    deleteRole: RoleResponse;
    deleteUser: UserResponse;
    deleteWriters: DeleteWriterResponse;
    forgotAdminPassword: UserResponse;
    forgotPass: UserResponse;
    hideComment: StatusResponse;
    login: AuthResponse;
    loginAdmin: AuthResponse;
    loginFacebook: AuthResponse;
    logout: AuthResponse;
    readNotification: NotificationResponse;
    readNotificationMessage: NotificationMessageResponse;
    recoverPermission: PermissionResponse;
    recoverRole: RoleResponse;
    recoverUser: UserResponse;
    register: AuthResponse;
    rejectComments: StatusResponse;
    removeBusinessRegistrationFile: RemoveBusinessRegistrationFileResponse;
    removeMediaFile: RemoveMediaFileResponse;
    removeOrderItemFile: RemoveOrderItemResponse;
    removePackageFile: RemovePackageResponse;
    removeUser: UserResponse;
    removeWriterFile: RemoveWriterFileResponse;
    resendActivationAccount: UserResponse;
    resetEmail: UserResponse;
    updateApi?: Maybe<ApiResponse>;
    updateBusinessRegistration: BusinessRegistrationResponse;
    updateCity: CityResponse;
    updateCountry: CountryResponse;
    updateMedia: MediaResponse;
    updateOrderComment: CommentOrderResponse;
    updateOrderItem: UpdateOrderItemResponse;
    updateOrderStatus: UpdateOrderResponse;
    updatePackage: PackageResponse;
    updatePackageLocation: PackageLocationResponse;
    updatePassViaMail: UserResponse;
    updatePermission: PermissionResponse;
    updateRole: RoleResponse;
    updateUser: UserResponse;
    updateWriter: WriterResponse;
    uploadBusinessRegistrationFile: UploadBusinessRegistrationFileResponse;
    uploadCustomBusinessFile: UploadCustomBusinessResponse;
    uploadMediaFile: UploadMediaFileResponse;
    uploadOrderItemFile: UploadOrderItemFileResponse;
    uploadPackageFile: UploadPackageFileResponse;
    uploadReportReviewFile: UploadReportReviewFileResponse;
    uploadWriterFile: UploadWriterFileResponse;
    verifyResetPassToken: UserResponse;
};

export type MutationActivationAccountArgs = {
    user: InputActivationAccount;
};

export type MutationApproveCommentsArgs = {
    commentIds: Array<Maybe<Scalars['String']>>;
};

export type MutationChangeAdminPasswordArgs = {
    admin: InputAdminChangePassword;
};

export type MutationChangeEmailArgs = {
    user: InputUserChangeEmail;
};

export type MutationChangePassArgs = {
    user: InputUserChangePass;
};

export type MutationClearTestDataArgs = {
    testData: ClearTestDataInput;
};

export type MutationConfirmAccountArgs = {
    user: InputUserConfirmAccount;
};

export type MutationCreateApiArgs = {
    api: InputApiCreate;
};

export type MutationCreateBusinessItemArgs = {
    businessItem: InputBusinessItemCreate;
};

export type MutationCreateBusinessRegistrationArgs = {
    businessRegistration: InputBusinessRegistrationCreate;
};

export type MutationCreateBusinessTypeArgs = {
    businessType: InputBusinessTypeCreate;
};

export type MutationCreateCityArgs = {
    city: InputCityCreate;
};

export type MutationCreateCommentArgs = {
    comment: InputCommentCreate;
};

export type MutationCreateContactArgs = {
    contact: InputContact;
};

export type MutationCreateCountryArgs = {
    country: InputCountryCreate;
};

export type MutationCreateCouponArgs = {
    coupon: InputCouponCreate;
};

export type MutationCreateCustomBusinessArgs = {
    customBusiness: InputCustomBusinessCreate;
};

export type MutationCreateFormatPublicationArgs = {
    formatPublication: InputFormatPublicationCreate;
};

export type MutationCreateManuscriptReviewArgs = {
    manuscriptReview: InputManuscriptReviewCreate;
};

export type MutationCreateMediaArgs = {
    media: InputMediaCreate;
};

export type MutationCreateMediaListReviewArgs = {
    mediaListReview: InputMediaListReviewCreate;
};

export type MutationCreateOrderArgs = {
    order: InputOrderCreate;
};

export type MutationCreatePackageArgs = {
    package: InputPackageCreate;
};

export type MutationCreatePackageLocationArgs = {
    packageLocation: InputPackageLocationCreate;
};

export type MutationCreatePermissionArgs = {
    permission: InputPermissionCreate;
};

export type MutationCreatePlatformPublicationArgs = {
    platformPublication: InputPlatformPublicationCreate;
};

export type MutationCreatePublishLinkReviewArgs = {
    publishLinkReview: InputPublishLinkReviewCreate;
};

export type MutationCreatePublishTimeReviewArgs = {
    publishTimeReview: InputPublishTimeReviewCreate;
};

export type MutationCreateReaderOccupationArgs = {
    readerOccupation: InputReaderOccupationCreate;
};

export type MutationCreateReportReviewArgs = {
    reportReview: InputReportReviewCreate;
};

export type MutationCreateRoleArgs = {
    role: InputRoleCreate;
};

export type MutationCreateUserArgs = {
    user: InputUserCreate;
};

export type MutationCreateWriterArgs = {
    writer: InputWriterCreate;
};

export type MutationDeleteApiArgs = {
    api: InputApiDelete;
};

export type MutationDeleteBusinessRegistrationsArgs = {
    businessRegistrationIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeleteCitiesArgs = {
    cityIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeleteCommentsArgs = {
    commentIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeleteCountriesArgs = {
    countryIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeleteMediasArgs = {
    mediaIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeleteOrderItemArgs = {
    orderItemIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeleteOrdersArgs = {
    orderIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeletePackagesArgs = {
    packageIds: Array<Maybe<Scalars['String']>>;
};

export type MutationDeletePermissionArgs = {
    permission: InputPermissionDeleteRecover;
};

export type MutationDeleteRoleArgs = {
    role: InputRoleDeleteRecover;
};

export type MutationDeleteUserArgs = {
    user: InputUserId;
};

export type MutationDeleteWritersArgs = {
    writerIds: Array<Maybe<Scalars['String']>>;
};

export type MutationForgotAdminPasswordArgs = {
    admin: InputAdminForgotPassword;
};

export type MutationForgotPassArgs = {
    user: InputUserForgotPass;
};

export type MutationHideCommentArgs = {
    comment: InputHideComment;
};

export type MutationLoginArgs = {
    user: InputAuthLogin;
};

export type MutationLoginAdminArgs = {
    user: InputAuthLogin;
};

export type MutationLoginFacebookArgs = {
    facebookUser: InputFacebookUser;
};

export type MutationReadNotificationArgs = {
    notificationId: Scalars['String'];
};

export type MutationReadNotificationMessageArgs = {
    notificationMessageId: Scalars['String'];
};

export type MutationRecoverPermissionArgs = {
    permission: InputPermissionDeleteRecover;
};

export type MutationRecoverRoleArgs = {
    role: InputRoleDeleteRecover;
};

export type MutationRecoverUserArgs = {
    user: InputUserId;
};

export type MutationRegisterArgs = {
    user: InputAuthRegister;
};

export type MutationRejectCommentsArgs = {
    commentIds: Array<Maybe<Scalars['String']>>;
};

export type MutationRemoveBusinessRegistrationFileArgs = {
    businessRegistrationId: Scalars['String'];
};

export type MutationRemoveMediaFileArgs = {
    mediaId?: Maybe<Scalars['String']>;
};

export type MutationRemoveOrderItemFileArgs = {
    orderItemId?: Maybe<Scalars['String']>;
};

export type MutationRemovePackageFileArgs = {
    packageId: Scalars['String'];
};

export type MutationRemoveUserArgs = {
    user: InputUserId;
};

export type MutationRemoveWriterFileArgs = {
    writerId?: Maybe<Scalars['String']>;
};

export type MutationResendActivationAccountArgs = {
    user: InputResendActivationAccount;
};

export type MutationResetEmailArgs = {
    user: InputUserResetEmail;
};

export type MutationUpdateApiArgs = {
    api: InputApiUpdate;
};

export type MutationUpdateBusinessRegistrationArgs = {
    businessRegistration: InputBusinessRegistrationUpdate;
};

export type MutationUpdateCityArgs = {
    city: InputCityUpdate;
};

export type MutationUpdateCountryArgs = {
    country: InputCountryUpdate;
};

export type MutationUpdateMediaArgs = {
    media: InputMediaUpdate;
};

export type MutationUpdateOrderCommentArgs = {
    order: InputCommentOrder;
};

export type MutationUpdateOrderItemArgs = {
    orderItem?: Maybe<InputOrderItemUpdate>;
};

export type MutationUpdateOrderStatusArgs = {
    order: InputOrderUpdate;
};

export type MutationUpdatePackageArgs = {
    package: InputPackageUpdate;
};

export type MutationUpdatePackageLocationArgs = {
    packageLocation: InputPackageLocationUpdate;
};

export type MutationUpdatePassViaMailArgs = {
    user: InputUserUpdatePassViaMail;
};

export type MutationUpdatePermissionArgs = {
    permission: InputPermissionUpdate;
};

export type MutationUpdateRoleArgs = {
    role: InputRoleUpdate;
};

export type MutationUpdateUserArgs = {
    user: InputUserUpdate;
};

export type MutationUpdateWriterArgs = {
    writer: InputWriterUpdate;
};

export type MutationUploadBusinessRegistrationFileArgs = {
    businessRegistrationId: Scalars['String'];
    file: Scalars['Upload'];
};

export type MutationUploadCustomBusinessFileArgs = {
    customBusinessId: Scalars['String'];
    file: Scalars['Upload'];
};

export type MutationUploadMediaFileArgs = {
    file: Array<Maybe<Scalars['Upload']>>;
    mediaId: Scalars['String'];
};

export type MutationUploadOrderItemFileArgs = {
    file: Scalars['Upload'];
};

export type MutationUploadPackageFileArgs = {
    file: Scalars['Upload'];
    packageId: Scalars['String'];
};

export type MutationUploadReportReviewFileArgs = {
    file: Scalars['Upload'];
    orderItemId: Scalars['String'];
    reportReviewId: Scalars['String'];
};

export type MutationUploadWriterFileArgs = {
    file: Array<Maybe<Scalars['Upload']>>;
    writerId: Scalars['String'];
};

export type MutationVerifyResetPassTokenArgs = {
    user: InputUserVerifyResetPassToken;
};

export type Notification = {
    __typename?: 'Notification';
    content: Scalars['String'];
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    readBy: Array<Maybe<Scalars['String']>>;
    receiver: Scalars['String'];
    roleIds: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type NotificationMessage = {
    __typename?: 'NotificationMessage';
    content: Scalars['String'];
    createdAt?: Maybe<Scalars['DateTime']>;
    createdBy: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['String'];
    isRead: Scalars['String'];
    receiver: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    updatedBy: Scalars['String'];
};

export type NotificationMessageResponse = {
    __typename?: 'NotificationMessageResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<NotificationMessage>;
    success: Scalars['Boolean'];
};

export type NotificationPaginate = {
    __typename?: 'NotificationPaginate';
    docs?: Maybe<Array<Maybe<Notification>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type NotificationResponse = {
    __typename?: 'NotificationResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Notification>;
    success: Scalars['Boolean'];
};

export type NotificationsResponse = {
    __typename?: 'NotificationsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<NotificationPaginate>;
    success: Scalars['Boolean'];
};

export type Order = {
    __typename?: 'Order';
    comment?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    isDel?: Maybe<Scalars['Boolean']>;
    orderCode: Scalars['String'];
    orderItems?: Maybe<Array<Maybe<OrderItem>>>;
    otherCost: Scalars['Int'];
    paymentDetail?: Maybe<PaymentDetail>;
    paymentDetailId?: Maybe<Scalars['String']>;
    paymentInformation?: Maybe<PaymentInformation>;
    status?: Maybe<OrderStatus>;
    total: Scalars['Float'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
    userCreated?: Maybe<User>;
};

export type OrderItem = {
    __typename?: 'OrderItem';
    city?: Maybe<City>;
    cityId?: Maybe<Scalars['String']>;
    country?: Maybe<Country>;
    countryId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    mediaListReview?: Maybe<MediaListReview>;
    mediaType?: Maybe<Scalars['String']>;
    order?: Maybe<Order>;
    orderId: Scalars['String'];
    orderItemCode: Scalars['String'];
    orderItemURL?: Maybe<Scalars['String']>;
    orderItemsProcessing?: Maybe<Array<Maybe<OrderItemProcessing>>>;
    package?: Maybe<Package>;
    packageId: Scalars['String'];
    publishLinkReview?: Maybe<PublishLinkReview>;
    publishTimeReview?: Maybe<PublishTimeReview>;
    quantity: Scalars['Int'];
    remark?: Maybe<Scalars['String']>;
    reportReview?: Maybe<ReportReview>;
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type OrderItemPagination = {
    __typename?: 'OrderItemPagination';
    docs?: Maybe<Array<Maybe<OrderItem>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type OrderItemProcessing = {
    __typename?: 'OrderItemProcessing';
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    orderItemId: Scalars['String'];
    status: Scalars['String'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type OrderItemResponse = {
    __typename?: 'OrderItemResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<OrderItemPagination>;
    success: Scalars['Boolean'];
};

export type OrderPagination = {
    __typename?: 'OrderPagination';
    docs?: Maybe<Array<Maybe<Order>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type OrderResponse = {
    __typename?: 'OrderResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Order>;
    success: Scalars['Boolean'];
};

export enum OrderStatus {
    Completed = 'completed',
    Failed = 'failed',
    Process = 'process',
}

export type OrdersResponse = {
    __typename?: 'OrdersResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<OrderPagination>;
    success: Scalars['Boolean'];
};

export type Package = {
    __typename?: 'Package';
    childPackages?: Maybe<Array<Maybe<Package>>>;
    content?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    location: Scalars['String'];
    name: Scalars['String'];
    otherIndex: Array<Maybe<Scalars['String']>>;
    packageCode: Scalars['String'];
    packageImgURL?: Maybe<Scalars['String']>;
    packageLocation?: Maybe<PackageLocation>;
    packageLocationId?: Maybe<Scalars['String']>;
    parentPackage?: Maybe<Package>;
    parentPackageId?: Maybe<Scalars['String']>;
    price: Scalars['Float'];
    supplement?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type PackageLocation = {
    __typename?: 'PackageLocation';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    packages?: Maybe<Array<Maybe<Package>>>;
};

export type PackageLocationPaginate = {
    __typename?: 'PackageLocationPaginate';
    docs?: Maybe<Array<Maybe<PackageLocation>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type PackageLocationResponse = {
    __typename?: 'PackageLocationResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<PackageLocation>;
    success: Scalars['Boolean'];
};

export type PackageLocationsResponse = {
    __typename?: 'PackageLocationsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<PackageLocation>>>;
    success: Scalars['Boolean'];
};

export type PackagePaginate = {
    __typename?: 'PackagePaginate';
    docs?: Maybe<Array<Maybe<Package>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type PackageResponse = {
    __typename?: 'PackageResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Package>;
    success: Scalars['Boolean'];
};

export type PackagesResponse = {
    __typename?: 'PackagesResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<PackagePaginate>;
    success: Scalars['Boolean'];
};

export type PaymentDetail = {
    __typename?: 'PaymentDetail';
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['String'];
    payerId: Scalars['String'];
    payerName: Scalars['String'];
    paymentAmount: Scalars['Float'];
    paymentId: Scalars['String'];
    paymentMethod: Scalars['String'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type PaymentInformation = {
    __typename?: 'PaymentInformation';
    address: Scalars['String'];
    companyName: Scalars['String'];
    contactPersonName: Scalars['String'];
    createdBy?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    orderId: Scalars['String'];
    paymentDate?: Maybe<Scalars['DateTime']>;
    phoneNumber: Scalars['String'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type Permission = {
    __typename?: 'Permission';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type PermissionPaginate = {
    __typename?: 'PermissionPaginate';
    docs?: Maybe<Array<Maybe<Permission>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type PermissionResponse = {
    __typename?: 'PermissionResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Permission>;
    success: Scalars['Boolean'];
};

export type PermissionsResponse = {
    __typename?: 'PermissionsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<PermissionPaginate>;
    success: Scalars['Boolean'];
};

export type PlatformPublication = {
    __typename?: 'PlatformPublication';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type PlatformPublicationResponse = {
    __typename?: 'PlatformPublicationResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<PlatformPublication>;
    success: Scalars['Boolean'];
};

export type PlatformPublicationsResponse = {
    __typename?: 'PlatformPublicationsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<PlatformPublication>>>;
    success: Scalars['Boolean'];
};

export type PublishLinkReview = {
    __typename?: 'PublishLinkReview';
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    firstPublishLink: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    orderItemId: Scalars['String'];
    secondPublishLink: Scalars['String'];
    thirdPublishLink: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type PublishLinkReviewResponse = {
    __typename?: 'PublishLinkReviewResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<PublishLinkReview>;
    success: Scalars['Boolean'];
};

export type PublishTimeReview = {
    __typename?: 'PublishTimeReview';
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    firstPublicationTime: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    orderItemId: Scalars['String'];
    secondPublicationTime: Scalars['String'];
    thirdPublicationTime: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type PublishTimeReviewResponse = {
    __typename?: 'PublishTimeReviewResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<PublishTimeReview>;
    success: Scalars['Boolean'];
};

export type Query = {
    __typename?: 'Query';
    checkAuth: AuthResponse;
    checkAuthAdmin: AuthResponse;
    countUnread: CountUnreadResponse;
    getApi?: Maybe<ApiResponse>;
    getApis?: Maybe<ApisResponse>;
    getBusinessRegistration: BusinessRegistrationResponse;
    getBusinessRegistrations?: Maybe<BusinessRegistrationsResponse>;
    getBusinessType: BusinessTypeResponse;
    getBusinessTypes: BusinessTypesResponse;
    getComments?: Maybe<CommentsResponse>;
    getCountries: CountriesResponse;
    getCountry: CountryResponse;
    getFormatPublications: FormatPublicationsResponse;
    getMedia: MediaResponse;
    getMedias?: Maybe<MediasResponse>;
    getNotifications: NotificationsResponse;
    getOrder: OrderResponse;
    getOrderItems: OrderItemResponse;
    getOrders?: Maybe<OrdersResponse>;
    getOrdersByUser?: Maybe<OrdersResponse>;
    getPackage?: Maybe<PackageResponse>;
    getPackageLocation?: Maybe<PackageLocationResponse>;
    getPackageLocations: PackageLocationsResponse;
    getPackages?: Maybe<PackagesResponse>;
    getPermission?: Maybe<PermissionResponse>;
    getPermissions?: Maybe<PermissionsResponse>;
    getPlatformPublications: PlatformPublicationsResponse;
    getProfile?: Maybe<UserResponse>;
    getReaderOccupations: ReaderOccupationsResponse;
    getRole?: Maybe<RoleResponse>;
    getRoles?: Maybe<RolesResponse>;
    getUser?: Maybe<UserResponse>;
    getUsers?: Maybe<UsersResponse>;
    getWriter: WriterResponse;
    getWriters?: Maybe<WritersResponse>;
};

export type QueryCheckAuthArgs = {
    token?: Maybe<InputAuthToken>;
};

export type QueryCheckAuthAdminArgs = {
    token?: Maybe<InputAuthToken>;
};

export type QueryGetApiArgs = {
    api: InputApiGet;
};

export type QueryGetBusinessRegistrationArgs = {
    businessRegistrationId: Scalars['String'];
};

export type QueryGetBusinessRegistrationsArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetBusinessTypeArgs = {
    businessTypeId: Scalars['String'];
};

export type QueryGetBusinessTypesArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetCommentsArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetCountryArgs = {
    countryId: Scalars['String'];
};

export type QueryGetMediaArgs = {
    mediaId: Scalars['String'];
};

export type QueryGetMediasArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetNotificationsArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetOrderArgs = {
    orderId: Scalars['String'];
};

export type QueryGetOrderItemsArgs = {
    filter?: Maybe<InputPagingRequest>;
};

export type QueryGetOrdersArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetPackageArgs = {
    packageId: Scalars['String'];
};

export type QueryGetPackageLocationArgs = {
    packageLocationId: Scalars['String'];
};

export type QueryGetPackageLocationsArgs = {
    filter?: Maybe<InputPagingRequest>;
};

export type QueryGetPackagesArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetPermissionArgs = {
    permission: InputPermissionGet;
};

export type QueryGetPermissionsArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetProfileArgs = {
    id: Scalars['String'];
};

export type QueryGetRoleArgs = {
    role: InputRoleGet;
};

export type QueryGetRolesArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetUserArgs = {
    user: InputUserGet;
};

export type QueryGetUsersArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type QueryGetWriterArgs = {
    writerId: Scalars['String'];
};

export type QueryGetWritersArgs = {
    filters?: Maybe<InputPagingRequest>;
};

export type ReaderOccupation = {
    __typename?: 'ReaderOccupation';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ReaderOccupationResponse = {
    __typename?: 'ReaderOccupationResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<ReaderOccupation>;
    success: Scalars['Boolean'];
};

export type ReaderOccupationsResponse = {
    __typename?: 'ReaderOccupationsResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Array<Maybe<ReaderOccupation>>>;
    success: Scalars['Boolean'];
};

export type RemoveBusinessRegistrationFileResponse = {
    __typename?: 'RemoveBusinessRegistrationFileResponse';
    success: Scalars['Boolean'];
};

export type RemoveOrderItemResponse = {
    __typename?: 'RemoveOrderItemResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type RemovePackageResponse = {
    __typename?: 'RemovePackageResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type ReportReview = {
    __typename?: 'ReportReview';
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    orderItemId: Scalars['String'];
    reportReviewURL: Array<Maybe<Scalars['String']>>;
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<Scalars['String']>;
};

export type ReportReviewResponse = {
    __typename?: 'ReportReviewResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<ReportReview>;
    success: Scalars['Boolean'];
};

export type Role = {
    __typename?: 'Role';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type RolePaginate = {
    __typename?: 'RolePaginate';
    docs?: Maybe<Array<Maybe<Role>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type RoleResponse = {
    __typename?: 'RoleResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Role>;
    success: Scalars['Boolean'];
};

export type RolesResponse = {
    __typename?: 'RolesResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<RolePaginate>;
    success: Scalars['Boolean'];
};

export type StatusResponse = {
    __typename?: 'StatusResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type UpdateOrderItemResponse = {
    __typename?: 'UpdateOrderItemResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type UpdateOrderResponse = {
    __typename?: 'UpdateOrderResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type UploadBusinessRegistrationFileResponse = {
    __typename?: 'UploadBusinessRegistrationFileResponse';
    success: Scalars['Boolean'];
};

export type UploadCustomBusinessResponse = {
    __typename?: 'UploadCustomBusinessResponse';
    success: Scalars['Boolean'];
};

export type UploadMediaFileResponse = {
    __typename?: 'UploadMediaFileResponse';
    success: Scalars['Boolean'];
};

export type UploadOrderItemFileResponse = {
    __typename?: 'UploadOrderItemFileResponse';
    orderItemURL?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type UploadPackageFileResponse = {
    __typename?: 'UploadPackageFileResponse';
    success: Scalars['Boolean'];
};

export type UploadReportReviewFileResponse = {
    __typename?: 'UploadReportReviewFileResponse';
    success: Scalars['Boolean'];
};

export type UploadWriterFileResponse = {
    __typename?: 'UploadWriterFileResponse';
    success: Scalars['Boolean'];
};

export type User = {
    __typename?: 'User';
    activeToken?: Maybe<Scalars['String']>;
    activeTokenExpires?: Maybe<Scalars['String']>;
    businessRegistration?: Maybe<BusinessRegistration>;
    createdAt: Scalars['DateTime'];
    email: Scalars['String'];
    fullName: Scalars['String'];
    id: Scalars['String'];
    isDel: Scalars['Boolean'];
    permissionId: Array<Maybe<Scalars['String']>>;
    permissions?: Maybe<Array<Maybe<Permission>>>;
    role?: Maybe<Role>;
    roleId: Scalars['String'];
    status: UserStatus;
    updatedAt: Scalars['DateTime'];
};

export type UserPaginate = {
    __typename?: 'UserPaginate';
    docs?: Maybe<Array<Maybe<User>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type UserResponse = {
    __typename?: 'UserResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<User>;
    success: Scalars['Boolean'];
};

export enum UserStatus {
    Active = 'Active',
    Blocked = 'Blocked',
    Pending = 'Pending',
}

export type UserUpdateProfileResponse = {
    __typename?: 'UserUpdateProfileResponse';
    success: Scalars['Boolean'];
};

export type UsersResponse = {
    __typename?: 'UsersResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<UserPaginate>;
    success: Scalars['Boolean'];
};

export type Writer = {
    __typename?: 'Writer';
    address?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    createdBy?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    isDel?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    nationality?: Maybe<Scalars['String']>;
    newsCategory?: Maybe<Scalars['String']>;
    profession?: Maybe<Scalars['String']>;
    receivingAccount?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    updatedBy?: Maybe<Scalars['String']>;
    userCreated?: Maybe<User>;
    writerURL?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WriterPagination = {
    __typename?: 'WriterPagination';
    docs?: Maybe<Array<Maybe<Writer>>>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    meta?: Maybe<Scalars['JSON']>;
    nextPage?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pagingCounter?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export type WriterResponse = {
    __typename?: 'WriterResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<Writer>;
    success: Scalars['Boolean'];
};

export type WritersResponse = {
    __typename?: 'WritersResponse';
    message?: Maybe<Scalars['String']>;
    result?: Maybe<WriterPagination>;
    success: Scalars['Boolean'];
};

export type AdminUtilsResponse = {
    __typename?: 'adminUtilsResponse';
    message?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type AuthResponse = {
    __typename?: 'authResponse';
    permission?: Maybe<Array<Maybe<Permission>>>;
    role?: Maybe<Role>;
    success?: Maybe<Scalars['Boolean']>;
    token?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
};

export type ContactResponse = {
    __typename?: 'contactResponse';
    success?: Maybe<Scalars['Boolean']>;
};

export type RemoveMediaFileResponse = {
    __typename?: 'removeMediaFileResponse';
    success: Scalars['Boolean'];
};

export type RemoveWriterFileResponse = {
    __typename?: 'removeWriterFileResponse';
    success: Scalars['Boolean'];
};

export type AuthResponseInfoFragment = {
    __typename?: 'authResponse';
    success?: boolean | null | undefined;
    token?: string | null | undefined;
    user?:
        | {
              __typename?: 'User';
              id: string;
              fullName: string;
              email: string;
              roleId: string;
              permissionId: Array<string | null | undefined>;
              status: UserStatus;
              isDel: boolean;
              role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
              permissions?:
                  | Array<{ __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined>
                  | null
                  | undefined;
          }
        | null
        | undefined;
    role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
    permission?: Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined> | null | undefined;
};

export type AuthResponseWithoutTokenInfoFragment = {
    __typename?: 'authResponse';
    success?: boolean | null | undefined;
    user?:
        | {
              __typename?: 'User';
              id: string;
              fullName: string;
              email: string;
              roleId: string;
              permissionId: Array<string | null | undefined>;
              status: UserStatus;
              isDel: boolean;
              role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
              permissions?:
                  | Array<{ __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined>
                  | null
                  | undefined;
          }
        | null
        | undefined;
    role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
    permission?: Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined> | null | undefined;
};

export type CheckAuthQueryVariables = Exact<{
    token?: Maybe<InputAuthToken>;
}>;

export type CheckAuthQuery = {
    __typename?: 'Query';
    checkAuth: {
        __typename?: 'authResponse';
        success?: boolean | null | undefined;
        token?: string | null | undefined;
        user?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
        role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
        permission?:
            | Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined>
            | null
            | undefined;
    };
};

export type CheckAuthAdminQueryVariables = Exact<{
    token?: Maybe<InputAuthToken>;
}>;

export type CheckAuthAdminQuery = {
    __typename?: 'Query';
    checkAuthAdmin: {
        __typename?: 'authResponse';
        success?: boolean | null | undefined;
        token?: string | null | undefined;
        user?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
        role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
        permission?:
            | Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined>
            | null
            | undefined;
    };
};

export type LoginMutationVariables = Exact<{
    user: InputAuthLogin;
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    login: {
        __typename?: 'authResponse';
        success?: boolean | null | undefined;
        token?: string | null | undefined;
        user?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
        role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
        permission?:
            | Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined>
            | null
            | undefined;
    };
};

export type LoginAdminMutationVariables = Exact<{
    user: InputAuthLogin;
}>;

export type LoginAdminMutation = {
    __typename?: 'Mutation';
    loginAdmin: {
        __typename?: 'authResponse';
        success?: boolean | null | undefined;
        token?: string | null | undefined;
        user?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
        role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
        permission?:
            | Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined>
            | null
            | undefined;
    };
};

export type RegisterMutationVariables = Exact<{
    user: InputAuthRegister;
}>;

export type RegisterMutation = {
    __typename?: 'Mutation';
    register: {
        __typename?: 'authResponse';
        success?: boolean | null | undefined;
        token?: string | null | undefined;
        user?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
        role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
        permission?:
            | Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined>
            | null
            | undefined;
    };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
    __typename?: 'Mutation';
    logout: { __typename?: 'authResponse'; success?: boolean | null | undefined };
};

export type ForgotPassMutationVariables = Exact<{
    user: InputUserForgotPass;
}>;

export type ForgotPassMutation = {
    __typename?: 'Mutation';
    forgotPass: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type VerifyResetPassTokenMutationVariables = Exact<{
    user: InputUserVerifyResetPassToken;
}>;

export type VerifyResetPassTokenMutation = {
    __typename?: 'Mutation';
    verifyResetPassToken: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdatePassViaMailMutationVariables = Exact<{
    user: InputUserUpdatePassViaMail;
}>;

export type UpdatePassViaMailMutation = {
    __typename?: 'Mutation';
    updatePassViaMail: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type ActivationAccountMutationVariables = Exact<{
    user: InputActivationAccount;
}>;

export type ActivationAccountMutation = {
    __typename?: 'Mutation';
    activationAccount: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type ResendActivationAccountMutationVariables = Exact<{
    user: InputResendActivationAccount;
}>;

export type ResendActivationAccountMutation = {
    __typename?: 'Mutation';
    resendActivationAccount: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type LoginFacebookMutationVariables = Exact<{
    facebookUser: InputFacebookUser;
}>;

export type LoginFacebookMutation = {
    __typename?: 'Mutation';
    loginFacebook: {
        __typename?: 'authResponse';
        success?: boolean | null | undefined;
        user?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
        role?: { __typename?: 'Role'; id: string; name: string } | null | undefined;
        permission?:
            | Array<{ __typename?: 'Permission'; id: string; name: string } | null | undefined>
            | null
            | undefined;
    };
};

export type ChangePassMutationVariables = Exact<{
    user: InputUserChangePass;
}>;

export type ChangePassMutation = {
    __typename?: 'Mutation';
    changePass: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type ChangeEmailMutationVariables = Exact<{
    user: InputUserChangeEmail;
}>;

export type ChangeEmailMutation = {
    __typename?: 'Mutation';
    changeEmail: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type ResetEmailMutationVariables = Exact<{
    user: InputUserResetEmail;
}>;

export type ResetEmailMutation = {
    __typename?: 'Mutation';
    resetEmail: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type ConfirmAccountMutationVariables = Exact<{
    user: InputUserConfirmAccount;
}>;

export type ConfirmAccountMutation = {
    __typename?: 'Mutation';
    confirmAccount: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
                  permissions?:
                      | Array<
                            { __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type GetRolesQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetRolesQuery = {
    __typename?: 'Query';
    getRoles?:
        | {
              __typename?: 'RolesResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'RolePaginate';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetPermissionsQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetPermissionsQuery = {
    __typename?: 'Query';
    getPermissions?:
        | {
              __typename?: 'PermissionsResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'PermissionPaginate';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | { __typename?: 'Permission'; id: string; name: string; isDel: boolean }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetRolesAndPermissionsQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetRolesAndPermissionsQuery = {
    __typename?: 'Query';
    getRoles?:
        | {
              __typename?: 'RolesResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'RolePaginate';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
    getPermissions?:
        | {
              __typename?: 'PermissionsResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'PermissionPaginate';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | { __typename?: 'Permission'; id: string; name: string; isDel: boolean }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type BusinessItemInfoFragment = {
    __typename?: 'BusinessItem';
    id: string;
    name: string;
    businessTypeId: string;
    createdAt: any;
    updatedAt: any;
    isDel: boolean;
};

export type CompanyIndustryClassificationInfoFragment = {
    __typename?: 'CompanyIndustryClassification';
    id: string;
    businessRegistrationId?: string | null | undefined;
    businessTypeId: string;
    businessItems?: Array<string> | null | undefined;
};

export type BusinessRegistrationInfoFragment = {
    __typename?: 'BusinessRegistration';
    id: string;
    companyName: string;
    companyWeb: string;
    registrationNumber: string;
    country: string;
    numberOfEmployees: number;
    contactPersonName: string;
    contactPersonTitle: string;
    phoneNumber: string;
    email: string;
    companyAddress: string;
    weChatAccount: string;
    facebookAccount: string;
    typesCompany: Array<string | null | undefined>;
    otherContent?: string | null | undefined;
    annualSales: Array<string | null | undefined>;
    createdBy?: string | null | undefined;
    updatedBy?: string | null | undefined;
    businessRegistrationURL?: string | null | undefined;
    isDel: boolean;
    companyIndustryClassifications?:
        | Array<
              | {
                    __typename?: 'CompanyIndustryClassification';
                    id: string;
                    businessRegistrationId?: string | null | undefined;
                    businessTypeId: string;
                    businessItems?: Array<string> | null | undefined;
                }
              | null
              | undefined
          >
        | null
        | undefined;
};

export type GetBusinessRegistrationsQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetBusinessRegistrationsQuery = {
    __typename?: 'Query';
    getBusinessRegistrations?:
        | {
              __typename?: 'BusinessRegistrationsResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'BusinessRegistrationPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'BusinessRegistration';
                                        id: string;
                                        companyName: string;
                                        companyWeb: string;
                                        registrationNumber: string;
                                        country: string;
                                        numberOfEmployees: number;
                                        contactPersonName: string;
                                        contactPersonTitle: string;
                                        phoneNumber: string;
                                        email: string;
                                        companyAddress: string;
                                        weChatAccount: string;
                                        facebookAccount: string;
                                        typesCompany: Array<string | null | undefined>;
                                        otherContent?: string | null | undefined;
                                        annualSales: Array<string | null | undefined>;
                                        createdBy?: string | null | undefined;
                                        updatedBy?: string | null | undefined;
                                        businessRegistrationURL?: string | null | undefined;
                                        isDel: boolean;
                                        companyIndustryClassifications?:
                                            | Array<
                                                  | {
                                                        __typename?: 'CompanyIndustryClassification';
                                                        id: string;
                                                        businessRegistrationId?: string | null | undefined;
                                                        businessTypeId: string;
                                                        businessItems?: Array<string> | null | undefined;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetBusinessRegistrationQueryVariables = Exact<{
    businessRegistrationId: Scalars['String'];
}>;

export type GetBusinessRegistrationQuery = {
    __typename?: 'Query';
    getBusinessRegistration: {
        __typename?: 'BusinessRegistrationResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'BusinessRegistration';
                  id: string;
                  companyName: string;
                  companyWeb: string;
                  registrationNumber: string;
                  country: string;
                  numberOfEmployees: number;
                  contactPersonName: string;
                  contactPersonTitle: string;
                  phoneNumber: string;
                  email: string;
                  companyAddress: string;
                  weChatAccount: string;
                  facebookAccount: string;
                  typesCompany: Array<string | null | undefined>;
                  otherContent?: string | null | undefined;
                  annualSales: Array<string | null | undefined>;
                  createdBy?: string | null | undefined;
                  updatedBy?: string | null | undefined;
                  businessRegistrationURL?: string | null | undefined;
                  isDel: boolean;
                  companyIndustryClassifications?:
                      | Array<
                            | {
                                  __typename?: 'CompanyIndustryClassification';
                                  id: string;
                                  businessRegistrationId?: string | null | undefined;
                                  businessTypeId: string;
                                  businessItems?: Array<string> | null | undefined;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type CreateBusinessRegistrationMutationVariables = Exact<{
    businessRegistration: InputBusinessRegistrationCreate;
}>;

export type CreateBusinessRegistrationMutation = {
    __typename?: 'Mutation';
    createBusinessRegistration: {
        __typename?: 'BusinessRegistrationResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'BusinessRegistration';
                  id: string;
                  companyName: string;
                  companyWeb: string;
                  registrationNumber: string;
                  country: string;
                  numberOfEmployees: number;
                  contactPersonName: string;
                  contactPersonTitle: string;
                  phoneNumber: string;
                  email: string;
                  companyAddress: string;
                  weChatAccount: string;
                  facebookAccount: string;
                  typesCompany: Array<string | null | undefined>;
                  otherContent?: string | null | undefined;
                  annualSales: Array<string | null | undefined>;
                  createdBy?: string | null | undefined;
                  updatedBy?: string | null | undefined;
                  businessRegistrationURL?: string | null | undefined;
                  isDel: boolean;
                  companyIndustryClassifications?:
                      | Array<
                            | {
                                  __typename?: 'CompanyIndustryClassification';
                                  id: string;
                                  businessRegistrationId?: string | null | undefined;
                                  businessTypeId: string;
                                  businessItems?: Array<string> | null | undefined;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdateBusinessRegistrationMutationVariables = Exact<{
    businessRegistration: InputBusinessRegistrationUpdate;
}>;

export type UpdateBusinessRegistrationMutation = {
    __typename?: 'Mutation';
    updateBusinessRegistration: {
        __typename?: 'BusinessRegistrationResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'BusinessRegistration';
                  id: string;
                  companyName: string;
                  companyWeb: string;
                  registrationNumber: string;
                  country: string;
                  numberOfEmployees: number;
                  contactPersonName: string;
                  contactPersonTitle: string;
                  phoneNumber: string;
                  email: string;
                  companyAddress: string;
                  weChatAccount: string;
                  facebookAccount: string;
                  typesCompany: Array<string | null | undefined>;
                  otherContent?: string | null | undefined;
                  annualSales: Array<string | null | undefined>;
                  createdBy?: string | null | undefined;
                  updatedBy?: string | null | undefined;
                  businessRegistrationURL?: string | null | undefined;
                  isDel: boolean;
                  companyIndustryClassifications?:
                      | Array<
                            | {
                                  __typename?: 'CompanyIndustryClassification';
                                  id: string;
                                  businessRegistrationId?: string | null | undefined;
                                  businessTypeId: string;
                                  businessItems?: Array<string> | null | undefined;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeleteBusinessRegistrationsMutationVariables = Exact<{
    businessRegistrationIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteBusinessRegistrationsMutation = {
    __typename?: 'Mutation';
    deleteBusinessRegistrations: {
        __typename?: 'DeleteBusinessRegistrationResponse';
        success: boolean;
        message?: string | null | undefined;
    };
};

export type UploadBusinessRegistrationFileMutationVariables = Exact<{
    file: Scalars['Upload'];
    businessRegistrationId: Scalars['String'];
}>;

export type UploadBusinessRegistrationFileMutation = {
    __typename?: 'Mutation';
    uploadBusinessRegistrationFile: { __typename?: 'UploadBusinessRegistrationFileResponse'; success: boolean };
};

export type RemoveBusinessRegistrationFileMutationVariables = Exact<{
    businessRegistrationId: Scalars['String'];
}>;

export type RemoveBusinessRegistrationFileMutation = {
    __typename?: 'Mutation';
    removeBusinessRegistrationFile: { __typename?: 'RemoveBusinessRegistrationFileResponse'; success: boolean };
};

export type BusinessTypeInfoFragment = {
    __typename?: 'BusinessType';
    id: string;
    name: string;
    createdAt: any;
    updatedAt: any;
    isDel: boolean;
    businessItems?:
        | Array<
              | {
                    __typename?: 'BusinessItem';
                    id: string;
                    name: string;
                    businessTypeId: string;
                    createdAt: any;
                    updatedAt: any;
                    isDel: boolean;
                }
              | null
              | undefined
          >
        | null
        | undefined;
};

export type GetBusinessTypesQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetBusinessTypesQuery = {
    __typename?: 'Query';
    getBusinessTypes: {
        __typename?: 'BusinessTypesResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | Array<
                  | {
                        __typename?: 'BusinessType';
                        id: string;
                        name: string;
                        createdAt: any;
                        updatedAt: any;
                        isDel: boolean;
                        businessItems?:
                            | Array<
                                  | {
                                        __typename?: 'BusinessItem';
                                        id: string;
                                        name: string;
                                        businessTypeId: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        isDel: boolean;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined
              >
            | null
            | undefined;
    };
};

export type CityInfoFragment = {
    __typename?: 'City';
    id: string;
    name: string;
    countryId: string;
    createdAt: any;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type CreateCityMutationVariables = Exact<{
    city: InputCityCreate;
}>;

export type CreateCityMutation = {
    __typename?: 'Mutation';
    createCity: {
        __typename?: 'CityResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'City';
                  id: string;
                  name: string;
                  countryId: string;
                  createdAt: any;
                  createdBy?: string | null | undefined;
                  updatedAt: any;
                  updatedBy?: string | null | undefined;
                  isDel: boolean;
              }
            | null
            | undefined;
    };
};

export type UpdateCityMutationVariables = Exact<{
    city: InputCityUpdate;
}>;

export type UpdateCityMutation = {
    __typename?: 'Mutation';
    updateCity: {
        __typename?: 'CityResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'City';
                  id: string;
                  name: string;
                  countryId: string;
                  createdAt: any;
                  createdBy?: string | null | undefined;
                  updatedAt: any;
                  updatedBy?: string | null | undefined;
                  isDel: boolean;
              }
            | null
            | undefined;
    };
};

export type DeleteCitiesMutationVariables = Exact<{
    cityIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteCitiesMutation = {
    __typename?: 'Mutation';
    deleteCities: { __typename?: 'DeleteCityResponse'; success: boolean; message?: string | null | undefined };
};

export type CommentInfoFragment = {
    __typename?: 'Comment';
    id: string;
    content: string;
    rateStar: number;
    isApproved?: boolean | null | undefined;
    approvedBy?: string | null | undefined;
    rejectedBy?: string | null | undefined;
    packageId: string;
    createdBy: string;
    updatedBy?: string | null | undefined;
    isDel?: boolean | null | undefined;
    createdAt: any;
    updatedAt?: any | null | undefined;
    hide?: boolean | null | undefined;
    package?:
        | {
              __typename?: 'Package';
              id: string;
              name: string;
              packageCode: string;
              content?: string | null | undefined;
              price: number;
              packageLocationId?: string | null | undefined;
              location: string;
              supplement?: string | null | undefined;
              otherIndex: Array<string | null | undefined>;
              packageImgURL?: string | null | undefined;
              parentPackageId?: string | null | undefined;
              createdBy?: string | null | undefined;
              createdAt: any;
              updatedBy?: string | null | undefined;
              updatedAt: any;
              isDel: boolean;
              packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
              parentPackage?: { __typename?: 'Package'; id: string; name: string; price: number } | null | undefined;
              childPackages?:
                  | Array<
                        | { __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
    userCreated?:
        | {
              __typename?: 'User';
              id: string;
              fullName: string;
              email: string;
              roleId: string;
              permissionId: Array<string | null | undefined>;
              status: UserStatus;
              isDel: boolean;
              businessRegistration?:
                  | {
                        __typename?: 'BusinessRegistration';
                        id: string;
                        companyName: string;
                        companyWeb: string;
                        registrationNumber: string;
                        country: string;
                        numberOfEmployees: number;
                        contactPersonName: string;
                        contactPersonTitle: string;
                        phoneNumber: string;
                        email: string;
                        companyAddress: string;
                        weChatAccount: string;
                        facebookAccount: string;
                        typesCompany: Array<string | null | undefined>;
                        otherContent?: string | null | undefined;
                        annualSales: Array<string | null | undefined>;
                        createdBy?: string | null | undefined;
                        updatedBy?: string | null | undefined;
                        businessRegistrationURL?: string | null | undefined;
                        isDel: boolean;
                        companyIndustryClassifications?:
                            | Array<
                                  | {
                                        __typename?: 'CompanyIndustryClassification';
                                        id: string;
                                        businessRegistrationId?: string | null | undefined;
                                        businessTypeId: string;
                                        businessItems?: Array<string> | null | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type UserCommentFragment = {
    __typename?: 'Comment';
    id: string;
    content: string;
    rateStar: number;
    packageId: string;
    isDel?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
};

export type GetCommentsQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetCommentsQuery = {
    __typename?: 'Query';
    getComments?:
        | {
              __typename?: 'CommentsResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'CommentPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Comment';
                                        id: string;
                                        content: string;
                                        rateStar: number;
                                        isApproved?: boolean | null | undefined;
                                        approvedBy?: string | null | undefined;
                                        rejectedBy?: string | null | undefined;
                                        packageId: string;
                                        createdBy: string;
                                        updatedBy?: string | null | undefined;
                                        isDel?: boolean | null | undefined;
                                        createdAt: any;
                                        updatedAt?: any | null | undefined;
                                        hide?: boolean | null | undefined;
                                        package?:
                                            | {
                                                  __typename?: 'Package';
                                                  id: string;
                                                  name: string;
                                                  packageCode: string;
                                                  content?: string | null | undefined;
                                                  price: number;
                                                  packageLocationId?: string | null | undefined;
                                                  location: string;
                                                  supplement?: string | null | undefined;
                                                  otherIndex: Array<string | null | undefined>;
                                                  packageImgURL?: string | null | undefined;
                                                  parentPackageId?: string | null | undefined;
                                                  createdBy?: string | null | undefined;
                                                  createdAt: any;
                                                  updatedBy?: string | null | undefined;
                                                  updatedAt: any;
                                                  isDel: boolean;
                                                  packageLocation?:
                                                      | { __typename?: 'PackageLocation'; name: string }
                                                      | null
                                                      | undefined;
                                                  parentPackage?:
                                                      | {
                                                            __typename?: 'Package';
                                                            id: string;
                                                            name: string;
                                                            price: number;
                                                        }
                                                      | null
                                                      | undefined;
                                                  childPackages?:
                                                      | Array<
                                                            | {
                                                                  __typename?: 'Package';
                                                                  id: string;
                                                                  name: string;
                                                                  price: number;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined
                                                        >
                                                      | null
                                                      | undefined;
                                              }
                                            | null
                                            | undefined;
                                        userCreated?:
                                            | {
                                                  __typename?: 'User';
                                                  id: string;
                                                  fullName: string;
                                                  email: string;
                                                  roleId: string;
                                                  permissionId: Array<string | null | undefined>;
                                                  status: UserStatus;
                                                  isDel: boolean;
                                                  businessRegistration?:
                                                      | {
                                                            __typename?: 'BusinessRegistration';
                                                            id: string;
                                                            companyName: string;
                                                            companyWeb: string;
                                                            registrationNumber: string;
                                                            country: string;
                                                            numberOfEmployees: number;
                                                            contactPersonName: string;
                                                            contactPersonTitle: string;
                                                            phoneNumber: string;
                                                            email: string;
                                                            companyAddress: string;
                                                            weChatAccount: string;
                                                            facebookAccount: string;
                                                            typesCompany: Array<string | null | undefined>;
                                                            otherContent?: string | null | undefined;
                                                            annualSales: Array<string | null | undefined>;
                                                            createdBy?: string | null | undefined;
                                                            updatedBy?: string | null | undefined;
                                                            businessRegistrationURL?: string | null | undefined;
                                                            isDel: boolean;
                                                            companyIndustryClassifications?:
                                                                | Array<
                                                                      | {
                                                                            __typename?: 'CompanyIndustryClassification';
                                                                            id: string;
                                                                            businessRegistrationId?:
                                                                                | string
                                                                                | null
                                                                                | undefined;
                                                                            businessTypeId: string;
                                                                            businessItems?:
                                                                                | Array<string>
                                                                                | null
                                                                                | undefined;
                                                                        }
                                                                      | null
                                                                      | undefined
                                                                  >
                                                                | null
                                                                | undefined;
                                                        }
                                                      | null
                                                      | undefined;
                                              }
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetUserCommentsQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetUserCommentsQuery = {
    __typename?: 'Query';
    getComments?:
        | {
              __typename?: 'CommentsResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'CommentPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Comment';
                                        id: string;
                                        content: string;
                                        rateStar: number;
                                        packageId: string;
                                        isDel?: boolean | null | undefined;
                                        updatedAt?: any | null | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type CreateCommentMutationVariables = Exact<{
    comment: InputCommentCreate;
}>;

export type CreateCommentMutation = {
    __typename?: 'Mutation';
    createComment: {
        __typename?: 'CommentResponse';
        success: boolean;
        message?: string | null | undefined;
        result?: { __typename?: 'Comment'; content: string; rateStar: number } | null | undefined;
    };
};

export type DeleteCommentsMutationVariables = Exact<{
    commentIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteCommentsMutation = {
    __typename?: 'Mutation';
    deleteComments: { __typename?: 'StatusResponse'; success: boolean; message?: string | null | undefined };
};

export type ApproveCommentsMutationVariables = Exact<{
    commentIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type ApproveCommentsMutation = {
    __typename?: 'Mutation';
    approveComments: { __typename?: 'StatusResponse'; success: boolean; message?: string | null | undefined };
};

export type RejectCommentsMutationVariables = Exact<{
    commentIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type RejectCommentsMutation = {
    __typename?: 'Mutation';
    rejectComments: { __typename?: 'StatusResponse'; success: boolean; message?: string | null | undefined };
};

export type HideCommentMutationVariables = Exact<{
    comment: InputHideComment;
}>;

export type HideCommentMutation = {
    __typename?: 'Mutation';
    hideComment: { __typename?: 'StatusResponse'; success: boolean; message?: string | null | undefined };
};

export type CreateContactMutationVariables = Exact<{
    contact: InputContact;
}>;

export type CreateContactMutation = {
    __typename?: 'Mutation';
    createContact: { __typename?: 'contactResponse'; success?: boolean | null | undefined };
};

export type CountryInfoFragment = {
    __typename?: 'Country';
    id: string;
    name: string;
    createdAt: any;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    isDel: boolean;
    cities?:
        | Array<
              | {
                    __typename?: 'City';
                    id: string;
                    name: string;
                    countryId: string;
                    createdAt: any;
                    createdBy?: string | null | undefined;
                    updatedAt: any;
                    updatedBy?: string | null | undefined;
                    isDel: boolean;
                }
              | null
              | undefined
          >
        | null
        | undefined;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
    __typename?: 'Query';
    getCountries: {
        __typename?: 'CountriesResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | Array<
                  | {
                        __typename?: 'Country';
                        id: string;
                        name: string;
                        createdAt: any;
                        createdBy?: string | null | undefined;
                        updatedAt: any;
                        updatedBy?: string | null | undefined;
                        isDel: boolean;
                        cities?:
                            | Array<
                                  | {
                                        __typename?: 'City';
                                        id: string;
                                        name: string;
                                        countryId: string;
                                        createdAt: any;
                                        createdBy?: string | null | undefined;
                                        updatedAt: any;
                                        updatedBy?: string | null | undefined;
                                        isDel: boolean;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined
              >
            | null
            | undefined;
    };
};

export type CreateCountryMutationVariables = Exact<{
    country: InputCountryCreate;
}>;

export type CreateCountryMutation = {
    __typename?: 'Mutation';
    createCountry: {
        __typename?: 'CountryResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Country';
                  id: string;
                  name: string;
                  createdAt: any;
                  createdBy?: string | null | undefined;
                  updatedAt: any;
                  updatedBy?: string | null | undefined;
                  isDel: boolean;
                  cities?:
                      | Array<
                            | {
                                  __typename?: 'City';
                                  id: string;
                                  name: string;
                                  countryId: string;
                                  createdAt: any;
                                  createdBy?: string | null | undefined;
                                  updatedAt: any;
                                  updatedBy?: string | null | undefined;
                                  isDel: boolean;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdateCountryMutationVariables = Exact<{
    country: InputCountryUpdate;
}>;

export type UpdateCountryMutation = {
    __typename?: 'Mutation';
    updateCountry: {
        __typename?: 'CountryResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Country';
                  id: string;
                  name: string;
                  createdAt: any;
                  createdBy?: string | null | undefined;
                  updatedAt: any;
                  updatedBy?: string | null | undefined;
                  isDel: boolean;
                  cities?:
                      | Array<
                            | {
                                  __typename?: 'City';
                                  id: string;
                                  name: string;
                                  countryId: string;
                                  createdAt: any;
                                  createdBy?: string | null | undefined;
                                  updatedAt: any;
                                  updatedBy?: string | null | undefined;
                                  isDel: boolean;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeleteCountriesMutationVariables = Exact<{
    countryIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteCountriesMutation = {
    __typename?: 'Mutation';
    deleteCountries: { __typename?: 'DeleteCountryResponse'; success: boolean; message?: string | null | undefined };
};

export type MediaInfoFragment = {
    __typename?: 'Media';
    id?: string | null | undefined;
    mediaName?: string | null | undefined;
    mediaWeb?: string | null | undefined;
    registrationNumber?: string | null | undefined;
    established?: string | null | undefined;
    contactPersonName?: string | null | undefined;
    contactPersonTitle?: string | null | undefined;
    address?: string | null | undefined;
    email?: string | null | undefined;
    receivingAccount?: string | null | undefined;
    newsCategoriesCovered?: string | null | undefined;
    createdAt?: any | null | undefined;
    createdBy?: string | null | undefined;
    updatedAt?: any | null | undefined;
    updatedBy?: string | null | undefined;
    mediaURL?: Array<string | null | undefined> | null | undefined;
    isDel?: boolean | null | undefined;
    userCreated?:
        | {
              __typename?: 'User';
              id: string;
              fullName: string;
              email: string;
              roleId: string;
              permissionId: Array<string | null | undefined>;
              status: UserStatus;
              isDel: boolean;
              businessRegistration?:
                  | {
                        __typename?: 'BusinessRegistration';
                        id: string;
                        companyName: string;
                        companyWeb: string;
                        registrationNumber: string;
                        country: string;
                        numberOfEmployees: number;
                        contactPersonName: string;
                        contactPersonTitle: string;
                        phoneNumber: string;
                        email: string;
                        companyAddress: string;
                        weChatAccount: string;
                        facebookAccount: string;
                        typesCompany: Array<string | null | undefined>;
                        otherContent?: string | null | undefined;
                        annualSales: Array<string | null | undefined>;
                        createdBy?: string | null | undefined;
                        updatedBy?: string | null | undefined;
                        businessRegistrationURL?: string | null | undefined;
                        isDel: boolean;
                        companyIndustryClassifications?:
                            | Array<
                                  | {
                                        __typename?: 'CompanyIndustryClassification';
                                        id: string;
                                        businessRegistrationId?: string | null | undefined;
                                        businessTypeId: string;
                                        businessItems?: Array<string> | null | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetMediasQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetMediasQuery = {
    __typename?: 'Query';
    getMedias?:
        | {
              __typename?: 'MediasResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'MediaPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Media';
                                        id?: string | null | undefined;
                                        mediaName?: string | null | undefined;
                                        mediaWeb?: string | null | undefined;
                                        registrationNumber?: string | null | undefined;
                                        established?: string | null | undefined;
                                        contactPersonName?: string | null | undefined;
                                        contactPersonTitle?: string | null | undefined;
                                        address?: string | null | undefined;
                                        email?: string | null | undefined;
                                        receivingAccount?: string | null | undefined;
                                        newsCategoriesCovered?: string | null | undefined;
                                        createdAt?: any | null | undefined;
                                        createdBy?: string | null | undefined;
                                        updatedAt?: any | null | undefined;
                                        updatedBy?: string | null | undefined;
                                        mediaURL?: Array<string | null | undefined> | null | undefined;
                                        isDel?: boolean | null | undefined;
                                        userCreated?:
                                            | {
                                                  __typename?: 'User';
                                                  id: string;
                                                  fullName: string;
                                                  email: string;
                                                  roleId: string;
                                                  permissionId: Array<string | null | undefined>;
                                                  status: UserStatus;
                                                  isDel: boolean;
                                                  businessRegistration?:
                                                      | {
                                                            __typename?: 'BusinessRegistration';
                                                            id: string;
                                                            companyName: string;
                                                            companyWeb: string;
                                                            registrationNumber: string;
                                                            country: string;
                                                            numberOfEmployees: number;
                                                            contactPersonName: string;
                                                            contactPersonTitle: string;
                                                            phoneNumber: string;
                                                            email: string;
                                                            companyAddress: string;
                                                            weChatAccount: string;
                                                            facebookAccount: string;
                                                            typesCompany: Array<string | null | undefined>;
                                                            otherContent?: string | null | undefined;
                                                            annualSales: Array<string | null | undefined>;
                                                            createdBy?: string | null | undefined;
                                                            updatedBy?: string | null | undefined;
                                                            businessRegistrationURL?: string | null | undefined;
                                                            isDel: boolean;
                                                            companyIndustryClassifications?:
                                                                | Array<
                                                                      | {
                                                                            __typename?: 'CompanyIndustryClassification';
                                                                            id: string;
                                                                            businessRegistrationId?:
                                                                                | string
                                                                                | null
                                                                                | undefined;
                                                                            businessTypeId: string;
                                                                            businessItems?:
                                                                                | Array<string>
                                                                                | null
                                                                                | undefined;
                                                                        }
                                                                      | null
                                                                      | undefined
                                                                  >
                                                                | null
                                                                | undefined;
                                                        }
                                                      | null
                                                      | undefined;
                                              }
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetMediaQueryVariables = Exact<{
    mediaId: Scalars['String'];
}>;

export type GetMediaQuery = {
    __typename?: 'Query';
    getMedia: {
        __typename?: 'MediaResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Media';
                  id?: string | null | undefined;
                  mediaName?: string | null | undefined;
                  mediaWeb?: string | null | undefined;
                  registrationNumber?: string | null | undefined;
                  established?: string | null | undefined;
                  contactPersonName?: string | null | undefined;
                  contactPersonTitle?: string | null | undefined;
                  address?: string | null | undefined;
                  email?: string | null | undefined;
                  receivingAccount?: string | null | undefined;
                  newsCategoriesCovered?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                  updatedBy?: string | null | undefined;
                  mediaURL?: Array<string | null | undefined> | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?:
                      | {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            roleId: string;
                            permissionId: Array<string | null | undefined>;
                            status: UserStatus;
                            isDel: boolean;
                            businessRegistration?:
                                | {
                                      __typename?: 'BusinessRegistration';
                                      id: string;
                                      companyName: string;
                                      companyWeb: string;
                                      registrationNumber: string;
                                      country: string;
                                      numberOfEmployees: number;
                                      contactPersonName: string;
                                      contactPersonTitle: string;
                                      phoneNumber: string;
                                      email: string;
                                      companyAddress: string;
                                      weChatAccount: string;
                                      facebookAccount: string;
                                      typesCompany: Array<string | null | undefined>;
                                      otherContent?: string | null | undefined;
                                      annualSales: Array<string | null | undefined>;
                                      createdBy?: string | null | undefined;
                                      updatedBy?: string | null | undefined;
                                      businessRegistrationURL?: string | null | undefined;
                                      isDel: boolean;
                                      companyIndustryClassifications?:
                                          | Array<
                                                | {
                                                      __typename?: 'CompanyIndustryClassification';
                                                      id: string;
                                                      businessRegistrationId?: string | null | undefined;
                                                      businessTypeId: string;
                                                      businessItems?: Array<string> | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                            >
                                          | null
                                          | undefined;
                                  }
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type CreateMediaMutationVariables = Exact<{
    media: InputMediaCreate;
}>;

export type CreateMediaMutation = {
    __typename?: 'Mutation';
    createMedia: {
        __typename?: 'MediaResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Media';
                  id?: string | null | undefined;
                  mediaName?: string | null | undefined;
                  mediaWeb?: string | null | undefined;
                  registrationNumber?: string | null | undefined;
                  established?: string | null | undefined;
                  contactPersonName?: string | null | undefined;
                  contactPersonTitle?: string | null | undefined;
                  address?: string | null | undefined;
                  email?: string | null | undefined;
                  receivingAccount?: string | null | undefined;
                  newsCategoriesCovered?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                  updatedBy?: string | null | undefined;
                  mediaURL?: Array<string | null | undefined> | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?:
                      | {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            roleId: string;
                            permissionId: Array<string | null | undefined>;
                            status: UserStatus;
                            isDel: boolean;
                            businessRegistration?:
                                | {
                                      __typename?: 'BusinessRegistration';
                                      id: string;
                                      companyName: string;
                                      companyWeb: string;
                                      registrationNumber: string;
                                      country: string;
                                      numberOfEmployees: number;
                                      contactPersonName: string;
                                      contactPersonTitle: string;
                                      phoneNumber: string;
                                      email: string;
                                      companyAddress: string;
                                      weChatAccount: string;
                                      facebookAccount: string;
                                      typesCompany: Array<string | null | undefined>;
                                      otherContent?: string | null | undefined;
                                      annualSales: Array<string | null | undefined>;
                                      createdBy?: string | null | undefined;
                                      updatedBy?: string | null | undefined;
                                      businessRegistrationURL?: string | null | undefined;
                                      isDel: boolean;
                                      companyIndustryClassifications?:
                                          | Array<
                                                | {
                                                      __typename?: 'CompanyIndustryClassification';
                                                      id: string;
                                                      businessRegistrationId?: string | null | undefined;
                                                      businessTypeId: string;
                                                      businessItems?: Array<string> | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                            >
                                          | null
                                          | undefined;
                                  }
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdateMediaMutationVariables = Exact<{
    media: InputMediaUpdate;
}>;

export type UpdateMediaMutation = {
    __typename?: 'Mutation';
    updateMedia: {
        __typename?: 'MediaResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Media';
                  id?: string | null | undefined;
                  mediaName?: string | null | undefined;
                  mediaWeb?: string | null | undefined;
                  registrationNumber?: string | null | undefined;
                  established?: string | null | undefined;
                  contactPersonName?: string | null | undefined;
                  contactPersonTitle?: string | null | undefined;
                  address?: string | null | undefined;
                  email?: string | null | undefined;
                  receivingAccount?: string | null | undefined;
                  newsCategoriesCovered?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                  updatedBy?: string | null | undefined;
                  mediaURL?: Array<string | null | undefined> | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?:
                      | {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            roleId: string;
                            permissionId: Array<string | null | undefined>;
                            status: UserStatus;
                            isDel: boolean;
                            businessRegistration?:
                                | {
                                      __typename?: 'BusinessRegistration';
                                      id: string;
                                      companyName: string;
                                      companyWeb: string;
                                      registrationNumber: string;
                                      country: string;
                                      numberOfEmployees: number;
                                      contactPersonName: string;
                                      contactPersonTitle: string;
                                      phoneNumber: string;
                                      email: string;
                                      companyAddress: string;
                                      weChatAccount: string;
                                      facebookAccount: string;
                                      typesCompany: Array<string | null | undefined>;
                                      otherContent?: string | null | undefined;
                                      annualSales: Array<string | null | undefined>;
                                      createdBy?: string | null | undefined;
                                      updatedBy?: string | null | undefined;
                                      businessRegistrationURL?: string | null | undefined;
                                      isDel: boolean;
                                      companyIndustryClassifications?:
                                          | Array<
                                                | {
                                                      __typename?: 'CompanyIndustryClassification';
                                                      id: string;
                                                      businessRegistrationId?: string | null | undefined;
                                                      businessTypeId: string;
                                                      businessItems?: Array<string> | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                            >
                                          | null
                                          | undefined;
                                  }
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeleteMediasMutationVariables = Exact<{
    mediaIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteMediasMutation = {
    __typename?: 'Mutation';
    deleteMedias: { __typename?: 'DeleteMediaResponse'; success: boolean; message?: string | null | undefined };
};

export type UploadMediaFileMutationVariables = Exact<{
    file: Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>;
    mediaId: Scalars['String'];
}>;

export type UploadMediaFileMutation = {
    __typename?: 'Mutation';
    uploadMediaFile: { __typename?: 'UploadMediaFileResponse'; success: boolean };
};

export type RemoveMediaFileMutationVariables = Exact<{
    mediaId: Scalars['String'];
}>;

export type RemoveMediaFileMutation = {
    __typename?: 'Mutation';
    removeMediaFile: { __typename?: 'removeMediaFileResponse'; success: boolean };
};

export type OrderItemProcessingInfoFragment = {
    __typename?: 'OrderItemProcessing';
    id: string;
    status: string;
    orderItemId: string;
    createdBy?: string | null | undefined;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type MediaListReviewInfoFragment = {
    __typename?: 'MediaListReview';
    id: string;
    firstMedia: string;
    secondMedia: string;
    thirdMedia: string;
    orderItemId: string;
    createdAt: any;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type PublishTimeReviewInfoFragment = {
    __typename?: 'PublishTimeReview';
    id: string;
    firstPublicationTime: string;
    secondPublicationTime: string;
    thirdPublicationTime: string;
    orderItemId: string;
    createdAt: any;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type PublishLinkReviewInfoFragment = {
    __typename?: 'PublishLinkReview';
    id: string;
    firstPublishLink: string;
    secondPublishLink: string;
    thirdPublishLink: string;
    orderItemId: string;
    createdAt: any;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type ReportReviewInfoFragment = {
    __typename?: 'ReportReview';
    id: string;
    reportReviewURL: Array<string | null | undefined>;
    orderItemId: string;
    createdAt: any;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type PaymentInformationInfoFragment = {
    __typename?: 'PaymentInformation';
    id: string;
    companyName: string;
    contactPersonName: string;
    phoneNumber: string;
    email: string;
    address: string;
    orderId: string;
    createdBy?: string | null | undefined;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type PaymentDetailInfoFragment = {
    __typename?: 'PaymentDetail';
    id: string;
    payerId: string;
    paymentId: string;
    payerName: string;
    paymentMethod: string;
    paymentAmount: number;
    createdBy?: string | null | undefined;
    updatedBy?: string | null | undefined;
    isDel: string;
};

export type OrderItemInfoFragment = {
    __typename?: 'OrderItem';
    id: string;
    orderItemCode: string;
    quantity: number;
    orderId: string;
    packageId: string;
    countryId?: string | null | undefined;
    cityId?: string | null | undefined;
    mediaType?: string | null | undefined;
    remark?: string | null | undefined;
    orderItemURL?: string | null | undefined;
    createdBy?: string | null | undefined;
    createdAt: any;
    updatedBy?: string | null | undefined;
    updatedAt: any;
    isDel: boolean;
    package?:
        | {
              __typename?: 'Package';
              id: string;
              name: string;
              packageCode: string;
              content?: string | null | undefined;
              price: number;
              packageLocationId?: string | null | undefined;
              location: string;
              supplement?: string | null | undefined;
              otherIndex: Array<string | null | undefined>;
              packageImgURL?: string | null | undefined;
              parentPackageId?: string | null | undefined;
              createdBy?: string | null | undefined;
              createdAt: any;
              updatedBy?: string | null | undefined;
              updatedAt: any;
              isDel: boolean;
              packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
              parentPackage?: { __typename?: 'Package'; id: string; name: string; price: number } | null | undefined;
              childPackages?:
                  | Array<
                        | { __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
    orderItemsProcessing?:
        | Array<
              | {
                    __typename?: 'OrderItemProcessing';
                    id: string;
                    status: string;
                    orderItemId: string;
                    createdBy?: string | null | undefined;
                    updatedBy?: string | null | undefined;
                    isDel: boolean;
                }
              | null
              | undefined
          >
        | null
        | undefined;
    country?:
        | {
              __typename?: 'Country';
              id: string;
              name: string;
              createdAt: any;
              createdBy?: string | null | undefined;
              updatedAt: any;
              updatedBy?: string | null | undefined;
              isDel: boolean;
              cities?:
                  | Array<
                        | {
                              __typename?: 'City';
                              id: string;
                              name: string;
                              countryId: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
    city?:
        | {
              __typename?: 'City';
              id: string;
              name: string;
              countryId: string;
              createdAt: any;
              createdBy?: string | null | undefined;
              updatedAt: any;
              updatedBy?: string | null | undefined;
              isDel: boolean;
          }
        | null
        | undefined;
    mediaListReview?:
        | {
              __typename?: 'MediaListReview';
              id: string;
              firstMedia: string;
              secondMedia: string;
              thirdMedia: string;
              orderItemId: string;
              createdAt: any;
              createdBy?: string | null | undefined;
              updatedAt: any;
              updatedBy?: string | null | undefined;
              isDel: boolean;
          }
        | null
        | undefined;
    publishTimeReview?:
        | {
              __typename?: 'PublishTimeReview';
              id: string;
              firstPublicationTime: string;
              secondPublicationTime: string;
              thirdPublicationTime: string;
              orderItemId: string;
              createdAt: any;
              createdBy?: string | null | undefined;
              updatedAt: any;
              updatedBy?: string | null | undefined;
              isDel: boolean;
          }
        | null
        | undefined;
    publishLinkReview?:
        | {
              __typename?: 'PublishLinkReview';
              id: string;
              firstPublishLink: string;
              secondPublishLink: string;
              thirdPublishLink: string;
              orderItemId: string;
              createdAt: any;
              createdBy?: string | null | undefined;
              updatedAt: any;
              updatedBy?: string | null | undefined;
              isDel: boolean;
          }
        | null
        | undefined;
    reportReview?:
        | {
              __typename?: 'ReportReview';
              id: string;
              reportReviewURL: Array<string | null | undefined>;
              orderItemId: string;
              createdAt: any;
              createdBy?: string | null | undefined;
              updatedAt: any;
              updatedBy?: string | null | undefined;
              isDel: boolean;
          }
        | null
        | undefined;
};

export type OrderInfoFragment = {
    __typename?: 'Order';
    id: string;
    orderCode: string;
    status?: OrderStatus | null | undefined;
    otherCost: number;
    total: number;
    paymentDetailId?: string | null | undefined;
    createdAt: any;
    endDate?: any | null | undefined;
    createdBy?: string | null | undefined;
    updatedAt: any;
    updatedBy?: string | null | undefined;
    comment?: string | null | undefined;
    isDel?: boolean | null | undefined;
    orderItems?:
        | Array<
              | {
                    __typename?: 'OrderItem';
                    id: string;
                    orderItemCode: string;
                    quantity: number;
                    orderId: string;
                    packageId: string;
                    countryId?: string | null | undefined;
                    cityId?: string | null | undefined;
                    mediaType?: string | null | undefined;
                    remark?: string | null | undefined;
                    orderItemURL?: string | null | undefined;
                    createdBy?: string | null | undefined;
                    createdAt: any;
                    updatedBy?: string | null | undefined;
                    updatedAt: any;
                    isDel: boolean;
                    package?:
                        | {
                              __typename?: 'Package';
                              id: string;
                              name: string;
                              packageCode: string;
                              content?: string | null | undefined;
                              price: number;
                              packageLocationId?: string | null | undefined;
                              location: string;
                              supplement?: string | null | undefined;
                              otherIndex: Array<string | null | undefined>;
                              packageImgURL?: string | null | undefined;
                              parentPackageId?: string | null | undefined;
                              createdBy?: string | null | undefined;
                              createdAt: any;
                              updatedBy?: string | null | undefined;
                              updatedAt: any;
                              isDel: boolean;
                              packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
                              parentPackage?:
                                  | { __typename?: 'Package'; id: string; name: string; price: number }
                                  | null
                                  | undefined;
                              childPackages?:
                                  | Array<
                                        | {
                                              __typename?: 'Package';
                                              id: string;
                                              name: string;
                                              price: number;
                                              isDel: boolean;
                                          }
                                        | null
                                        | undefined
                                    >
                                  | null
                                  | undefined;
                          }
                        | null
                        | undefined;
                    orderItemsProcessing?:
                        | Array<
                              | {
                                    __typename?: 'OrderItemProcessing';
                                    id: string;
                                    status: string;
                                    orderItemId: string;
                                    createdBy?: string | null | undefined;
                                    updatedBy?: string | null | undefined;
                                    isDel: boolean;
                                }
                              | null
                              | undefined
                          >
                        | null
                        | undefined;
                    country?:
                        | {
                              __typename?: 'Country';
                              id: string;
                              name: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                              cities?:
                                  | Array<
                                        | {
                                              __typename?: 'City';
                                              id: string;
                                              name: string;
                                              countryId: string;
                                              createdAt: any;
                                              createdBy?: string | null | undefined;
                                              updatedAt: any;
                                              updatedBy?: string | null | undefined;
                                              isDel: boolean;
                                          }
                                        | null
                                        | undefined
                                    >
                                  | null
                                  | undefined;
                          }
                        | null
                        | undefined;
                    city?:
                        | {
                              __typename?: 'City';
                              id: string;
                              name: string;
                              countryId: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                          }
                        | null
                        | undefined;
                    mediaListReview?:
                        | {
                              __typename?: 'MediaListReview';
                              id: string;
                              firstMedia: string;
                              secondMedia: string;
                              thirdMedia: string;
                              orderItemId: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                          }
                        | null
                        | undefined;
                    publishTimeReview?:
                        | {
                              __typename?: 'PublishTimeReview';
                              id: string;
                              firstPublicationTime: string;
                              secondPublicationTime: string;
                              thirdPublicationTime: string;
                              orderItemId: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                          }
                        | null
                        | undefined;
                    publishLinkReview?:
                        | {
                              __typename?: 'PublishLinkReview';
                              id: string;
                              firstPublishLink: string;
                              secondPublishLink: string;
                              thirdPublishLink: string;
                              orderItemId: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                          }
                        | null
                        | undefined;
                    reportReview?:
                        | {
                              __typename?: 'ReportReview';
                              id: string;
                              reportReviewURL: Array<string | null | undefined>;
                              orderItemId: string;
                              createdAt: any;
                              createdBy?: string | null | undefined;
                              updatedAt: any;
                              updatedBy?: string | null | undefined;
                              isDel: boolean;
                          }
                        | null
                        | undefined;
                }
              | null
              | undefined
          >
        | null
        | undefined;
    paymentInformation?:
        | {
              __typename?: 'PaymentInformation';
              id: string;
              companyName: string;
              contactPersonName: string;
              phoneNumber: string;
              email: string;
              address: string;
              orderId: string;
              createdBy?: string | null | undefined;
              updatedBy?: string | null | undefined;
              isDel: boolean;
          }
        | null
        | undefined;
    paymentDetail?:
        | {
              __typename?: 'PaymentDetail';
              id: string;
              payerId: string;
              paymentId: string;
              payerName: string;
              paymentMethod: string;
              paymentAmount: number;
              createdBy?: string | null | undefined;
              updatedBy?: string | null | undefined;
              isDel: string;
          }
        | null
        | undefined;
};

export type GetOrdersQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetOrdersQuery = {
    __typename?: 'Query';
    getOrders?:
        | {
              __typename?: 'OrdersResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'OrderPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Order';
                                        id: string;
                                        orderCode: string;
                                        status?: OrderStatus | null | undefined;
                                        otherCost: number;
                                        total: number;
                                        paymentDetailId?: string | null | undefined;
                                        createdAt: any;
                                        endDate?: any | null | undefined;
                                        createdBy?: string | null | undefined;
                                        updatedAt: any;
                                        updatedBy?: string | null | undefined;
                                        comment?: string | null | undefined;
                                        isDel?: boolean | null | undefined;
                                        orderItems?:
                                            | Array<
                                                  | {
                                                        __typename?: 'OrderItem';
                                                        id: string;
                                                        orderItemCode: string;
                                                        quantity: number;
                                                        orderId: string;
                                                        packageId: string;
                                                        countryId?: string | null | undefined;
                                                        cityId?: string | null | undefined;
                                                        mediaType?: string | null | undefined;
                                                        remark?: string | null | undefined;
                                                        orderItemURL?: string | null | undefined;
                                                        createdBy?: string | null | undefined;
                                                        createdAt: any;
                                                        updatedBy?: string | null | undefined;
                                                        updatedAt: any;
                                                        isDel: boolean;
                                                        package?:
                                                            | {
                                                                  __typename?: 'Package';
                                                                  id: string;
                                                                  name: string;
                                                                  packageCode: string;
                                                                  content?: string | null | undefined;
                                                                  price: number;
                                                                  packageLocationId?: string | null | undefined;
                                                                  location: string;
                                                                  supplement?: string | null | undefined;
                                                                  otherIndex: Array<string | null | undefined>;
                                                                  packageImgURL?: string | null | undefined;
                                                                  parentPackageId?: string | null | undefined;
                                                                  createdBy?: string | null | undefined;
                                                                  createdAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  isDel: boolean;
                                                                  packageLocation?:
                                                                      | { __typename?: 'PackageLocation'; name: string }
                                                                      | null
                                                                      | undefined;
                                                                  parentPackage?:
                                                                      | {
                                                                            __typename?: 'Package';
                                                                            id: string;
                                                                            name: string;
                                                                            price: number;
                                                                        }
                                                                      | null
                                                                      | undefined;
                                                                  childPackages?:
                                                                      | Array<
                                                                            | {
                                                                                  __typename?: 'Package';
                                                                                  id: string;
                                                                                  name: string;
                                                                                  price: number;
                                                                                  isDel: boolean;
                                                                              }
                                                                            | null
                                                                            | undefined
                                                                        >
                                                                      | null
                                                                      | undefined;
                                                              }
                                                            | null
                                                            | undefined;
                                                        orderItemsProcessing?:
                                                            | Array<
                                                                  | {
                                                                        __typename?: 'OrderItemProcessing';
                                                                        id: string;
                                                                        status: string;
                                                                        orderItemId: string;
                                                                        createdBy?: string | null | undefined;
                                                                        updatedBy?: string | null | undefined;
                                                                        isDel: boolean;
                                                                    }
                                                                  | null
                                                                  | undefined
                                                              >
                                                            | null
                                                            | undefined;
                                                        country?:
                                                            | {
                                                                  __typename?: 'Country';
                                                                  id: string;
                                                                  name: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                                  cities?:
                                                                      | Array<
                                                                            | {
                                                                                  __typename?: 'City';
                                                                                  id: string;
                                                                                  name: string;
                                                                                  countryId: string;
                                                                                  createdAt: any;
                                                                                  createdBy?: string | null | undefined;
                                                                                  updatedAt: any;
                                                                                  updatedBy?: string | null | undefined;
                                                                                  isDel: boolean;
                                                                              }
                                                                            | null
                                                                            | undefined
                                                                        >
                                                                      | null
                                                                      | undefined;
                                                              }
                                                            | null
                                                            | undefined;
                                                        city?:
                                                            | {
                                                                  __typename?: 'City';
                                                                  id: string;
                                                                  name: string;
                                                                  countryId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        mediaListReview?:
                                                            | {
                                                                  __typename?: 'MediaListReview';
                                                                  id: string;
                                                                  firstMedia: string;
                                                                  secondMedia: string;
                                                                  thirdMedia: string;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        publishTimeReview?:
                                                            | {
                                                                  __typename?: 'PublishTimeReview';
                                                                  id: string;
                                                                  firstPublicationTime: string;
                                                                  secondPublicationTime: string;
                                                                  thirdPublicationTime: string;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        publishLinkReview?:
                                                            | {
                                                                  __typename?: 'PublishLinkReview';
                                                                  id: string;
                                                                  firstPublishLink: string;
                                                                  secondPublishLink: string;
                                                                  thirdPublishLink: string;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        reportReview?:
                                                            | {
                                                                  __typename?: 'ReportReview';
                                                                  id: string;
                                                                  reportReviewURL: Array<string | null | undefined>;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                        paymentInformation?:
                                            | {
                                                  __typename?: 'PaymentInformation';
                                                  id: string;
                                                  companyName: string;
                                                  contactPersonName: string;
                                                  phoneNumber: string;
                                                  email: string;
                                                  address: string;
                                                  orderId: string;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: boolean;
                                              }
                                            | null
                                            | undefined;
                                        paymentDetail?:
                                            | {
                                                  __typename?: 'PaymentDetail';
                                                  id: string;
                                                  payerId: string;
                                                  paymentId: string;
                                                  payerName: string;
                                                  paymentMethod: string;
                                                  paymentAmount: number;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: string;
                                              }
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetOrderQueryVariables = Exact<{
    orderId: Scalars['String'];
}>;

export type GetOrderQuery = {
    __typename?: 'Query';
    getOrder: {
        __typename?: 'OrderResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Order';
                  id: string;
                  orderCode: string;
                  status?: OrderStatus | null | undefined;
                  otherCost: number;
                  total: number;
                  paymentDetailId?: string | null | undefined;
                  createdAt: any;
                  endDate?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt: any;
                  updatedBy?: string | null | undefined;
                  comment?: string | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?: { __typename?: 'User'; fullName: string } | null | undefined;
                  orderItems?:
                      | Array<
                            | {
                                  __typename?: 'OrderItem';
                                  id: string;
                                  orderItemCode: string;
                                  quantity: number;
                                  orderId: string;
                                  packageId: string;
                                  countryId?: string | null | undefined;
                                  cityId?: string | null | undefined;
                                  mediaType?: string | null | undefined;
                                  remark?: string | null | undefined;
                                  orderItemURL?: string | null | undefined;
                                  createdBy?: string | null | undefined;
                                  createdAt: any;
                                  updatedBy?: string | null | undefined;
                                  updatedAt: any;
                                  isDel: boolean;
                                  package?:
                                      | {
                                            __typename?: 'Package';
                                            id: string;
                                            name: string;
                                            packageCode: string;
                                            content?: string | null | undefined;
                                            price: number;
                                            packageLocationId?: string | null | undefined;
                                            location: string;
                                            supplement?: string | null | undefined;
                                            otherIndex: Array<string | null | undefined>;
                                            packageImgURL?: string | null | undefined;
                                            parentPackageId?: string | null | undefined;
                                            createdBy?: string | null | undefined;
                                            createdAt: any;
                                            updatedBy?: string | null | undefined;
                                            updatedAt: any;
                                            isDel: boolean;
                                            packageLocation?:
                                                | { __typename?: 'PackageLocation'; name: string }
                                                | null
                                                | undefined;
                                            parentPackage?:
                                                | { __typename?: 'Package'; id: string; name: string; price: number }
                                                | null
                                                | undefined;
                                            childPackages?:
                                                | Array<
                                                      | {
                                                            __typename?: 'Package';
                                                            id: string;
                                                            name: string;
                                                            price: number;
                                                            isDel: boolean;
                                                        }
                                                      | null
                                                      | undefined
                                                  >
                                                | null
                                                | undefined;
                                        }
                                      | null
                                      | undefined;
                                  orderItemsProcessing?:
                                      | Array<
                                            | {
                                                  __typename?: 'OrderItemProcessing';
                                                  id: string;
                                                  status: string;
                                                  orderItemId: string;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: boolean;
                                              }
                                            | null
                                            | undefined
                                        >
                                      | null
                                      | undefined;
                                  country?:
                                      | {
                                            __typename?: 'Country';
                                            id: string;
                                            name: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                            cities?:
                                                | Array<
                                                      | {
                                                            __typename?: 'City';
                                                            id: string;
                                                            name: string;
                                                            countryId: string;
                                                            createdAt: any;
                                                            createdBy?: string | null | undefined;
                                                            updatedAt: any;
                                                            updatedBy?: string | null | undefined;
                                                            isDel: boolean;
                                                        }
                                                      | null
                                                      | undefined
                                                  >
                                                | null
                                                | undefined;
                                        }
                                      | null
                                      | undefined;
                                  city?:
                                      | {
                                            __typename?: 'City';
                                            id: string;
                                            name: string;
                                            countryId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  mediaListReview?:
                                      | {
                                            __typename?: 'MediaListReview';
                                            id: string;
                                            firstMedia: string;
                                            secondMedia: string;
                                            thirdMedia: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  publishTimeReview?:
                                      | {
                                            __typename?: 'PublishTimeReview';
                                            id: string;
                                            firstPublicationTime: string;
                                            secondPublicationTime: string;
                                            thirdPublicationTime: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  publishLinkReview?:
                                      | {
                                            __typename?: 'PublishLinkReview';
                                            id: string;
                                            firstPublishLink: string;
                                            secondPublishLink: string;
                                            thirdPublishLink: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  reportReview?:
                                      | {
                                            __typename?: 'ReportReview';
                                            id: string;
                                            reportReviewURL: Array<string | null | undefined>;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
                  paymentInformation?:
                      | {
                            __typename?: 'PaymentInformation';
                            id: string;
                            companyName: string;
                            contactPersonName: string;
                            phoneNumber: string;
                            email: string;
                            address: string;
                            orderId: string;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            isDel: boolean;
                        }
                      | null
                      | undefined;
                  paymentDetail?:
                      | {
                            __typename?: 'PaymentDetail';
                            id: string;
                            payerId: string;
                            paymentId: string;
                            payerName: string;
                            paymentMethod: string;
                            paymentAmount: number;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            isDel: string;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type GetOrdersByUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrdersByUserQuery = {
    __typename?: 'Query';
    getOrdersByUser?:
        | {
              __typename?: 'OrdersResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'OrderPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Order';
                                        id: string;
                                        orderCode: string;
                                        status?: OrderStatus | null | undefined;
                                        otherCost: number;
                                        total: number;
                                        paymentDetailId?: string | null | undefined;
                                        createdAt: any;
                                        endDate?: any | null | undefined;
                                        createdBy?: string | null | undefined;
                                        updatedAt: any;
                                        updatedBy?: string | null | undefined;
                                        comment?: string | null | undefined;
                                        isDel?: boolean | null | undefined;
                                        orderItems?:
                                            | Array<
                                                  | {
                                                        __typename?: 'OrderItem';
                                                        id: string;
                                                        orderItemCode: string;
                                                        quantity: number;
                                                        orderId: string;
                                                        packageId: string;
                                                        countryId?: string | null | undefined;
                                                        cityId?: string | null | undefined;
                                                        mediaType?: string | null | undefined;
                                                        remark?: string | null | undefined;
                                                        orderItemURL?: string | null | undefined;
                                                        createdBy?: string | null | undefined;
                                                        createdAt: any;
                                                        updatedBy?: string | null | undefined;
                                                        updatedAt: any;
                                                        isDel: boolean;
                                                        package?:
                                                            | {
                                                                  __typename?: 'Package';
                                                                  id: string;
                                                                  name: string;
                                                                  packageCode: string;
                                                                  content?: string | null | undefined;
                                                                  price: number;
                                                                  packageLocationId?: string | null | undefined;
                                                                  location: string;
                                                                  supplement?: string | null | undefined;
                                                                  otherIndex: Array<string | null | undefined>;
                                                                  packageImgURL?: string | null | undefined;
                                                                  parentPackageId?: string | null | undefined;
                                                                  createdBy?: string | null | undefined;
                                                                  createdAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  isDel: boolean;
                                                                  packageLocation?:
                                                                      | { __typename?: 'PackageLocation'; name: string }
                                                                      | null
                                                                      | undefined;
                                                                  parentPackage?:
                                                                      | {
                                                                            __typename?: 'Package';
                                                                            id: string;
                                                                            name: string;
                                                                            price: number;
                                                                        }
                                                                      | null
                                                                      | undefined;
                                                                  childPackages?:
                                                                      | Array<
                                                                            | {
                                                                                  __typename?: 'Package';
                                                                                  id: string;
                                                                                  name: string;
                                                                                  price: number;
                                                                                  isDel: boolean;
                                                                              }
                                                                            | null
                                                                            | undefined
                                                                        >
                                                                      | null
                                                                      | undefined;
                                                              }
                                                            | null
                                                            | undefined;
                                                        orderItemsProcessing?:
                                                            | Array<
                                                                  | {
                                                                        __typename?: 'OrderItemProcessing';
                                                                        id: string;
                                                                        status: string;
                                                                        orderItemId: string;
                                                                        createdBy?: string | null | undefined;
                                                                        updatedBy?: string | null | undefined;
                                                                        isDel: boolean;
                                                                    }
                                                                  | null
                                                                  | undefined
                                                              >
                                                            | null
                                                            | undefined;
                                                        country?:
                                                            | {
                                                                  __typename?: 'Country';
                                                                  id: string;
                                                                  name: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                                  cities?:
                                                                      | Array<
                                                                            | {
                                                                                  __typename?: 'City';
                                                                                  id: string;
                                                                                  name: string;
                                                                                  countryId: string;
                                                                                  createdAt: any;
                                                                                  createdBy?: string | null | undefined;
                                                                                  updatedAt: any;
                                                                                  updatedBy?: string | null | undefined;
                                                                                  isDel: boolean;
                                                                              }
                                                                            | null
                                                                            | undefined
                                                                        >
                                                                      | null
                                                                      | undefined;
                                                              }
                                                            | null
                                                            | undefined;
                                                        city?:
                                                            | {
                                                                  __typename?: 'City';
                                                                  id: string;
                                                                  name: string;
                                                                  countryId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        mediaListReview?:
                                                            | {
                                                                  __typename?: 'MediaListReview';
                                                                  id: string;
                                                                  firstMedia: string;
                                                                  secondMedia: string;
                                                                  thirdMedia: string;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        publishTimeReview?:
                                                            | {
                                                                  __typename?: 'PublishTimeReview';
                                                                  id: string;
                                                                  firstPublicationTime: string;
                                                                  secondPublicationTime: string;
                                                                  thirdPublicationTime: string;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        publishLinkReview?:
                                                            | {
                                                                  __typename?: 'PublishLinkReview';
                                                                  id: string;
                                                                  firstPublishLink: string;
                                                                  secondPublishLink: string;
                                                                  thirdPublishLink: string;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                        reportReview?:
                                                            | {
                                                                  __typename?: 'ReportReview';
                                                                  id: string;
                                                                  reportReviewURL: Array<string | null | undefined>;
                                                                  orderItemId: string;
                                                                  createdAt: any;
                                                                  createdBy?: string | null | undefined;
                                                                  updatedAt: any;
                                                                  updatedBy?: string | null | undefined;
                                                                  isDel: boolean;
                                                              }
                                                            | null
                                                            | undefined;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                        paymentInformation?:
                                            | {
                                                  __typename?: 'PaymentInformation';
                                                  id: string;
                                                  companyName: string;
                                                  contactPersonName: string;
                                                  phoneNumber: string;
                                                  email: string;
                                                  address: string;
                                                  orderId: string;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: boolean;
                                              }
                                            | null
                                            | undefined;
                                        paymentDetail?:
                                            | {
                                                  __typename?: 'PaymentDetail';
                                                  id: string;
                                                  payerId: string;
                                                  paymentId: string;
                                                  payerName: string;
                                                  paymentMethod: string;
                                                  paymentAmount: number;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: string;
                                              }
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetOrderItemsQueryVariables = Exact<{
    filter?: Maybe<InputPagingRequest>;
}>;

export type GetOrderItemsQuery = {
    __typename?: 'Query';
    getOrderItems: {
        __typename?: 'OrderItemResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'OrderItemPagination';
                  totalDocs?: number | null | undefined;
                  limit?: number | null | undefined;
                  hasPrevPage?: boolean | null | undefined;
                  hasNextPage?: boolean | null | undefined;
                  page?: number | null | undefined;
                  totalPages?: number | null | undefined;
                  offset?: number | null | undefined;
                  prevPage?: number | null | undefined;
                  nextPage?: number | null | undefined;
                  pagingCounter?: number | null | undefined;
                  meta?: any | null | undefined;
                  docs?:
                      | Array<
                            | {
                                  __typename?: 'OrderItem';
                                  id: string;
                                  orderItemCode: string;
                                  quantity: number;
                                  orderId: string;
                                  packageId: string;
                                  countryId?: string | null | undefined;
                                  cityId?: string | null | undefined;
                                  mediaType?: string | null | undefined;
                                  remark?: string | null | undefined;
                                  orderItemURL?: string | null | undefined;
                                  createdBy?: string | null | undefined;
                                  createdAt: any;
                                  updatedBy?: string | null | undefined;
                                  updatedAt: any;
                                  isDel: boolean;
                                  package?:
                                      | {
                                            __typename?: 'Package';
                                            id: string;
                                            name: string;
                                            packageCode: string;
                                            content?: string | null | undefined;
                                            price: number;
                                            packageLocationId?: string | null | undefined;
                                            location: string;
                                            supplement?: string | null | undefined;
                                            otherIndex: Array<string | null | undefined>;
                                            packageImgURL?: string | null | undefined;
                                            parentPackageId?: string | null | undefined;
                                            createdBy?: string | null | undefined;
                                            createdAt: any;
                                            updatedBy?: string | null | undefined;
                                            updatedAt: any;
                                            isDel: boolean;
                                            packageLocation?:
                                                | { __typename?: 'PackageLocation'; name: string }
                                                | null
                                                | undefined;
                                            parentPackage?:
                                                | { __typename?: 'Package'; id: string; name: string; price: number }
                                                | null
                                                | undefined;
                                            childPackages?:
                                                | Array<
                                                      | {
                                                            __typename?: 'Package';
                                                            id: string;
                                                            name: string;
                                                            price: number;
                                                            isDel: boolean;
                                                        }
                                                      | null
                                                      | undefined
                                                  >
                                                | null
                                                | undefined;
                                        }
                                      | null
                                      | undefined;
                                  orderItemsProcessing?:
                                      | Array<
                                            | {
                                                  __typename?: 'OrderItemProcessing';
                                                  id: string;
                                                  status: string;
                                                  orderItemId: string;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: boolean;
                                              }
                                            | null
                                            | undefined
                                        >
                                      | null
                                      | undefined;
                                  country?:
                                      | {
                                            __typename?: 'Country';
                                            id: string;
                                            name: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                            cities?:
                                                | Array<
                                                      | {
                                                            __typename?: 'City';
                                                            id: string;
                                                            name: string;
                                                            countryId: string;
                                                            createdAt: any;
                                                            createdBy?: string | null | undefined;
                                                            updatedAt: any;
                                                            updatedBy?: string | null | undefined;
                                                            isDel: boolean;
                                                        }
                                                      | null
                                                      | undefined
                                                  >
                                                | null
                                                | undefined;
                                        }
                                      | null
                                      | undefined;
                                  city?:
                                      | {
                                            __typename?: 'City';
                                            id: string;
                                            name: string;
                                            countryId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  mediaListReview?:
                                      | {
                                            __typename?: 'MediaListReview';
                                            id: string;
                                            firstMedia: string;
                                            secondMedia: string;
                                            thirdMedia: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  publishTimeReview?:
                                      | {
                                            __typename?: 'PublishTimeReview';
                                            id: string;
                                            firstPublicationTime: string;
                                            secondPublicationTime: string;
                                            thirdPublicationTime: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  publishLinkReview?:
                                      | {
                                            __typename?: 'PublishLinkReview';
                                            id: string;
                                            firstPublishLink: string;
                                            secondPublishLink: string;
                                            thirdPublishLink: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  reportReview?:
                                      | {
                                            __typename?: 'ReportReview';
                                            id: string;
                                            reportReviewURL: Array<string | null | undefined>;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdateOrderItemMutationVariables = Exact<{
    orderItem: InputOrderItemUpdate;
}>;

export type UpdateOrderItemMutation = {
    __typename?: 'Mutation';
    updateOrderItem: { __typename?: 'UpdateOrderItemResponse'; success: boolean; message?: string | null | undefined };
};

export type UploadOrderItemFileMutationVariables = Exact<{
    file: Scalars['Upload'];
}>;

export type UploadOrderItemFileMutation = {
    __typename?: 'Mutation';
    uploadOrderItemFile: {
        __typename?: 'UploadOrderItemFileResponse';
        success: boolean;
        orderItemURL?: string | null | undefined;
    };
};

export type RemoveOrderItemFileMutationVariables = Exact<{
    orderItemId: Scalars['String'];
}>;

export type RemoveOrderItemFileMutation = {
    __typename?: 'Mutation';
    removeOrderItemFile: {
        __typename?: 'RemoveOrderItemResponse';
        success: boolean;
        message?: string | null | undefined;
    };
};

export type CreateOrderMutationVariables = Exact<{
    order: InputOrderCreate;
}>;

export type CreateOrderMutation = {
    __typename?: 'Mutation';
    createOrder: {
        __typename?: 'OrderResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Order';
                  id: string;
                  orderCode: string;
                  status?: OrderStatus | null | undefined;
                  otherCost: number;
                  total: number;
                  paymentDetailId?: string | null | undefined;
                  createdAt: any;
                  endDate?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt: any;
                  updatedBy?: string | null | undefined;
                  comment?: string | null | undefined;
                  isDel?: boolean | null | undefined;
                  orderItems?:
                      | Array<
                            | {
                                  __typename?: 'OrderItem';
                                  id: string;
                                  orderItemCode: string;
                                  quantity: number;
                                  orderId: string;
                                  packageId: string;
                                  countryId?: string | null | undefined;
                                  cityId?: string | null | undefined;
                                  mediaType?: string | null | undefined;
                                  remark?: string | null | undefined;
                                  orderItemURL?: string | null | undefined;
                                  createdBy?: string | null | undefined;
                                  createdAt: any;
                                  updatedBy?: string | null | undefined;
                                  updatedAt: any;
                                  isDel: boolean;
                                  package?:
                                      | {
                                            __typename?: 'Package';
                                            id: string;
                                            name: string;
                                            packageCode: string;
                                            content?: string | null | undefined;
                                            price: number;
                                            packageLocationId?: string | null | undefined;
                                            location: string;
                                            supplement?: string | null | undefined;
                                            otherIndex: Array<string | null | undefined>;
                                            packageImgURL?: string | null | undefined;
                                            parentPackageId?: string | null | undefined;
                                            createdBy?: string | null | undefined;
                                            createdAt: any;
                                            updatedBy?: string | null | undefined;
                                            updatedAt: any;
                                            isDel: boolean;
                                            packageLocation?:
                                                | { __typename?: 'PackageLocation'; name: string }
                                                | null
                                                | undefined;
                                            parentPackage?:
                                                | { __typename?: 'Package'; id: string; name: string; price: number }
                                                | null
                                                | undefined;
                                            childPackages?:
                                                | Array<
                                                      | {
                                                            __typename?: 'Package';
                                                            id: string;
                                                            name: string;
                                                            price: number;
                                                            isDel: boolean;
                                                        }
                                                      | null
                                                      | undefined
                                                  >
                                                | null
                                                | undefined;
                                        }
                                      | null
                                      | undefined;
                                  orderItemsProcessing?:
                                      | Array<
                                            | {
                                                  __typename?: 'OrderItemProcessing';
                                                  id: string;
                                                  status: string;
                                                  orderItemId: string;
                                                  createdBy?: string | null | undefined;
                                                  updatedBy?: string | null | undefined;
                                                  isDel: boolean;
                                              }
                                            | null
                                            | undefined
                                        >
                                      | null
                                      | undefined;
                                  country?:
                                      | {
                                            __typename?: 'Country';
                                            id: string;
                                            name: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                            cities?:
                                                | Array<
                                                      | {
                                                            __typename?: 'City';
                                                            id: string;
                                                            name: string;
                                                            countryId: string;
                                                            createdAt: any;
                                                            createdBy?: string | null | undefined;
                                                            updatedAt: any;
                                                            updatedBy?: string | null | undefined;
                                                            isDel: boolean;
                                                        }
                                                      | null
                                                      | undefined
                                                  >
                                                | null
                                                | undefined;
                                        }
                                      | null
                                      | undefined;
                                  city?:
                                      | {
                                            __typename?: 'City';
                                            id: string;
                                            name: string;
                                            countryId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  mediaListReview?:
                                      | {
                                            __typename?: 'MediaListReview';
                                            id: string;
                                            firstMedia: string;
                                            secondMedia: string;
                                            thirdMedia: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  publishTimeReview?:
                                      | {
                                            __typename?: 'PublishTimeReview';
                                            id: string;
                                            firstPublicationTime: string;
                                            secondPublicationTime: string;
                                            thirdPublicationTime: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  publishLinkReview?:
                                      | {
                                            __typename?: 'PublishLinkReview';
                                            id: string;
                                            firstPublishLink: string;
                                            secondPublishLink: string;
                                            thirdPublishLink: string;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                                  reportReview?:
                                      | {
                                            __typename?: 'ReportReview';
                                            id: string;
                                            reportReviewURL: Array<string | null | undefined>;
                                            orderItemId: string;
                                            createdAt: any;
                                            createdBy?: string | null | undefined;
                                            updatedAt: any;
                                            updatedBy?: string | null | undefined;
                                            isDel: boolean;
                                        }
                                      | null
                                      | undefined;
                              }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
                  paymentInformation?:
                      | {
                            __typename?: 'PaymentInformation';
                            id: string;
                            companyName: string;
                            contactPersonName: string;
                            phoneNumber: string;
                            email: string;
                            address: string;
                            orderId: string;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            isDel: boolean;
                        }
                      | null
                      | undefined;
                  paymentDetail?:
                      | {
                            __typename?: 'PaymentDetail';
                            id: string;
                            payerId: string;
                            paymentId: string;
                            payerName: string;
                            paymentMethod: string;
                            paymentAmount: number;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            isDel: string;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeleteOrdersMutationVariables = Exact<{
    orderIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteOrdersMutation = {
    __typename?: 'Mutation';
    deleteOrders: { __typename?: 'DeleteOrdersResponse'; success: boolean; message?: string | null | undefined };
};

export type UpdateOrderStatusMutationVariables = Exact<{
    order: InputOrderUpdate;
}>;

export type UpdateOrderStatusMutation = {
    __typename?: 'Mutation';
    updateOrderStatus: { __typename?: 'UpdateOrderResponse'; success: boolean; message?: string | null | undefined };
};

export type UpdateOrderCommentMutationVariables = Exact<{
    order: InputCommentOrder;
}>;

export type UpdateOrderCommentMutation = {
    __typename?: 'Mutation';
    updateOrderComment: { __typename?: 'CommentOrderResponse'; success: boolean; message?: string | null | undefined };
};

export type PackageLocationInfoFragment = {
    __typename?: 'PackageLocation';
    id: string;
    name: string;
    createdAt: any;
    isDel: boolean;
    packages?:
        | Array<
              | {
                    __typename?: 'Package';
                    id: string;
                    name: string;
                    packageCode: string;
                    content?: string | null | undefined;
                    price: number;
                    packageLocationId?: string | null | undefined;
                    location: string;
                    supplement?: string | null | undefined;
                    otherIndex: Array<string | null | undefined>;
                    packageImgURL?: string | null | undefined;
                    parentPackageId?: string | null | undefined;
                    createdBy?: string | null | undefined;
                    createdAt: any;
                    updatedBy?: string | null | undefined;
                    updatedAt: any;
                    isDel: boolean;
                    packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
                    parentPackage?:
                        | { __typename?: 'Package'; id: string; name: string; price: number }
                        | null
                        | undefined;
                    childPackages?:
                        | Array<
                              | { __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean }
                              | null
                              | undefined
                          >
                        | null
                        | undefined;
                }
              | null
              | undefined
          >
        | null
        | undefined;
};

export type PackageInfoFragment = {
    __typename?: 'Package';
    id: string;
    name: string;
    packageCode: string;
    content?: string | null | undefined;
    price: number;
    packageLocationId?: string | null | undefined;
    location: string;
    supplement?: string | null | undefined;
    otherIndex: Array<string | null | undefined>;
    packageImgURL?: string | null | undefined;
    parentPackageId?: string | null | undefined;
    createdBy?: string | null | undefined;
    createdAt: any;
    updatedBy?: string | null | undefined;
    updatedAt: any;
    isDel: boolean;
    packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
    parentPackage?: { __typename?: 'Package'; id: string; name: string; price: number } | null | undefined;
    childPackages?:
        | Array<{ __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean } | null | undefined>
        | null
        | undefined;
};

export type PackageCustomizeInfoFragment = {
    __typename?: 'CustomBusiness';
    id: string;
    platformPublications: Array<string>;
    formatPublications: Array<string>;
    needTranslateService: boolean;
    publishingLanguage: Array<string>;
    postingLocation: Array<string>;
    city: string;
    readerOccupations: Array<string>;
    readerAge: Array<string>;
    createdBy?: string | null | undefined;
    updatedBy?: string | null | undefined;
    isDel: boolean;
};

export type GetPackageQueryVariables = Exact<{
    packageId: Scalars['String'];
}>;

export type GetPackageQuery = {
    __typename?: 'Query';
    getPackage?:
        | {
              __typename?: 'PackageResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'Package';
                        id: string;
                        name: string;
                        packageCode: string;
                        content?: string | null | undefined;
                        price: number;
                        packageLocationId?: string | null | undefined;
                        location: string;
                        supplement?: string | null | undefined;
                        otherIndex: Array<string | null | undefined>;
                        packageImgURL?: string | null | undefined;
                        parentPackageId?: string | null | undefined;
                        createdBy?: string | null | undefined;
                        createdAt: any;
                        updatedBy?: string | null | undefined;
                        updatedAt: any;
                        isDel: boolean;
                        packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
                        parentPackage?:
                            | { __typename?: 'Package'; id: string; name: string; price: number }
                            | null
                            | undefined;
                        childPackages?:
                            | Array<
                                  | { __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetPackagesQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetPackagesQuery = {
    __typename?: 'Query';
    getPackages?:
        | {
              __typename?: 'PackagesResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'PackagePaginate';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Package';
                                        id: string;
                                        name: string;
                                        packageCode: string;
                                        content?: string | null | undefined;
                                        price: number;
                                        packageLocationId?: string | null | undefined;
                                        location: string;
                                        supplement?: string | null | undefined;
                                        otherIndex: Array<string | null | undefined>;
                                        packageImgURL?: string | null | undefined;
                                        parentPackageId?: string | null | undefined;
                                        createdBy?: string | null | undefined;
                                        createdAt: any;
                                        updatedBy?: string | null | undefined;
                                        updatedAt: any;
                                        isDel: boolean;
                                        packageLocation?:
                                            | { __typename?: 'PackageLocation'; name: string }
                                            | null
                                            | undefined;
                                        parentPackage?:
                                            | { __typename?: 'Package'; id: string; name: string; price: number }
                                            | null
                                            | undefined;
                                        childPackages?:
                                            | Array<
                                                  | {
                                                        __typename?: 'Package';
                                                        id: string;
                                                        name: string;
                                                        price: number;
                                                        isDel: boolean;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type CreatePackageMutationVariables = Exact<{
    package: InputPackageCreate;
}>;

export type CreatePackageMutation = {
    __typename?: 'Mutation';
    createPackage: {
        __typename?: 'PackageResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Package';
                  id: string;
                  name: string;
                  packageCode: string;
                  content?: string | null | undefined;
                  price: number;
                  packageLocationId?: string | null | undefined;
                  location: string;
                  supplement?: string | null | undefined;
                  otherIndex: Array<string | null | undefined>;
                  packageImgURL?: string | null | undefined;
                  parentPackageId?: string | null | undefined;
                  createdBy?: string | null | undefined;
                  createdAt: any;
                  updatedBy?: string | null | undefined;
                  updatedAt: any;
                  isDel: boolean;
                  packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
                  parentPackage?:
                      | { __typename?: 'Package'; id: string; name: string; price: number }
                      | null
                      | undefined;
                  childPackages?:
                      | Array<
                            | { __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdatePackageMutationVariables = Exact<{
    package: InputPackageUpdate;
}>;

export type UpdatePackageMutation = {
    __typename?: 'Mutation';
    updatePackage: {
        __typename?: 'PackageResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Package';
                  id: string;
                  name: string;
                  packageCode: string;
                  content?: string | null | undefined;
                  price: number;
                  packageLocationId?: string | null | undefined;
                  location: string;
                  supplement?: string | null | undefined;
                  otherIndex: Array<string | null | undefined>;
                  packageImgURL?: string | null | undefined;
                  parentPackageId?: string | null | undefined;
                  createdBy?: string | null | undefined;
                  createdAt: any;
                  updatedBy?: string | null | undefined;
                  updatedAt: any;
                  isDel: boolean;
                  packageLocation?: { __typename?: 'PackageLocation'; name: string } | null | undefined;
                  parentPackage?:
                      | { __typename?: 'Package'; id: string; name: string; price: number }
                      | null
                      | undefined;
                  childPackages?:
                      | Array<
                            | { __typename?: 'Package'; id: string; name: string; price: number; isDel: boolean }
                            | null
                            | undefined
                        >
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeletePackagesMutationVariables = Exact<{
    packageIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeletePackagesMutation = {
    __typename?: 'Mutation';
    deletePackages: { __typename?: 'DeletePackageResponse'; success: boolean; message?: string | null | undefined };
};

export type GetPackageLocationQueryVariables = Exact<{
    packageLocationId: Scalars['String'];
}>;

export type GetPackageLocationQuery = {
    __typename?: 'Query';
    getPackageLocation?:
        | {
              __typename?: 'PackageLocationResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'PackageLocation';
                        id: string;
                        name: string;
                        createdAt: any;
                        isDel: boolean;
                        packages?:
                            | Array<
                                  | {
                                        __typename?: 'Package';
                                        id: string;
                                        name: string;
                                        packageCode: string;
                                        content?: string | null | undefined;
                                        price: number;
                                        packageLocationId?: string | null | undefined;
                                        location: string;
                                        supplement?: string | null | undefined;
                                        otherIndex: Array<string | null | undefined>;
                                        packageImgURL?: string | null | undefined;
                                        parentPackageId?: string | null | undefined;
                                        createdBy?: string | null | undefined;
                                        createdAt: any;
                                        updatedBy?: string | null | undefined;
                                        updatedAt: any;
                                        isDel: boolean;
                                        packageLocation?:
                                            | { __typename?: 'PackageLocation'; name: string }
                                            | null
                                            | undefined;
                                        parentPackage?:
                                            | { __typename?: 'Package'; id: string; name: string; price: number }
                                            | null
                                            | undefined;
                                        childPackages?:
                                            | Array<
                                                  | {
                                                        __typename?: 'Package';
                                                        id: string;
                                                        name: string;
                                                        price: number;
                                                        isDel: boolean;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetPackageLocationsQueryVariables = Exact<{
    filter?: Maybe<InputPagingRequest>;
}>;

export type GetPackageLocationsQuery = {
    __typename?: 'Query';
    getPackageLocations: {
        __typename?: 'PackageLocationsResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | Array<
                  | {
                        __typename?: 'PackageLocation';
                        id: string;
                        name: string;
                        createdAt: any;
                        isDel: boolean;
                        packages?:
                            | Array<
                                  | {
                                        __typename?: 'Package';
                                        id: string;
                                        name: string;
                                        packageCode: string;
                                        content?: string | null | undefined;
                                        price: number;
                                        packageLocationId?: string | null | undefined;
                                        location: string;
                                        supplement?: string | null | undefined;
                                        otherIndex: Array<string | null | undefined>;
                                        packageImgURL?: string | null | undefined;
                                        parentPackageId?: string | null | undefined;
                                        createdBy?: string | null | undefined;
                                        createdAt: any;
                                        updatedBy?: string | null | undefined;
                                        updatedAt: any;
                                        isDel: boolean;
                                        packageLocation?:
                                            | { __typename?: 'PackageLocation'; name: string }
                                            | null
                                            | undefined;
                                        parentPackage?:
                                            | { __typename?: 'Package'; id: string; name: string; price: number }
                                            | null
                                            | undefined;
                                        childPackages?:
                                            | Array<
                                                  | {
                                                        __typename?: 'Package';
                                                        id: string;
                                                        name: string;
                                                        price: number;
                                                        isDel: boolean;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined
              >
            | null
            | undefined;
    };
};

export type CreateCustomBusinessMutationVariables = Exact<{
    customBusiness: InputCustomBusinessCreate;
}>;

export type CreateCustomBusinessMutation = {
    __typename?: 'Mutation';
    createCustomBusiness: {
        __typename?: 'CustomBusinessResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'CustomBusiness';
                  id: string;
                  platformPublications: Array<string>;
                  formatPublications: Array<string>;
                  needTranslateService: boolean;
                  publishingLanguage: Array<string>;
                  postingLocation: Array<string>;
                  city: string;
                  readerOccupations: Array<string>;
                  readerAge: Array<string>;
                  createdBy?: string | null | undefined;
                  updatedBy?: string | null | undefined;
                  isDel: boolean;
              }
            | null
            | undefined;
    };
};

export type UploadCustomBusinessFileMutationVariables = Exact<{
    file: Scalars['Upload'];
    customBusinessId: Scalars['String'];
}>;

export type UploadCustomBusinessFileMutation = {
    __typename?: 'Mutation';
    uploadCustomBusinessFile: { __typename?: 'UploadCustomBusinessResponse'; success: boolean };
};

export type UploadPackageFileMutationVariables = Exact<{
    file: Scalars['Upload'];
    packageId: Scalars['String'];
}>;

export type UploadPackageFileMutation = {
    __typename?: 'Mutation';
    uploadPackageFile: { __typename?: 'UploadPackageFileResponse'; success: boolean };
};

export type UpdatePackageLocationMutationVariables = Exact<{
    packageLocation: InputPackageLocationUpdate;
}>;

export type UpdatePackageLocationMutation = {
    __typename?: 'Mutation';
    updatePackageLocation: { __typename?: 'PackageLocationResponse'; success: boolean };
};

export type RemovePackageFileMutationVariables = Exact<{
    packageId: Scalars['String'];
}>;

export type RemovePackageFileMutation = {
    __typename?: 'Mutation';
    removePackageFile: { __typename?: 'RemovePackageResponse'; success: boolean };
};

export type AddressInfoFragment = {
    __typename?: 'Address';
    isDefault?: boolean | null | undefined;
    cityId: number;
    cityName: string;
    districtId: number;
    districtName: string;
    wardId: number;
    wardName: string;
    street: string;
};

export type UserInfoFragment = {
    __typename?: 'User';
    id: string;
    fullName: string;
    email: string;
    roleId: string;
    permissionId: Array<string | null | undefined>;
    status: UserStatus;
    isDel: boolean;
    businessRegistration?:
        | {
              __typename?: 'BusinessRegistration';
              id: string;
              companyName: string;
              companyWeb: string;
              registrationNumber: string;
              country: string;
              numberOfEmployees: number;
              contactPersonName: string;
              contactPersonTitle: string;
              phoneNumber: string;
              email: string;
              companyAddress: string;
              weChatAccount: string;
              facebookAccount: string;
              typesCompany: Array<string | null | undefined>;
              otherContent?: string | null | undefined;
              annualSales: Array<string | null | undefined>;
              createdBy?: string | null | undefined;
              updatedBy?: string | null | undefined;
              businessRegistrationURL?: string | null | undefined;
              isDel: boolean;
              companyIndustryClassifications?:
                  | Array<
                        | {
                              __typename?: 'CompanyIndustryClassification';
                              id: string;
                              businessRegistrationId?: string | null | undefined;
                              businessTypeId: string;
                              businessItems?: Array<string> | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type UserWithAuthorizationInfoFragment = {
    __typename?: 'User';
    id: string;
    fullName: string;
    email: string;
    roleId: string;
    permissionId: Array<string | null | undefined>;
    status: UserStatus;
    isDel: boolean;
    role?: { __typename?: 'Role'; id: string; name: string; isDel: boolean } | null | undefined;
    permissions?:
        | Array<{ __typename?: 'Permission'; id: string; name: string; isDel: boolean } | null | undefined>
        | null
        | undefined;
};

export type GetProfileQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetProfileQuery = {
    __typename?: 'Query';
    getProfile?:
        | {
              __typename?: 'UserResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        email: string;
                        roleId: string;
                        permissionId: Array<string | null | undefined>;
                        status: UserStatus;
                        isDel: boolean;
                        businessRegistration?:
                            | {
                                  __typename?: 'BusinessRegistration';
                                  id: string;
                                  companyName: string;
                                  companyWeb: string;
                                  registrationNumber: string;
                                  country: string;
                                  numberOfEmployees: number;
                                  contactPersonName: string;
                                  contactPersonTitle: string;
                                  phoneNumber: string;
                                  email: string;
                                  companyAddress: string;
                                  weChatAccount: string;
                                  facebookAccount: string;
                                  typesCompany: Array<string | null | undefined>;
                                  otherContent?: string | null | undefined;
                                  annualSales: Array<string | null | undefined>;
                                  createdBy?: string | null | undefined;
                                  updatedBy?: string | null | undefined;
                                  businessRegistrationURL?: string | null | undefined;
                                  isDel: boolean;
                                  companyIndustryClassifications?:
                                      | Array<
                                            | {
                                                  __typename?: 'CompanyIndustryClassification';
                                                  id: string;
                                                  businessRegistrationId?: string | null | undefined;
                                                  businessTypeId: string;
                                                  businessItems?: Array<string> | null | undefined;
                                              }
                                            | null
                                            | undefined
                                        >
                                      | null
                                      | undefined;
                              }
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetUsersQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetUsersQuery = {
    __typename?: 'Query';
    getUsers?:
        | {
              __typename?: 'UsersResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'UserPaginate';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'User';
                                        id: string;
                                        fullName: string;
                                        email: string;
                                        roleId: string;
                                        permissionId: Array<string | null | undefined>;
                                        status: UserStatus;
                                        isDel: boolean;
                                        role?:
                                            | { __typename?: 'Role'; id: string; name: string; isDel: boolean }
                                            | null
                                            | undefined;
                                        permissions?:
                                            | Array<
                                                  | {
                                                        __typename?: 'Permission';
                                                        id: string;
                                                        name: string;
                                                        isDel: boolean;
                                                    }
                                                  | null
                                                  | undefined
                                              >
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type CreateUserMutationVariables = Exact<{
    user: InputUserCreate;
}>;

export type CreateUserMutation = {
    __typename?: 'Mutation';
    createUser: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  businessRegistration?:
                      | {
                            __typename?: 'BusinessRegistration';
                            id: string;
                            companyName: string;
                            companyWeb: string;
                            registrationNumber: string;
                            country: string;
                            numberOfEmployees: number;
                            contactPersonName: string;
                            contactPersonTitle: string;
                            phoneNumber: string;
                            email: string;
                            companyAddress: string;
                            weChatAccount: string;
                            facebookAccount: string;
                            typesCompany: Array<string | null | undefined>;
                            otherContent?: string | null | undefined;
                            annualSales: Array<string | null | undefined>;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            businessRegistrationURL?: string | null | undefined;
                            isDel: boolean;
                            companyIndustryClassifications?:
                                | Array<
                                      | {
                                            __typename?: 'CompanyIndustryClassification';
                                            id: string;
                                            businessRegistrationId?: string | null | undefined;
                                            businessTypeId: string;
                                            businessItems?: Array<string> | null | undefined;
                                        }
                                      | null
                                      | undefined
                                  >
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdateUserMutationVariables = Exact<{
    user: InputUserUpdate;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  businessRegistration?:
                      | {
                            __typename?: 'BusinessRegistration';
                            id: string;
                            companyName: string;
                            companyWeb: string;
                            registrationNumber: string;
                            country: string;
                            numberOfEmployees: number;
                            contactPersonName: string;
                            contactPersonTitle: string;
                            phoneNumber: string;
                            email: string;
                            companyAddress: string;
                            weChatAccount: string;
                            facebookAccount: string;
                            typesCompany: Array<string | null | undefined>;
                            otherContent?: string | null | undefined;
                            annualSales: Array<string | null | undefined>;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            businessRegistrationURL?: string | null | undefined;
                            isDel: boolean;
                            companyIndustryClassifications?:
                                | Array<
                                      | {
                                            __typename?: 'CompanyIndustryClassification';
                                            id: string;
                                            businessRegistrationId?: string | null | undefined;
                                            businessTypeId: string;
                                            businessItems?: Array<string> | null | undefined;
                                        }
                                      | null
                                      | undefined
                                  >
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeleteUserMutationVariables = Exact<{
    user: InputUserId;
}>;

export type DeleteUserMutation = {
    __typename?: 'Mutation';
    deleteUser: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  businessRegistration?:
                      | {
                            __typename?: 'BusinessRegistration';
                            id: string;
                            companyName: string;
                            companyWeb: string;
                            registrationNumber: string;
                            country: string;
                            numberOfEmployees: number;
                            contactPersonName: string;
                            contactPersonTitle: string;
                            phoneNumber: string;
                            email: string;
                            companyAddress: string;
                            weChatAccount: string;
                            facebookAccount: string;
                            typesCompany: Array<string | null | undefined>;
                            otherContent?: string | null | undefined;
                            annualSales: Array<string | null | undefined>;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            businessRegistrationURL?: string | null | undefined;
                            isDel: boolean;
                            companyIndustryClassifications?:
                                | Array<
                                      | {
                                            __typename?: 'CompanyIndustryClassification';
                                            id: string;
                                            businessRegistrationId?: string | null | undefined;
                                            businessTypeId: string;
                                            businessItems?: Array<string> | null | undefined;
                                        }
                                      | null
                                      | undefined
                                  >
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type RemoveUserMutationVariables = Exact<{
    user: InputUserId;
}>;

export type RemoveUserMutation = {
    __typename?: 'Mutation';
    removeUser: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  businessRegistration?:
                      | {
                            __typename?: 'BusinessRegistration';
                            id: string;
                            companyName: string;
                            companyWeb: string;
                            registrationNumber: string;
                            country: string;
                            numberOfEmployees: number;
                            contactPersonName: string;
                            contactPersonTitle: string;
                            phoneNumber: string;
                            email: string;
                            companyAddress: string;
                            weChatAccount: string;
                            facebookAccount: string;
                            typesCompany: Array<string | null | undefined>;
                            otherContent?: string | null | undefined;
                            annualSales: Array<string | null | undefined>;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            businessRegistrationURL?: string | null | undefined;
                            isDel: boolean;
                            companyIndustryClassifications?:
                                | Array<
                                      | {
                                            __typename?: 'CompanyIndustryClassification';
                                            id: string;
                                            businessRegistrationId?: string | null | undefined;
                                            businessTypeId: string;
                                            businessItems?: Array<string> | null | undefined;
                                        }
                                      | null
                                      | undefined
                                  >
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type RecoverUserMutationVariables = Exact<{
    user: InputUserId;
}>;

export type RecoverUserMutation = {
    __typename?: 'Mutation';
    recoverUser: {
        __typename?: 'UserResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'User';
                  id: string;
                  fullName: string;
                  email: string;
                  roleId: string;
                  permissionId: Array<string | null | undefined>;
                  status: UserStatus;
                  isDel: boolean;
                  businessRegistration?:
                      | {
                            __typename?: 'BusinessRegistration';
                            id: string;
                            companyName: string;
                            companyWeb: string;
                            registrationNumber: string;
                            country: string;
                            numberOfEmployees: number;
                            contactPersonName: string;
                            contactPersonTitle: string;
                            phoneNumber: string;
                            email: string;
                            companyAddress: string;
                            weChatAccount: string;
                            facebookAccount: string;
                            typesCompany: Array<string | null | undefined>;
                            otherContent?: string | null | undefined;
                            annualSales: Array<string | null | undefined>;
                            createdBy?: string | null | undefined;
                            updatedBy?: string | null | undefined;
                            businessRegistrationURL?: string | null | undefined;
                            isDel: boolean;
                            companyIndustryClassifications?:
                                | Array<
                                      | {
                                            __typename?: 'CompanyIndustryClassification';
                                            id: string;
                                            businessRegistrationId?: string | null | undefined;
                                            businessTypeId: string;
                                            businessItems?: Array<string> | null | undefined;
                                        }
                                      | null
                                      | undefined
                                  >
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type WriterInfoFragment = {
    __typename?: 'Writer';
    id?: string | null | undefined;
    name?: string | null | undefined;
    profession?: string | null | undefined;
    nationality?: string | null | undefined;
    company?: string | null | undefined;
    address?: string | null | undefined;
    email?: string | null | undefined;
    receivingAccount?: string | null | undefined;
    newsCategory?: string | null | undefined;
    createdAt?: any | null | undefined;
    createdBy?: string | null | undefined;
    updatedAt?: any | null | undefined;
    updatedBy?: string | null | undefined;
    writerURL?: Array<string | null | undefined> | null | undefined;
    isDel?: boolean | null | undefined;
    userCreated?:
        | {
              __typename?: 'User';
              id: string;
              fullName: string;
              email: string;
              roleId: string;
              permissionId: Array<string | null | undefined>;
              status: UserStatus;
              isDel: boolean;
              businessRegistration?:
                  | {
                        __typename?: 'BusinessRegistration';
                        id: string;
                        companyName: string;
                        companyWeb: string;
                        registrationNumber: string;
                        country: string;
                        numberOfEmployees: number;
                        contactPersonName: string;
                        contactPersonTitle: string;
                        phoneNumber: string;
                        email: string;
                        companyAddress: string;
                        weChatAccount: string;
                        facebookAccount: string;
                        typesCompany: Array<string | null | undefined>;
                        otherContent?: string | null | undefined;
                        annualSales: Array<string | null | undefined>;
                        createdBy?: string | null | undefined;
                        updatedBy?: string | null | undefined;
                        businessRegistrationURL?: string | null | undefined;
                        isDel: boolean;
                        companyIndustryClassifications?:
                            | Array<
                                  | {
                                        __typename?: 'CompanyIndustryClassification';
                                        id: string;
                                        businessRegistrationId?: string | null | undefined;
                                        businessTypeId: string;
                                        businessItems?: Array<string> | null | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetWritersQueryVariables = Exact<{
    filters?: Maybe<InputPagingRequest>;
}>;

export type GetWritersQuery = {
    __typename?: 'Query';
    getWriters?:
        | {
              __typename?: 'WritersResponse';
              success: boolean;
              message?: string | null | undefined;
              result?:
                  | {
                        __typename?: 'WriterPagination';
                        totalDocs?: number | null | undefined;
                        limit?: number | null | undefined;
                        hasPrevPage?: boolean | null | undefined;
                        hasNextPage?: boolean | null | undefined;
                        page?: number | null | undefined;
                        totalPages?: number | null | undefined;
                        offset?: number | null | undefined;
                        prevPage?: number | null | undefined;
                        nextPage?: number | null | undefined;
                        pagingCounter?: number | null | undefined;
                        meta?: any | null | undefined;
                        docs?:
                            | Array<
                                  | {
                                        __typename?: 'Writer';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        profession?: string | null | undefined;
                                        nationality?: string | null | undefined;
                                        company?: string | null | undefined;
                                        address?: string | null | undefined;
                                        email?: string | null | undefined;
                                        receivingAccount?: string | null | undefined;
                                        newsCategory?: string | null | undefined;
                                        createdAt?: any | null | undefined;
                                        createdBy?: string | null | undefined;
                                        updatedAt?: any | null | undefined;
                                        updatedBy?: string | null | undefined;
                                        writerURL?: Array<string | null | undefined> | null | undefined;
                                        isDel?: boolean | null | undefined;
                                        userCreated?:
                                            | {
                                                  __typename?: 'User';
                                                  id: string;
                                                  fullName: string;
                                                  email: string;
                                                  roleId: string;
                                                  permissionId: Array<string | null | undefined>;
                                                  status: UserStatus;
                                                  isDel: boolean;
                                                  businessRegistration?:
                                                      | {
                                                            __typename?: 'BusinessRegistration';
                                                            id: string;
                                                            companyName: string;
                                                            companyWeb: string;
                                                            registrationNumber: string;
                                                            country: string;
                                                            numberOfEmployees: number;
                                                            contactPersonName: string;
                                                            contactPersonTitle: string;
                                                            phoneNumber: string;
                                                            email: string;
                                                            companyAddress: string;
                                                            weChatAccount: string;
                                                            facebookAccount: string;
                                                            typesCompany: Array<string | null | undefined>;
                                                            otherContent?: string | null | undefined;
                                                            annualSales: Array<string | null | undefined>;
                                                            createdBy?: string | null | undefined;
                                                            updatedBy?: string | null | undefined;
                                                            businessRegistrationURL?: string | null | undefined;
                                                            isDel: boolean;
                                                            companyIndustryClassifications?:
                                                                | Array<
                                                                      | {
                                                                            __typename?: 'CompanyIndustryClassification';
                                                                            id: string;
                                                                            businessRegistrationId?:
                                                                                | string
                                                                                | null
                                                                                | undefined;
                                                                            businessTypeId: string;
                                                                            businessItems?:
                                                                                | Array<string>
                                                                                | null
                                                                                | undefined;
                                                                        }
                                                                      | null
                                                                      | undefined
                                                                  >
                                                                | null
                                                                | undefined;
                                                        }
                                                      | null
                                                      | undefined;
                                              }
                                            | null
                                            | undefined;
                                    }
                                  | null
                                  | undefined
                              >
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetWriterQueryVariables = Exact<{
    writerId: Scalars['String'];
}>;

export type GetWriterQuery = {
    __typename?: 'Query';
    getWriter: {
        __typename?: 'WriterResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Writer';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                  profession?: string | null | undefined;
                  nationality?: string | null | undefined;
                  company?: string | null | undefined;
                  address?: string | null | undefined;
                  email?: string | null | undefined;
                  receivingAccount?: string | null | undefined;
                  newsCategory?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                  updatedBy?: string | null | undefined;
                  writerURL?: Array<string | null | undefined> | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?:
                      | {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            roleId: string;
                            permissionId: Array<string | null | undefined>;
                            status: UserStatus;
                            isDel: boolean;
                            businessRegistration?:
                                | {
                                      __typename?: 'BusinessRegistration';
                                      id: string;
                                      companyName: string;
                                      companyWeb: string;
                                      registrationNumber: string;
                                      country: string;
                                      numberOfEmployees: number;
                                      contactPersonName: string;
                                      contactPersonTitle: string;
                                      phoneNumber: string;
                                      email: string;
                                      companyAddress: string;
                                      weChatAccount: string;
                                      facebookAccount: string;
                                      typesCompany: Array<string | null | undefined>;
                                      otherContent?: string | null | undefined;
                                      annualSales: Array<string | null | undefined>;
                                      createdBy?: string | null | undefined;
                                      updatedBy?: string | null | undefined;
                                      businessRegistrationURL?: string | null | undefined;
                                      isDel: boolean;
                                      companyIndustryClassifications?:
                                          | Array<
                                                | {
                                                      __typename?: 'CompanyIndustryClassification';
                                                      id: string;
                                                      businessRegistrationId?: string | null | undefined;
                                                      businessTypeId: string;
                                                      businessItems?: Array<string> | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                            >
                                          | null
                                          | undefined;
                                  }
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type CreateWriterMutationVariables = Exact<{
    writer: InputWriterCreate;
}>;

export type CreateWriterMutation = {
    __typename?: 'Mutation';
    createWriter: {
        __typename?: 'WriterResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Writer';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                  profession?: string | null | undefined;
                  nationality?: string | null | undefined;
                  company?: string | null | undefined;
                  address?: string | null | undefined;
                  email?: string | null | undefined;
                  receivingAccount?: string | null | undefined;
                  newsCategory?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                  updatedBy?: string | null | undefined;
                  writerURL?: Array<string | null | undefined> | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?:
                      | {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            roleId: string;
                            permissionId: Array<string | null | undefined>;
                            status: UserStatus;
                            isDel: boolean;
                            businessRegistration?:
                                | {
                                      __typename?: 'BusinessRegistration';
                                      id: string;
                                      companyName: string;
                                      companyWeb: string;
                                      registrationNumber: string;
                                      country: string;
                                      numberOfEmployees: number;
                                      contactPersonName: string;
                                      contactPersonTitle: string;
                                      phoneNumber: string;
                                      email: string;
                                      companyAddress: string;
                                      weChatAccount: string;
                                      facebookAccount: string;
                                      typesCompany: Array<string | null | undefined>;
                                      otherContent?: string | null | undefined;
                                      annualSales: Array<string | null | undefined>;
                                      createdBy?: string | null | undefined;
                                      updatedBy?: string | null | undefined;
                                      businessRegistrationURL?: string | null | undefined;
                                      isDel: boolean;
                                      companyIndustryClassifications?:
                                          | Array<
                                                | {
                                                      __typename?: 'CompanyIndustryClassification';
                                                      id: string;
                                                      businessRegistrationId?: string | null | undefined;
                                                      businessTypeId: string;
                                                      businessItems?: Array<string> | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                            >
                                          | null
                                          | undefined;
                                  }
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type UpdateWriterMutationVariables = Exact<{
    writer: InputWriterUpdate;
}>;

export type UpdateWriterMutation = {
    __typename?: 'Mutation';
    updateWriter: {
        __typename?: 'WriterResponse';
        success: boolean;
        message?: string | null | undefined;
        result?:
            | {
                  __typename?: 'Writer';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                  profession?: string | null | undefined;
                  nationality?: string | null | undefined;
                  company?: string | null | undefined;
                  address?: string | null | undefined;
                  email?: string | null | undefined;
                  receivingAccount?: string | null | undefined;
                  newsCategory?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  createdBy?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                  updatedBy?: string | null | undefined;
                  writerURL?: Array<string | null | undefined> | null | undefined;
                  isDel?: boolean | null | undefined;
                  userCreated?:
                      | {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            roleId: string;
                            permissionId: Array<string | null | undefined>;
                            status: UserStatus;
                            isDel: boolean;
                            businessRegistration?:
                                | {
                                      __typename?: 'BusinessRegistration';
                                      id: string;
                                      companyName: string;
                                      companyWeb: string;
                                      registrationNumber: string;
                                      country: string;
                                      numberOfEmployees: number;
                                      contactPersonName: string;
                                      contactPersonTitle: string;
                                      phoneNumber: string;
                                      email: string;
                                      companyAddress: string;
                                      weChatAccount: string;
                                      facebookAccount: string;
                                      typesCompany: Array<string | null | undefined>;
                                      otherContent?: string | null | undefined;
                                      annualSales: Array<string | null | undefined>;
                                      createdBy?: string | null | undefined;
                                      updatedBy?: string | null | undefined;
                                      businessRegistrationURL?: string | null | undefined;
                                      isDel: boolean;
                                      companyIndustryClassifications?:
                                          | Array<
                                                | {
                                                      __typename?: 'CompanyIndustryClassification';
                                                      id: string;
                                                      businessRegistrationId?: string | null | undefined;
                                                      businessTypeId: string;
                                                      businessItems?: Array<string> | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                            >
                                          | null
                                          | undefined;
                                  }
                                | null
                                | undefined;
                        }
                      | null
                      | undefined;
              }
            | null
            | undefined;
    };
};

export type DeleteWritersMutationVariables = Exact<{
    writerIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type DeleteWritersMutation = {
    __typename?: 'Mutation';
    deleteWriters: { __typename?: 'DeleteWriterResponse'; success: boolean; message?: string | null | undefined };
};

export type UploadWriterFileMutationVariables = Exact<{
    file: Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>;
    writerId: Scalars['String'];
}>;

export type UploadWriterFileMutation = {
    __typename?: 'Mutation';
    uploadWriterFile: { __typename?: 'UploadWriterFileResponse'; success: boolean };
};

export type RemoveWriterFileMutationVariables = Exact<{
    writerId: Scalars['String'];
}>;

export type RemoveWriterFileMutation = {
    __typename?: 'Mutation';
    removeWriterFile: { __typename?: 'removeWriterFileResponse'; success: boolean };
};

export const UserWithAuthorizationInfoFragmentDoc = gql`
    fragment UserWithAuthorizationInfo on User {
        id
        fullName
        email
        roleId
        role {
            id
            name
            isDel
        }
        permissionId
        permissions {
            id
            name
            isDel
        }
        status
        isDel
    }
`;
export const AuthResponseInfoFragmentDoc = gql`
    fragment AuthResponseInfo on authResponse {
        success
        user {
            ...UserWithAuthorizationInfo
        }
        role {
            id
            name
        }
        permission {
            id
            name
        }
        token
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export const AuthResponseWithoutTokenInfoFragmentDoc = gql`
    fragment AuthResponseWithoutTokenInfo on authResponse {
        success
        user {
            ...UserWithAuthorizationInfo
        }
        role {
            id
            name
        }
        permission {
            id
            name
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export const BusinessItemInfoFragmentDoc = gql`
    fragment BusinessItemInfo on BusinessItem {
        id
        name
        businessTypeId
        createdAt
        updatedAt
        isDel
    }
`;
export const BusinessTypeInfoFragmentDoc = gql`
    fragment BusinessTypeInfo on BusinessType {
        id
        name
        businessItems {
            ...BusinessItemInfo
        }
        createdAt
        updatedAt
        isDel
    }
    ${BusinessItemInfoFragmentDoc}
`;
export const PackageInfoFragmentDoc = gql`
    fragment PackageInfo on Package {
        id
        name
        packageCode
        content
        price
        packageLocationId
        packageLocation {
            name
        }
        location
        supplement
        otherIndex
        packageImgURL
        parentPackageId
        parentPackage {
            id
            name
            price
        }
        childPackages {
            id
            name
            price
            isDel
        }
        createdBy
        createdAt
        updatedBy
        updatedAt
        isDel
    }
`;
export const CompanyIndustryClassificationInfoFragmentDoc = gql`
    fragment CompanyIndustryClassificationInfo on CompanyIndustryClassification {
        id
        businessRegistrationId
        businessTypeId
        businessItems
    }
`;
export const BusinessRegistrationInfoFragmentDoc = gql`
    fragment BusinessRegistrationInfo on BusinessRegistration {
        id
        companyName
        companyWeb
        registrationNumber
        country
        numberOfEmployees
        contactPersonName
        contactPersonTitle
        phoneNumber
        email
        companyAddress
        weChatAccount
        facebookAccount
        typesCompany
        otherContent
        annualSales
        companyIndustryClassifications {
            ...CompanyIndustryClassificationInfo
        }
        createdBy
        updatedBy
        businessRegistrationURL
        isDel
    }
    ${CompanyIndustryClassificationInfoFragmentDoc}
`;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
        id
        fullName
        email
        businessRegistration {
            ...BusinessRegistrationInfo
        }
        roleId
        permissionId
        status
        isDel
    }
    ${BusinessRegistrationInfoFragmentDoc}
`;
export const CommentInfoFragmentDoc = gql`
    fragment CommentInfo on Comment {
        id
        content
        rateStar
        isApproved
        approvedBy
        rejectedBy
        packageId
        package {
            ...PackageInfo
        }
        createdBy
        userCreated {
            ...UserInfo
        }
        updatedBy
        isDel
        createdAt
        updatedAt
        hide
    }
    ${PackageInfoFragmentDoc}
    ${UserInfoFragmentDoc}
`;
export const UserCommentFragmentDoc = gql`
    fragment UserComment on Comment {
        id
        content
        rateStar
        packageId
        isDel
        updatedAt
    }
`;
export const MediaInfoFragmentDoc = gql`
    fragment MediaInfo on Media {
        id
        mediaName
        mediaWeb
        registrationNumber
        established
        contactPersonName
        contactPersonTitle
        address
        email
        receivingAccount
        newsCategoriesCovered
        createdAt
        createdBy
        userCreated {
            ...UserInfo
        }
        updatedAt
        updatedBy
        mediaURL
        isDel
    }
    ${UserInfoFragmentDoc}
`;
export const OrderItemProcessingInfoFragmentDoc = gql`
    fragment OrderItemProcessingInfo on OrderItemProcessing {
        id
        status
        orderItemId
        createdBy
        updatedBy
        isDel
    }
`;
export const CityInfoFragmentDoc = gql`
    fragment CityInfo on City {
        id
        name
        countryId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isDel
    }
`;
export const CountryInfoFragmentDoc = gql`
    fragment CountryInfo on Country {
        id
        name
        cities {
            ...CityInfo
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        isDel
    }
    ${CityInfoFragmentDoc}
`;
export const MediaListReviewInfoFragmentDoc = gql`
    fragment MediaListReviewInfo on MediaListReview {
        id
        firstMedia
        secondMedia
        thirdMedia
        orderItemId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isDel
    }
`;
export const PublishTimeReviewInfoFragmentDoc = gql`
    fragment PublishTimeReviewInfo on PublishTimeReview {
        id
        firstPublicationTime
        secondPublicationTime
        thirdPublicationTime
        orderItemId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isDel
    }
`;
export const PublishLinkReviewInfoFragmentDoc = gql`
    fragment PublishLinkReviewInfo on PublishLinkReview {
        id
        firstPublishLink
        secondPublishLink
        thirdPublishLink
        orderItemId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isDel
    }
`;
export const ReportReviewInfoFragmentDoc = gql`
    fragment ReportReviewInfo on ReportReview {
        id
        reportReviewURL
        orderItemId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isDel
    }
`;
export const OrderItemInfoFragmentDoc = gql`
    fragment OrderItemInfo on OrderItem {
        id
        orderItemCode
        quantity
        orderId
        packageId
        package {
            ...PackageInfo
        }
        orderItemsProcessing {
            ...OrderItemProcessingInfo
        }
        countryId
        country {
            ...CountryInfo
        }
        cityId
        city {
            ...CityInfo
        }
        mediaType
        remark
        mediaListReview {
            ...MediaListReviewInfo
        }
        publishTimeReview {
            ...PublishTimeReviewInfo
        }
        publishLinkReview {
            ...PublishLinkReviewInfo
        }
        reportReview {
            ...ReportReviewInfo
        }
        orderItemURL
        createdBy
        createdAt
        updatedBy
        updatedAt
        isDel
    }
    ${PackageInfoFragmentDoc}
    ${OrderItemProcessingInfoFragmentDoc}
    ${CountryInfoFragmentDoc}
    ${CityInfoFragmentDoc}
    ${MediaListReviewInfoFragmentDoc}
    ${PublishTimeReviewInfoFragmentDoc}
    ${PublishLinkReviewInfoFragmentDoc}
    ${ReportReviewInfoFragmentDoc}
`;
export const PaymentInformationInfoFragmentDoc = gql`
    fragment PaymentInformationInfo on PaymentInformation {
        id
        companyName
        contactPersonName
        phoneNumber
        email
        address
        orderId
        createdBy
        updatedBy
        isDel
    }
`;
export const PaymentDetailInfoFragmentDoc = gql`
    fragment PaymentDetailInfo on PaymentDetail {
        id
        payerId
        paymentId
        payerName
        paymentMethod
        paymentAmount
        createdBy
        updatedBy
        isDel
    }
`;
export const OrderInfoFragmentDoc = gql`
    fragment OrderInfo on Order {
        id
        orderCode
        status
        otherCost
        total
        orderItems {
            ...OrderItemInfo
        }
        paymentInformation {
            ...PaymentInformationInfo
        }
        paymentDetailId
        paymentDetail {
            ...PaymentDetailInfo
        }
        createdAt
        endDate
        createdBy
        updatedAt
        updatedBy
        comment
        isDel
    }
    ${OrderItemInfoFragmentDoc}
    ${PaymentInformationInfoFragmentDoc}
    ${PaymentDetailInfoFragmentDoc}
`;
export const PackageLocationInfoFragmentDoc = gql`
    fragment PackageLocationInfo on PackageLocation {
        id
        name
        createdAt
        packages {
            ...PackageInfo
        }
        isDel
    }
    ${PackageInfoFragmentDoc}
`;
export const PackageCustomizeInfoFragmentDoc = gql`
    fragment PackageCustomizeInfo on CustomBusiness {
        id
        platformPublications
        formatPublications
        needTranslateService
        publishingLanguage
        postingLocation
        city
        readerOccupations
        readerAge
        createdBy
        updatedBy
        isDel
    }
`;
export const AddressInfoFragmentDoc = gql`
    fragment AddressInfo on Address {
        isDefault
        cityId
        cityName
        districtId
        districtName
        wardId
        wardName
        street
    }
`;
export const WriterInfoFragmentDoc = gql`
    fragment WriterInfo on Writer {
        id
        name
        profession
        nationality
        company
        address
        email
        receivingAccount
        newsCategory
        createdAt
        createdBy
        userCreated {
            ...UserInfo
        }
        updatedAt
        updatedBy
        writerURL
        isDel
    }
    ${UserInfoFragmentDoc}
`;
export const CheckAuthDocument = gql`
    query checkAuth($token: InputAuthToken) {
        checkAuth(token: $token) {
            ...AuthResponseInfo
        }
    }
    ${AuthResponseInfoFragmentDoc}
`;

/**
 * __useCheckAuthQuery__
 *
 * To run a query within a React component, call `useCheckAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuthQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckAuthQuery(baseOptions?: Apollo.QueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
}
export function useCheckAuthLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
}
export type CheckAuthQueryHookResult = ReturnType<typeof useCheckAuthQuery>;
export type CheckAuthLazyQueryHookResult = ReturnType<typeof useCheckAuthLazyQuery>;
export type CheckAuthQueryResult = Apollo.QueryResult<CheckAuthQuery, CheckAuthQueryVariables>;
export const CheckAuthAdminDocument = gql`
    query checkAuthAdmin($token: InputAuthToken) {
        checkAuthAdmin(token: $token) {
            ...AuthResponseInfo
        }
    }
    ${AuthResponseInfoFragmentDoc}
`;

/**
 * __useCheckAuthAdminQuery__
 *
 * To run a query within a React component, call `useCheckAuthAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuthAdminQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckAuthAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<CheckAuthAdminQuery, CheckAuthAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CheckAuthAdminQuery, CheckAuthAdminQueryVariables>(CheckAuthAdminDocument, options);
}
export function useCheckAuthAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckAuthAdminQuery, CheckAuthAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CheckAuthAdminQuery, CheckAuthAdminQueryVariables>(CheckAuthAdminDocument, options);
}
export type CheckAuthAdminQueryHookResult = ReturnType<typeof useCheckAuthAdminQuery>;
export type CheckAuthAdminLazyQueryHookResult = ReturnType<typeof useCheckAuthAdminLazyQuery>;
export type CheckAuthAdminQueryResult = Apollo.QueryResult<CheckAuthAdminQuery, CheckAuthAdminQueryVariables>;
export const LoginDocument = gql`
    mutation login($user: InputAuthLogin!) {
        login(user: $user) {
            ...AuthResponseInfo
        }
    }
    ${AuthResponseInfoFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginAdminDocument = gql`
    mutation loginAdmin($user: InputAuthLogin!) {
        loginAdmin(user: $user) {
            ...AuthResponseInfo
        }
    }
    ${AuthResponseInfoFragmentDoc}
`;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLoginAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
}
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const RegisterDocument = gql`
    mutation register($user: InputAuthRegister!) {
        register(user: $user) {
            ...AuthResponseInfo
        }
    }
    ${AuthResponseInfoFragmentDoc}
`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRegisterMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
        logout {
            success
        }
    }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ForgotPassDocument = gql`
    mutation forgotPass($user: InputUserForgotPass!) {
        forgotPass(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ForgotPassMutationFn = Apollo.MutationFunction<ForgotPassMutation, ForgotPassMutationVariables>;

/**
 * __useForgotPassMutation__
 *
 * To run a mutation, you first call `useForgotPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPassMutation, { data, loading, error }] = useForgotPassMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useForgotPassMutation(
    baseOptions?: Apollo.MutationHookOptions<ForgotPassMutation, ForgotPassMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ForgotPassMutation, ForgotPassMutationVariables>(ForgotPassDocument, options);
}
export type ForgotPassMutationHookResult = ReturnType<typeof useForgotPassMutation>;
export type ForgotPassMutationResult = Apollo.MutationResult<ForgotPassMutation>;
export type ForgotPassMutationOptions = Apollo.BaseMutationOptions<ForgotPassMutation, ForgotPassMutationVariables>;
export const VerifyResetPassTokenDocument = gql`
    mutation verifyResetPassToken($user: InputUserVerifyResetPassToken!) {
        verifyResetPassToken(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type VerifyResetPassTokenMutationFn = Apollo.MutationFunction<
    VerifyResetPassTokenMutation,
    VerifyResetPassTokenMutationVariables
>;

/**
 * __useVerifyResetPassTokenMutation__
 *
 * To run a mutation, you first call `useVerifyResetPassTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetPassTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyResetPassTokenMutation, { data, loading, error }] = useVerifyResetPassTokenMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useVerifyResetPassTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyResetPassTokenMutation, VerifyResetPassTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyResetPassTokenMutation, VerifyResetPassTokenMutationVariables>(
        VerifyResetPassTokenDocument,
        options
    );
}
export type VerifyResetPassTokenMutationHookResult = ReturnType<typeof useVerifyResetPassTokenMutation>;
export type VerifyResetPassTokenMutationResult = Apollo.MutationResult<VerifyResetPassTokenMutation>;
export type VerifyResetPassTokenMutationOptions = Apollo.BaseMutationOptions<
    VerifyResetPassTokenMutation,
    VerifyResetPassTokenMutationVariables
>;
export const UpdatePassViaMailDocument = gql`
    mutation updatePassViaMail($user: InputUserUpdatePassViaMail!) {
        updatePassViaMail(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type UpdatePassViaMailMutationFn = Apollo.MutationFunction<
    UpdatePassViaMailMutation,
    UpdatePassViaMailMutationVariables
>;

/**
 * __useUpdatePassViaMailMutation__
 *
 * To run a mutation, you first call `useUpdatePassViaMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassViaMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassViaMailMutation, { data, loading, error }] = useUpdatePassViaMailMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdatePassViaMailMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePassViaMailMutation, UpdatePassViaMailMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePassViaMailMutation, UpdatePassViaMailMutationVariables>(
        UpdatePassViaMailDocument,
        options
    );
}
export type UpdatePassViaMailMutationHookResult = ReturnType<typeof useUpdatePassViaMailMutation>;
export type UpdatePassViaMailMutationResult = Apollo.MutationResult<UpdatePassViaMailMutation>;
export type UpdatePassViaMailMutationOptions = Apollo.BaseMutationOptions<
    UpdatePassViaMailMutation,
    UpdatePassViaMailMutationVariables
>;
export const ActivationAccountDocument = gql`
    mutation activationAccount($user: InputActivationAccount!) {
        activationAccount(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ActivationAccountMutationFn = Apollo.MutationFunction<
    ActivationAccountMutation,
    ActivationAccountMutationVariables
>;

/**
 * __useActivationAccountMutation__
 *
 * To run a mutation, you first call `useActivationAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivationAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activationAccountMutation, { data, loading, error }] = useActivationAccountMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useActivationAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivationAccountMutation, ActivationAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ActivationAccountMutation, ActivationAccountMutationVariables>(
        ActivationAccountDocument,
        options
    );
}
export type ActivationAccountMutationHookResult = ReturnType<typeof useActivationAccountMutation>;
export type ActivationAccountMutationResult = Apollo.MutationResult<ActivationAccountMutation>;
export type ActivationAccountMutationOptions = Apollo.BaseMutationOptions<
    ActivationAccountMutation,
    ActivationAccountMutationVariables
>;
export const ResendActivationAccountDocument = gql`
    mutation resendActivationAccount($user: InputResendActivationAccount!) {
        resendActivationAccount(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ResendActivationAccountMutationFn = Apollo.MutationFunction<
    ResendActivationAccountMutation,
    ResendActivationAccountMutationVariables
>;

/**
 * __useResendActivationAccountMutation__
 *
 * To run a mutation, you first call `useResendActivationAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationAccountMutation, { data, loading, error }] = useResendActivationAccountMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResendActivationAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ResendActivationAccountMutation, ResendActivationAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResendActivationAccountMutation, ResendActivationAccountMutationVariables>(
        ResendActivationAccountDocument,
        options
    );
}
export type ResendActivationAccountMutationHookResult = ReturnType<typeof useResendActivationAccountMutation>;
export type ResendActivationAccountMutationResult = Apollo.MutationResult<ResendActivationAccountMutation>;
export type ResendActivationAccountMutationOptions = Apollo.BaseMutationOptions<
    ResendActivationAccountMutation,
    ResendActivationAccountMutationVariables
>;
export const LoginFacebookDocument = gql`
    mutation loginFacebook($facebookUser: InputFacebookUser!) {
        loginFacebook(facebookUser: $facebookUser) {
            ...AuthResponseWithoutTokenInfo
        }
    }
    ${AuthResponseWithoutTokenInfoFragmentDoc}
`;
export type LoginFacebookMutationFn = Apollo.MutationFunction<LoginFacebookMutation, LoginFacebookMutationVariables>;

/**
 * __useLoginFacebookMutation__
 *
 * To run a mutation, you first call `useLoginFacebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginFacebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginFacebookMutation, { data, loading, error }] = useLoginFacebookMutation({
 *   variables: {
 *      facebookUser: // value for 'facebookUser'
 *   },
 * });
 */
export function useLoginFacebookMutation(
    baseOptions?: Apollo.MutationHookOptions<LoginFacebookMutation, LoginFacebookMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LoginFacebookMutation, LoginFacebookMutationVariables>(LoginFacebookDocument, options);
}
export type LoginFacebookMutationHookResult = ReturnType<typeof useLoginFacebookMutation>;
export type LoginFacebookMutationResult = Apollo.MutationResult<LoginFacebookMutation>;
export type LoginFacebookMutationOptions = Apollo.BaseMutationOptions<
    LoginFacebookMutation,
    LoginFacebookMutationVariables
>;
export const ChangePassDocument = gql`
    mutation changePass($user: InputUserChangePass!) {
        changePass(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ChangePassMutationFn = Apollo.MutationFunction<ChangePassMutation, ChangePassMutationVariables>;

/**
 * __useChangePassMutation__
 *
 * To run a mutation, you first call `useChangePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePassMutation, { data, loading, error }] = useChangePassMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useChangePassMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePassMutation, ChangePassMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePassMutation, ChangePassMutationVariables>(ChangePassDocument, options);
}
export type ChangePassMutationHookResult = ReturnType<typeof useChangePassMutation>;
export type ChangePassMutationResult = Apollo.MutationResult<ChangePassMutation>;
export type ChangePassMutationOptions = Apollo.BaseMutationOptions<ChangePassMutation, ChangePassMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($user: InputUserChangeEmail!) {
        changeEmail(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useChangeEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, options);
}
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ResetEmailDocument = gql`
    mutation resetEmail($user: InputUserResetEmail!) {
        resetEmail(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ResetEmailMutationFn = Apollo.MutationFunction<ResetEmailMutation, ResetEmailMutationVariables>;

/**
 * __useResetEmailMutation__
 *
 * To run a mutation, you first call `useResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEmailMutation, { data, loading, error }] = useResetEmailMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResetEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetEmailMutation, ResetEmailMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetEmailMutation, ResetEmailMutationVariables>(ResetEmailDocument, options);
}
export type ResetEmailMutationHookResult = ReturnType<typeof useResetEmailMutation>;
export type ResetEmailMutationResult = Apollo.MutationResult<ResetEmailMutation>;
export type ResetEmailMutationOptions = Apollo.BaseMutationOptions<ResetEmailMutation, ResetEmailMutationVariables>;
export const ConfirmAccountDocument = gql`
    mutation confirmAccount($user: InputUserConfirmAccount!) {
        confirmAccount(user: $user) {
            success
            message
            result {
                ...UserWithAuthorizationInfo
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;
export type ConfirmAccountMutationFn = Apollo.MutationFunction<ConfirmAccountMutation, ConfirmAccountMutationVariables>;

/**
 * __useConfirmAccountMutation__
 *
 * To run a mutation, you first call `useConfirmAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAccountMutation, { data, loading, error }] = useConfirmAccountMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useConfirmAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmAccountMutation, ConfirmAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmAccountMutation, ConfirmAccountMutationVariables>(ConfirmAccountDocument, options);
}
export type ConfirmAccountMutationHookResult = ReturnType<typeof useConfirmAccountMutation>;
export type ConfirmAccountMutationResult = Apollo.MutationResult<ConfirmAccountMutation>;
export type ConfirmAccountMutationOptions = Apollo.BaseMutationOptions<
    ConfirmAccountMutation,
    ConfirmAccountMutationVariables
>;
export const GetRolesDocument = gql`
    query getRoles($filters: InputPagingRequest) {
        getRoles(filters: $filters) {
            success
            message
            result {
                docs {
                    id
                    name
                    isDel
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetPermissionsDocument = gql`
    query getPermissions($filters: InputPagingRequest) {
        getPermissions(filters: $filters) {
            success
            message
            result {
                docs {
                    id
                    name
                    isDel
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPermissionsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
}
export function useGetPermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
}
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetRolesAndPermissionsDocument = gql`
    query getRolesAndPermissions($filters: InputPagingRequest) {
        getRoles(filters: $filters) {
            success
            message
            result {
                docs {
                    id
                    name
                    isDel
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
        getPermissions(filters: $filters) {
            success
            message
            result {
                docs {
                    id
                    name
                    isDel
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
`;

/**
 * __useGetRolesAndPermissionsQuery__
 *
 * To run a query within a React component, call `useGetRolesAndPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesAndPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesAndPermissionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRolesAndPermissionsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetRolesAndPermissionsQuery, GetRolesAndPermissionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetRolesAndPermissionsQuery, GetRolesAndPermissionsQueryVariables>(
        GetRolesAndPermissionsDocument,
        options
    );
}
export function useGetRolesAndPermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetRolesAndPermissionsQuery, GetRolesAndPermissionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetRolesAndPermissionsQuery, GetRolesAndPermissionsQueryVariables>(
        GetRolesAndPermissionsDocument,
        options
    );
}
export type GetRolesAndPermissionsQueryHookResult = ReturnType<typeof useGetRolesAndPermissionsQuery>;
export type GetRolesAndPermissionsLazyQueryHookResult = ReturnType<typeof useGetRolesAndPermissionsLazyQuery>;
export type GetRolesAndPermissionsQueryResult = Apollo.QueryResult<
    GetRolesAndPermissionsQuery,
    GetRolesAndPermissionsQueryVariables
>;
export const GetBusinessRegistrationsDocument = gql`
    query getBusinessRegistrations($filters: InputPagingRequest) {
        getBusinessRegistrations(filters: $filters) {
            success
            message
            result {
                docs {
                    ...BusinessRegistrationInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${BusinessRegistrationInfoFragmentDoc}
`;

/**
 * __useGetBusinessRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetBusinessRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessRegistrationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetBusinessRegistrationsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetBusinessRegistrationsQuery, GetBusinessRegistrationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetBusinessRegistrationsQuery, GetBusinessRegistrationsQueryVariables>(
        GetBusinessRegistrationsDocument,
        options
    );
}
export function useGetBusinessRegistrationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessRegistrationsQuery, GetBusinessRegistrationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetBusinessRegistrationsQuery, GetBusinessRegistrationsQueryVariables>(
        GetBusinessRegistrationsDocument,
        options
    );
}
export type GetBusinessRegistrationsQueryHookResult = ReturnType<typeof useGetBusinessRegistrationsQuery>;
export type GetBusinessRegistrationsLazyQueryHookResult = ReturnType<typeof useGetBusinessRegistrationsLazyQuery>;
export type GetBusinessRegistrationsQueryResult = Apollo.QueryResult<
    GetBusinessRegistrationsQuery,
    GetBusinessRegistrationsQueryVariables
>;
export const GetBusinessRegistrationDocument = gql`
    query getBusinessRegistration($businessRegistrationId: String!) {
        getBusinessRegistration(businessRegistrationId: $businessRegistrationId) {
            success
            message
            result {
                ...BusinessRegistrationInfo
            }
        }
    }
    ${BusinessRegistrationInfoFragmentDoc}
`;

/**
 * __useGetBusinessRegistrationQuery__
 *
 * To run a query within a React component, call `useGetBusinessRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessRegistrationQuery({
 *   variables: {
 *      businessRegistrationId: // value for 'businessRegistrationId'
 *   },
 * });
 */
export function useGetBusinessRegistrationQuery(
    baseOptions: Apollo.QueryHookOptions<GetBusinessRegistrationQuery, GetBusinessRegistrationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetBusinessRegistrationQuery, GetBusinessRegistrationQueryVariables>(
        GetBusinessRegistrationDocument,
        options
    );
}
export function useGetBusinessRegistrationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessRegistrationQuery, GetBusinessRegistrationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetBusinessRegistrationQuery, GetBusinessRegistrationQueryVariables>(
        GetBusinessRegistrationDocument,
        options
    );
}
export type GetBusinessRegistrationQueryHookResult = ReturnType<typeof useGetBusinessRegistrationQuery>;
export type GetBusinessRegistrationLazyQueryHookResult = ReturnType<typeof useGetBusinessRegistrationLazyQuery>;
export type GetBusinessRegistrationQueryResult = Apollo.QueryResult<
    GetBusinessRegistrationQuery,
    GetBusinessRegistrationQueryVariables
>;
export const CreateBusinessRegistrationDocument = gql`
    mutation createBusinessRegistration($businessRegistration: InputBusinessRegistrationCreate!) {
        createBusinessRegistration(businessRegistration: $businessRegistration) {
            success
            message
            result {
                ...BusinessRegistrationInfo
            }
        }
    }
    ${BusinessRegistrationInfoFragmentDoc}
`;
export type CreateBusinessRegistrationMutationFn = Apollo.MutationFunction<
    CreateBusinessRegistrationMutation,
    CreateBusinessRegistrationMutationVariables
>;

/**
 * __useCreateBusinessRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessRegistrationMutation, { data, loading, error }] = useCreateBusinessRegistrationMutation({
 *   variables: {
 *      businessRegistration: // value for 'businessRegistration'
 *   },
 * });
 */
export function useCreateBusinessRegistrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBusinessRegistrationMutation,
        CreateBusinessRegistrationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBusinessRegistrationMutation, CreateBusinessRegistrationMutationVariables>(
        CreateBusinessRegistrationDocument,
        options
    );
}
export type CreateBusinessRegistrationMutationHookResult = ReturnType<typeof useCreateBusinessRegistrationMutation>;
export type CreateBusinessRegistrationMutationResult = Apollo.MutationResult<CreateBusinessRegistrationMutation>;
export type CreateBusinessRegistrationMutationOptions = Apollo.BaseMutationOptions<
    CreateBusinessRegistrationMutation,
    CreateBusinessRegistrationMutationVariables
>;
export const UpdateBusinessRegistrationDocument = gql`
    mutation updateBusinessRegistration($businessRegistration: InputBusinessRegistrationUpdate!) {
        updateBusinessRegistration(businessRegistration: $businessRegistration) {
            success
            message
            result {
                ...BusinessRegistrationInfo
            }
        }
    }
    ${BusinessRegistrationInfoFragmentDoc}
`;
export type UpdateBusinessRegistrationMutationFn = Apollo.MutationFunction<
    UpdateBusinessRegistrationMutation,
    UpdateBusinessRegistrationMutationVariables
>;

/**
 * __useUpdateBusinessRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessRegistrationMutation, { data, loading, error }] = useUpdateBusinessRegistrationMutation({
 *   variables: {
 *      businessRegistration: // value for 'businessRegistration'
 *   },
 * });
 */
export function useUpdateBusinessRegistrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBusinessRegistrationMutation,
        UpdateBusinessRegistrationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateBusinessRegistrationMutation, UpdateBusinessRegistrationMutationVariables>(
        UpdateBusinessRegistrationDocument,
        options
    );
}
export type UpdateBusinessRegistrationMutationHookResult = ReturnType<typeof useUpdateBusinessRegistrationMutation>;
export type UpdateBusinessRegistrationMutationResult = Apollo.MutationResult<UpdateBusinessRegistrationMutation>;
export type UpdateBusinessRegistrationMutationOptions = Apollo.BaseMutationOptions<
    UpdateBusinessRegistrationMutation,
    UpdateBusinessRegistrationMutationVariables
>;
export const DeleteBusinessRegistrationsDocument = gql`
    mutation deleteBusinessRegistrations($businessRegistrationIds: [String]!) {
        deleteBusinessRegistrations(businessRegistrationIds: $businessRegistrationIds) {
            success
            message
        }
    }
`;
export type DeleteBusinessRegistrationsMutationFn = Apollo.MutationFunction<
    DeleteBusinessRegistrationsMutation,
    DeleteBusinessRegistrationsMutationVariables
>;

/**
 * __useDeleteBusinessRegistrationsMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessRegistrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessRegistrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessRegistrationsMutation, { data, loading, error }] = useDeleteBusinessRegistrationsMutation({
 *   variables: {
 *      businessRegistrationIds: // value for 'businessRegistrationIds'
 *   },
 * });
 */
export function useDeleteBusinessRegistrationsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteBusinessRegistrationsMutation,
        DeleteBusinessRegistrationsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteBusinessRegistrationsMutation, DeleteBusinessRegistrationsMutationVariables>(
        DeleteBusinessRegistrationsDocument,
        options
    );
}
export type DeleteBusinessRegistrationsMutationHookResult = ReturnType<typeof useDeleteBusinessRegistrationsMutation>;
export type DeleteBusinessRegistrationsMutationResult = Apollo.MutationResult<DeleteBusinessRegistrationsMutation>;
export type DeleteBusinessRegistrationsMutationOptions = Apollo.BaseMutationOptions<
    DeleteBusinessRegistrationsMutation,
    DeleteBusinessRegistrationsMutationVariables
>;
export const UploadBusinessRegistrationFileDocument = gql`
    mutation uploadBusinessRegistrationFile($file: Upload!, $businessRegistrationId: String!) {
        uploadBusinessRegistrationFile(file: $file, businessRegistrationId: $businessRegistrationId) {
            success
        }
    }
`;
export type UploadBusinessRegistrationFileMutationFn = Apollo.MutationFunction<
    UploadBusinessRegistrationFileMutation,
    UploadBusinessRegistrationFileMutationVariables
>;

/**
 * __useUploadBusinessRegistrationFileMutation__
 *
 * To run a mutation, you first call `useUploadBusinessRegistrationFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBusinessRegistrationFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBusinessRegistrationFileMutation, { data, loading, error }] = useUploadBusinessRegistrationFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      businessRegistrationId: // value for 'businessRegistrationId'
 *   },
 * });
 */
export function useUploadBusinessRegistrationFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadBusinessRegistrationFileMutation,
        UploadBusinessRegistrationFileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadBusinessRegistrationFileMutation, UploadBusinessRegistrationFileMutationVariables>(
        UploadBusinessRegistrationFileDocument,
        options
    );
}
export type UploadBusinessRegistrationFileMutationHookResult = ReturnType<
    typeof useUploadBusinessRegistrationFileMutation
>;
export type UploadBusinessRegistrationFileMutationResult =
    Apollo.MutationResult<UploadBusinessRegistrationFileMutation>;
export type UploadBusinessRegistrationFileMutationOptions = Apollo.BaseMutationOptions<
    UploadBusinessRegistrationFileMutation,
    UploadBusinessRegistrationFileMutationVariables
>;
export const RemoveBusinessRegistrationFileDocument = gql`
    mutation removeBusinessRegistrationFile($businessRegistrationId: String!) {
        removeBusinessRegistrationFile(businessRegistrationId: $businessRegistrationId) {
            success
        }
    }
`;
export type RemoveBusinessRegistrationFileMutationFn = Apollo.MutationFunction<
    RemoveBusinessRegistrationFileMutation,
    RemoveBusinessRegistrationFileMutationVariables
>;

/**
 * __useRemoveBusinessRegistrationFileMutation__
 *
 * To run a mutation, you first call `useRemoveBusinessRegistrationFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBusinessRegistrationFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBusinessRegistrationFileMutation, { data, loading, error }] = useRemoveBusinessRegistrationFileMutation({
 *   variables: {
 *      businessRegistrationId: // value for 'businessRegistrationId'
 *   },
 * });
 */
export function useRemoveBusinessRegistrationFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveBusinessRegistrationFileMutation,
        RemoveBusinessRegistrationFileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveBusinessRegistrationFileMutation, RemoveBusinessRegistrationFileMutationVariables>(
        RemoveBusinessRegistrationFileDocument,
        options
    );
}
export type RemoveBusinessRegistrationFileMutationHookResult = ReturnType<
    typeof useRemoveBusinessRegistrationFileMutation
>;
export type RemoveBusinessRegistrationFileMutationResult =
    Apollo.MutationResult<RemoveBusinessRegistrationFileMutation>;
export type RemoveBusinessRegistrationFileMutationOptions = Apollo.BaseMutationOptions<
    RemoveBusinessRegistrationFileMutation,
    RemoveBusinessRegistrationFileMutationVariables
>;
export const GetBusinessTypesDocument = gql`
    query getBusinessTypes($filters: InputPagingRequest) {
        getBusinessTypes(filters: $filters) {
            success
            message
            result {
                ...BusinessTypeInfo
            }
        }
    }
    ${BusinessTypeInfoFragmentDoc}
`;

/**
 * __useGetBusinessTypesQuery__
 *
 * To run a query within a React component, call `useGetBusinessTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetBusinessTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>(GetBusinessTypesDocument, options);
}
export function useGetBusinessTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>(
        GetBusinessTypesDocument,
        options
    );
}
export type GetBusinessTypesQueryHookResult = ReturnType<typeof useGetBusinessTypesQuery>;
export type GetBusinessTypesLazyQueryHookResult = ReturnType<typeof useGetBusinessTypesLazyQuery>;
export type GetBusinessTypesQueryResult = Apollo.QueryResult<GetBusinessTypesQuery, GetBusinessTypesQueryVariables>;
export const CreateCityDocument = gql`
    mutation createCity($city: InputCityCreate!) {
        createCity(city: $city) {
            success
            message
            result {
                ...CityInfo
            }
        }
    }
    ${CityInfoFragmentDoc}
`;
export type CreateCityMutationFn = Apollo.MutationFunction<CreateCityMutation, CreateCityMutationVariables>;

/**
 * __useCreateCityMutation__
 *
 * To run a mutation, you first call `useCreateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCityMutation, { data, loading, error }] = useCreateCityMutation({
 *   variables: {
 *      city: // value for 'city'
 *   },
 * });
 */
export function useCreateCityMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCityMutation, CreateCityMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCityMutation, CreateCityMutationVariables>(CreateCityDocument, options);
}
export type CreateCityMutationHookResult = ReturnType<typeof useCreateCityMutation>;
export type CreateCityMutationResult = Apollo.MutationResult<CreateCityMutation>;
export type CreateCityMutationOptions = Apollo.BaseMutationOptions<CreateCityMutation, CreateCityMutationVariables>;
export const UpdateCityDocument = gql`
    mutation updateCity($city: InputCityUpdate!) {
        updateCity(city: $city) {
            success
            message
            result {
                ...CityInfo
            }
        }
    }
    ${CityInfoFragmentDoc}
`;
export type UpdateCityMutationFn = Apollo.MutationFunction<UpdateCityMutation, UpdateCityMutationVariables>;

/**
 * __useUpdateCityMutation__
 *
 * To run a mutation, you first call `useUpdateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCityMutation, { data, loading, error }] = useUpdateCityMutation({
 *   variables: {
 *      city: // value for 'city'
 *   },
 * });
 */
export function useUpdateCityMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCityMutation, UpdateCityMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateCityMutation, UpdateCityMutationVariables>(UpdateCityDocument, options);
}
export type UpdateCityMutationHookResult = ReturnType<typeof useUpdateCityMutation>;
export type UpdateCityMutationResult = Apollo.MutationResult<UpdateCityMutation>;
export type UpdateCityMutationOptions = Apollo.BaseMutationOptions<UpdateCityMutation, UpdateCityMutationVariables>;
export const DeleteCitiesDocument = gql`
    mutation deleteCities($cityIds: [String]!) {
        deleteCities(cityIds: $cityIds) {
            success
            message
        }
    }
`;
export type DeleteCitiesMutationFn = Apollo.MutationFunction<DeleteCitiesMutation, DeleteCitiesMutationVariables>;

/**
 * __useDeleteCitiesMutation__
 *
 * To run a mutation, you first call `useDeleteCitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCitiesMutation, { data, loading, error }] = useDeleteCitiesMutation({
 *   variables: {
 *      cityIds: // value for 'cityIds'
 *   },
 * });
 */
export function useDeleteCitiesMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCitiesMutation, DeleteCitiesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteCitiesMutation, DeleteCitiesMutationVariables>(DeleteCitiesDocument, options);
}
export type DeleteCitiesMutationHookResult = ReturnType<typeof useDeleteCitiesMutation>;
export type DeleteCitiesMutationResult = Apollo.MutationResult<DeleteCitiesMutation>;
export type DeleteCitiesMutationOptions = Apollo.BaseMutationOptions<
    DeleteCitiesMutation,
    DeleteCitiesMutationVariables
>;
export const GetCommentsDocument = gql`
    query getComments($filters: InputPagingRequest) {
        getComments(filters: $filters) {
            success
            message
            result {
                docs {
                    ...CommentInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${CommentInfoFragmentDoc}
`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCommentsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
}
export function useGetCommentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
}
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetUserCommentsDocument = gql`
    query getUserComments($filters: InputPagingRequest) {
        getComments(filters: $filters) {
            success
            message
            result {
                docs {
                    ...UserComment
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${UserCommentFragmentDoc}
`;

/**
 * __useGetUserCommentsQuery__
 *
 * To run a query within a React component, call `useGetUserCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCommentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUserCommentsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUserCommentsQuery, GetUserCommentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserCommentsQuery, GetUserCommentsQueryVariables>(GetUserCommentsDocument, options);
}
export function useGetUserCommentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserCommentsQuery, GetUserCommentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserCommentsQuery, GetUserCommentsQueryVariables>(GetUserCommentsDocument, options);
}
export type GetUserCommentsQueryHookResult = ReturnType<typeof useGetUserCommentsQuery>;
export type GetUserCommentsLazyQueryHookResult = ReturnType<typeof useGetUserCommentsLazyQuery>;
export type GetUserCommentsQueryResult = Apollo.QueryResult<GetUserCommentsQuery, GetUserCommentsQueryVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($comment: InputCommentCreate!) {
        createComment(comment: $comment) {
            success
            message
            result {
                content
                rateStar
            }
        }
    }
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
>;
export const DeleteCommentsDocument = gql`
    mutation deleteComments($commentIds: [String]!) {
        deleteComments(commentIds: $commentIds) {
            success
            message
        }
    }
`;
export type DeleteCommentsMutationFn = Apollo.MutationFunction<DeleteCommentsMutation, DeleteCommentsMutationVariables>;

/**
 * __useDeleteCommentsMutation__
 *
 * To run a mutation, you first call `useDeleteCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentsMutation, { data, loading, error }] = useDeleteCommentsMutation({
 *   variables: {
 *      commentIds: // value for 'commentIds'
 *   },
 * });
 */
export function useDeleteCommentsMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCommentsMutation, DeleteCommentsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteCommentsMutation, DeleteCommentsMutationVariables>(DeleteCommentsDocument, options);
}
export type DeleteCommentsMutationHookResult = ReturnType<typeof useDeleteCommentsMutation>;
export type DeleteCommentsMutationResult = Apollo.MutationResult<DeleteCommentsMutation>;
export type DeleteCommentsMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommentsMutation,
    DeleteCommentsMutationVariables
>;
export const ApproveCommentsDocument = gql`
    mutation approveComments($commentIds: [String]!) {
        approveComments(commentIds: $commentIds) {
            success
            message
        }
    }
`;
export type ApproveCommentsMutationFn = Apollo.MutationFunction<
    ApproveCommentsMutation,
    ApproveCommentsMutationVariables
>;

/**
 * __useApproveCommentsMutation__
 *
 * To run a mutation, you first call `useApproveCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCommentsMutation, { data, loading, error }] = useApproveCommentsMutation({
 *   variables: {
 *      commentIds: // value for 'commentIds'
 *   },
 * });
 */
export function useApproveCommentsMutation(
    baseOptions?: Apollo.MutationHookOptions<ApproveCommentsMutation, ApproveCommentsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ApproveCommentsMutation, ApproveCommentsMutationVariables>(
        ApproveCommentsDocument,
        options
    );
}
export type ApproveCommentsMutationHookResult = ReturnType<typeof useApproveCommentsMutation>;
export type ApproveCommentsMutationResult = Apollo.MutationResult<ApproveCommentsMutation>;
export type ApproveCommentsMutationOptions = Apollo.BaseMutationOptions<
    ApproveCommentsMutation,
    ApproveCommentsMutationVariables
>;
export const RejectCommentsDocument = gql`
    mutation rejectComments($commentIds: [String]!) {
        rejectComments(commentIds: $commentIds) {
            success
            message
        }
    }
`;
export type RejectCommentsMutationFn = Apollo.MutationFunction<RejectCommentsMutation, RejectCommentsMutationVariables>;

/**
 * __useRejectCommentsMutation__
 *
 * To run a mutation, you first call `useRejectCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCommentsMutation, { data, loading, error }] = useRejectCommentsMutation({
 *   variables: {
 *      commentIds: // value for 'commentIds'
 *   },
 * });
 */
export function useRejectCommentsMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectCommentsMutation, RejectCommentsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RejectCommentsMutation, RejectCommentsMutationVariables>(RejectCommentsDocument, options);
}
export type RejectCommentsMutationHookResult = ReturnType<typeof useRejectCommentsMutation>;
export type RejectCommentsMutationResult = Apollo.MutationResult<RejectCommentsMutation>;
export type RejectCommentsMutationOptions = Apollo.BaseMutationOptions<
    RejectCommentsMutation,
    RejectCommentsMutationVariables
>;
export const HideCommentDocument = gql`
    mutation hideComment($comment: InputHideComment!) {
        hideComment(comment: $comment) {
            success
            message
        }
    }
`;
export type HideCommentMutationFn = Apollo.MutationFunction<HideCommentMutation, HideCommentMutationVariables>;

/**
 * __useHideCommentMutation__
 *
 * To run a mutation, you first call `useHideCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideCommentMutation, { data, loading, error }] = useHideCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useHideCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<HideCommentMutation, HideCommentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<HideCommentMutation, HideCommentMutationVariables>(HideCommentDocument, options);
}
export type HideCommentMutationHookResult = ReturnType<typeof useHideCommentMutation>;
export type HideCommentMutationResult = Apollo.MutationResult<HideCommentMutation>;
export type HideCommentMutationOptions = Apollo.BaseMutationOptions<HideCommentMutation, HideCommentMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($contact: InputContact!) {
        createContact(contact: $contact) {
            success
        }
    }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateContactMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
    CreateContactMutation,
    CreateContactMutationVariables
>;
export const GetCountriesDocument = gql`
    query getCountries {
        getCountries {
            success
            message
            result {
                ...CountryInfo
            }
        }
    }
    ${CountryInfoFragmentDoc}
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
}
export function useGetCountriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
}
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const CreateCountryDocument = gql`
    mutation createCountry($country: InputCountryCreate!) {
        createCountry(country: $country) {
            success
            message
            result {
                ...CountryInfo
            }
        }
    }
    ${CountryInfoFragmentDoc}
`;
export type CreateCountryMutationFn = Apollo.MutationFunction<CreateCountryMutation, CreateCountryMutationVariables>;

/**
 * __useCreateCountryMutation__
 *
 * To run a mutation, you first call `useCreateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryMutation, { data, loading, error }] = useCreateCountryMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreateCountryMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCountryMutation, CreateCountryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, options);
}
export type CreateCountryMutationHookResult = ReturnType<typeof useCreateCountryMutation>;
export type CreateCountryMutationResult = Apollo.MutationResult<CreateCountryMutation>;
export type CreateCountryMutationOptions = Apollo.BaseMutationOptions<
    CreateCountryMutation,
    CreateCountryMutationVariables
>;
export const UpdateCountryDocument = gql`
    mutation updateCountry($country: InputCountryUpdate!) {
        updateCountry(country: $country) {
            success
            message
            result {
                ...CountryInfo
            }
        }
    }
    ${CountryInfoFragmentDoc}
`;
export type UpdateCountryMutationFn = Apollo.MutationFunction<UpdateCountryMutation, UpdateCountryMutationVariables>;

/**
 * __useUpdateCountryMutation__
 *
 * To run a mutation, you first call `useUpdateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountryMutation, { data, loading, error }] = useUpdateCountryMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useUpdateCountryMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCountryMutation, UpdateCountryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateCountryMutation, UpdateCountryMutationVariables>(UpdateCountryDocument, options);
}
export type UpdateCountryMutationHookResult = ReturnType<typeof useUpdateCountryMutation>;
export type UpdateCountryMutationResult = Apollo.MutationResult<UpdateCountryMutation>;
export type UpdateCountryMutationOptions = Apollo.BaseMutationOptions<
    UpdateCountryMutation,
    UpdateCountryMutationVariables
>;
export const DeleteCountriesDocument = gql`
    mutation deleteCountries($countryIds: [String]!) {
        deleteCountries(countryIds: $countryIds) {
            success
            message
        }
    }
`;
export type DeleteCountriesMutationFn = Apollo.MutationFunction<
    DeleteCountriesMutation,
    DeleteCountriesMutationVariables
>;

/**
 * __useDeleteCountriesMutation__
 *
 * To run a mutation, you first call `useDeleteCountriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCountriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCountriesMutation, { data, loading, error }] = useDeleteCountriesMutation({
 *   variables: {
 *      countryIds: // value for 'countryIds'
 *   },
 * });
 */
export function useDeleteCountriesMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCountriesMutation, DeleteCountriesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteCountriesMutation, DeleteCountriesMutationVariables>(
        DeleteCountriesDocument,
        options
    );
}
export type DeleteCountriesMutationHookResult = ReturnType<typeof useDeleteCountriesMutation>;
export type DeleteCountriesMutationResult = Apollo.MutationResult<DeleteCountriesMutation>;
export type DeleteCountriesMutationOptions = Apollo.BaseMutationOptions<
    DeleteCountriesMutation,
    DeleteCountriesMutationVariables
>;
export const GetMediasDocument = gql`
    query getMedias($filters: InputPagingRequest) {
        getMedias(filters: $filters) {
            success
            message
            result {
                docs {
                    ...MediaInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${MediaInfoFragmentDoc}
`;

/**
 * __useGetMediasQuery__
 *
 * To run a query within a React component, call `useGetMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediasQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMediasQuery(baseOptions?: Apollo.QueryHookOptions<GetMediasQuery, GetMediasQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMediasQuery, GetMediasQueryVariables>(GetMediasDocument, options);
}
export function useGetMediasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetMediasQuery, GetMediasQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMediasQuery, GetMediasQueryVariables>(GetMediasDocument, options);
}
export type GetMediasQueryHookResult = ReturnType<typeof useGetMediasQuery>;
export type GetMediasLazyQueryHookResult = ReturnType<typeof useGetMediasLazyQuery>;
export type GetMediasQueryResult = Apollo.QueryResult<GetMediasQuery, GetMediasQueryVariables>;
export const GetMediaDocument = gql`
    query getMedia($mediaId: String!) {
        getMedia(mediaId: $mediaId) {
            success
            message
            result {
                ...MediaInfo
            }
        }
    }
    ${MediaInfoFragmentDoc}
`;

/**
 * __useGetMediaQuery__
 *
 * To run a query within a React component, call `useGetMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetMediaQuery(baseOptions: Apollo.QueryHookOptions<GetMediaQuery, GetMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMediaQuery, GetMediaQueryVariables>(GetMediaDocument, options);
}
export function useGetMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaQuery, GetMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMediaQuery, GetMediaQueryVariables>(GetMediaDocument, options);
}
export type GetMediaQueryHookResult = ReturnType<typeof useGetMediaQuery>;
export type GetMediaLazyQueryHookResult = ReturnType<typeof useGetMediaLazyQuery>;
export type GetMediaQueryResult = Apollo.QueryResult<GetMediaQuery, GetMediaQueryVariables>;
export const CreateMediaDocument = gql`
    mutation createMedia($media: InputMediaCreate!) {
        createMedia(media: $media) {
            success
            message
            result {
                ...MediaInfo
            }
        }
    }
    ${MediaInfoFragmentDoc}
`;
export type CreateMediaMutationFn = Apollo.MutationFunction<CreateMediaMutation, CreateMediaMutationVariables>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      media: // value for 'media'
 *   },
 * });
 */
export function useCreateMediaMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(CreateMediaDocument, options);
}
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<CreateMediaMutation, CreateMediaMutationVariables>;
export const UpdateMediaDocument = gql`
    mutation updateMedia($media: InputMediaUpdate!) {
        updateMedia(media: $media) {
            success
            message
            result {
                ...MediaInfo
            }
        }
    }
    ${MediaInfoFragmentDoc}
`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<UpdateMediaMutation, UpdateMediaMutationVariables>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      media: // value for 'media'
 *   },
 * });
 */
export function useUpdateMediaMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(UpdateMediaDocument, options);
}
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<UpdateMediaMutation, UpdateMediaMutationVariables>;
export const DeleteMediasDocument = gql`
    mutation deleteMedias($mediaIds: [String]!) {
        deleteMedias(mediaIds: $mediaIds) {
            success
            message
        }
    }
`;
export type DeleteMediasMutationFn = Apollo.MutationFunction<DeleteMediasMutation, DeleteMediasMutationVariables>;

/**
 * __useDeleteMediasMutation__
 *
 * To run a mutation, you first call `useDeleteMediasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediasMutation, { data, loading, error }] = useDeleteMediasMutation({
 *   variables: {
 *      mediaIds: // value for 'mediaIds'
 *   },
 * });
 */
export function useDeleteMediasMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteMediasMutation, DeleteMediasMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteMediasMutation, DeleteMediasMutationVariables>(DeleteMediasDocument, options);
}
export type DeleteMediasMutationHookResult = ReturnType<typeof useDeleteMediasMutation>;
export type DeleteMediasMutationResult = Apollo.MutationResult<DeleteMediasMutation>;
export type DeleteMediasMutationOptions = Apollo.BaseMutationOptions<
    DeleteMediasMutation,
    DeleteMediasMutationVariables
>;
export const UploadMediaFileDocument = gql`
    mutation uploadMediaFile($file: [Upload]!, $mediaId: String!) {
        uploadMediaFile(file: $file, mediaId: $mediaId) {
            success
        }
    }
`;
export type UploadMediaFileMutationFn = Apollo.MutationFunction<
    UploadMediaFileMutation,
    UploadMediaFileMutationVariables
>;

/**
 * __useUploadMediaFileMutation__
 *
 * To run a mutation, you first call `useUploadMediaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaFileMutation, { data, loading, error }] = useUploadMediaFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useUploadMediaFileMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadMediaFileMutation, UploadMediaFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadMediaFileMutation, UploadMediaFileMutationVariables>(
        UploadMediaFileDocument,
        options
    );
}
export type UploadMediaFileMutationHookResult = ReturnType<typeof useUploadMediaFileMutation>;
export type UploadMediaFileMutationResult = Apollo.MutationResult<UploadMediaFileMutation>;
export type UploadMediaFileMutationOptions = Apollo.BaseMutationOptions<
    UploadMediaFileMutation,
    UploadMediaFileMutationVariables
>;
export const RemoveMediaFileDocument = gql`
    mutation removeMediaFile($mediaId: String!) {
        removeMediaFile(mediaId: $mediaId) {
            success
        }
    }
`;
export type RemoveMediaFileMutationFn = Apollo.MutationFunction<
    RemoveMediaFileMutation,
    RemoveMediaFileMutationVariables
>;

/**
 * __useRemoveMediaFileMutation__
 *
 * To run a mutation, you first call `useRemoveMediaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaFileMutation, { data, loading, error }] = useRemoveMediaFileMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useRemoveMediaFileMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveMediaFileMutation, RemoveMediaFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveMediaFileMutation, RemoveMediaFileMutationVariables>(
        RemoveMediaFileDocument,
        options
    );
}
export type RemoveMediaFileMutationHookResult = ReturnType<typeof useRemoveMediaFileMutation>;
export type RemoveMediaFileMutationResult = Apollo.MutationResult<RemoveMediaFileMutation>;
export type RemoveMediaFileMutationOptions = Apollo.BaseMutationOptions<
    RemoveMediaFileMutation,
    RemoveMediaFileMutationVariables
>;
export const GetOrdersDocument = gql`
    query getOrders($filters: InputPagingRequest) {
        getOrders(filters: $filters) {
            success
            message
            result {
                totalDocs
                limit
                page
                totalPages
                offset
                prevPage
                nextPage
                docs {
                    ...OrderInfo
                }
            }
        }
    }
    ${OrderInfoFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($orderId: String!) {
        getOrder(orderId: $orderId) {
            success
            message
            result {
                ...OrderInfo
                userCreated {
                    fullName
                }
            }
        }
    }
    ${OrderInfoFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrdersByUserDocument = gql`
    query getOrdersByUser {
        getOrdersByUser {
            success
            message
            result {
                docs {
                    ...OrderInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${OrderInfoFragmentDoc}
`;

/**
 * __useGetOrdersByUserQuery__
 *
 * To run a query within a React component, call `useGetOrdersByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersByUserQuery(
    baseOptions?: Apollo.QueryHookOptions<GetOrdersByUserQuery, GetOrdersByUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetOrdersByUserQuery, GetOrdersByUserQueryVariables>(GetOrdersByUserDocument, options);
}
export function useGetOrdersByUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersByUserQuery, GetOrdersByUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetOrdersByUserQuery, GetOrdersByUserQueryVariables>(GetOrdersByUserDocument, options);
}
export type GetOrdersByUserQueryHookResult = ReturnType<typeof useGetOrdersByUserQuery>;
export type GetOrdersByUserLazyQueryHookResult = ReturnType<typeof useGetOrdersByUserLazyQuery>;
export type GetOrdersByUserQueryResult = Apollo.QueryResult<GetOrdersByUserQuery, GetOrdersByUserQueryVariables>;
export const GetOrderItemsDocument = gql`
    query getOrderItems($filter: InputPagingRequest) {
        getOrderItems(filter: $filter) {
            success
            message
            result {
                docs {
                    ...OrderItemInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${OrderItemInfoFragmentDoc}
`;

/**
 * __useGetOrderItemsQuery__
 *
 * To run a query within a React component, call `useGetOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrderItemsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetOrderItemsQuery, GetOrderItemsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetOrderItemsQuery, GetOrderItemsQueryVariables>(GetOrderItemsDocument, options);
}
export function useGetOrderItemsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetOrderItemsQuery, GetOrderItemsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetOrderItemsQuery, GetOrderItemsQueryVariables>(GetOrderItemsDocument, options);
}
export type GetOrderItemsQueryHookResult = ReturnType<typeof useGetOrderItemsQuery>;
export type GetOrderItemsLazyQueryHookResult = ReturnType<typeof useGetOrderItemsLazyQuery>;
export type GetOrderItemsQueryResult = Apollo.QueryResult<GetOrderItemsQuery, GetOrderItemsQueryVariables>;
export const UpdateOrderItemDocument = gql`
    mutation updateOrderItem($orderItem: InputOrderItemUpdate!) {
        updateOrderItem(orderItem: $orderItem) {
            success
            message
        }
    }
`;
export type UpdateOrderItemMutationFn = Apollo.MutationFunction<
    UpdateOrderItemMutation,
    UpdateOrderItemMutationVariables
>;

/**
 * __useUpdateOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemMutation, { data, loading, error }] = useUpdateOrderItemMutation({
 *   variables: {
 *      orderItem: // value for 'orderItem'
 *   },
 * });
 */
export function useUpdateOrderItemMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>(
        UpdateOrderItemDocument,
        options
    );
}
export type UpdateOrderItemMutationHookResult = ReturnType<typeof useUpdateOrderItemMutation>;
export type UpdateOrderItemMutationResult = Apollo.MutationResult<UpdateOrderItemMutation>;
export type UpdateOrderItemMutationOptions = Apollo.BaseMutationOptions<
    UpdateOrderItemMutation,
    UpdateOrderItemMutationVariables
>;
export const UploadOrderItemFileDocument = gql`
    mutation uploadOrderItemFile($file: Upload!) {
        uploadOrderItemFile(file: $file) {
            success
            orderItemURL
        }
    }
`;
export type UploadOrderItemFileMutationFn = Apollo.MutationFunction<
    UploadOrderItemFileMutation,
    UploadOrderItemFileMutationVariables
>;

/**
 * __useUploadOrderItemFileMutation__
 *
 * To run a mutation, you first call `useUploadOrderItemFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrderItemFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrderItemFileMutation, { data, loading, error }] = useUploadOrderItemFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadOrderItemFileMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadOrderItemFileMutation, UploadOrderItemFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadOrderItemFileMutation, UploadOrderItemFileMutationVariables>(
        UploadOrderItemFileDocument,
        options
    );
}
export type UploadOrderItemFileMutationHookResult = ReturnType<typeof useUploadOrderItemFileMutation>;
export type UploadOrderItemFileMutationResult = Apollo.MutationResult<UploadOrderItemFileMutation>;
export type UploadOrderItemFileMutationOptions = Apollo.BaseMutationOptions<
    UploadOrderItemFileMutation,
    UploadOrderItemFileMutationVariables
>;
export const RemoveOrderItemFileDocument = gql`
    mutation removeOrderItemFile($orderItemId: String!) {
        removeOrderItemFile(orderItemId: $orderItemId) {
            success
            message
        }
    }
`;
export type RemoveOrderItemFileMutationFn = Apollo.MutationFunction<
    RemoveOrderItemFileMutation,
    RemoveOrderItemFileMutationVariables
>;

/**
 * __useRemoveOrderItemFileMutation__
 *
 * To run a mutation, you first call `useRemoveOrderItemFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderItemFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderItemFileMutation, { data, loading, error }] = useRemoveOrderItemFileMutation({
 *   variables: {
 *      orderItemId: // value for 'orderItemId'
 *   },
 * });
 */
export function useRemoveOrderItemFileMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveOrderItemFileMutation, RemoveOrderItemFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveOrderItemFileMutation, RemoveOrderItemFileMutationVariables>(
        RemoveOrderItemFileDocument,
        options
    );
}
export type RemoveOrderItemFileMutationHookResult = ReturnType<typeof useRemoveOrderItemFileMutation>;
export type RemoveOrderItemFileMutationResult = Apollo.MutationResult<RemoveOrderItemFileMutation>;
export type RemoveOrderItemFileMutationOptions = Apollo.BaseMutationOptions<
    RemoveOrderItemFileMutation,
    RemoveOrderItemFileMutationVariables
>;
export const CreateOrderDocument = gql`
    mutation createOrder($order: InputOrderCreate!) {
        createOrder(order: $order) {
            success
            message
            result {
                ...OrderInfo
            }
        }
    }
    ${OrderInfoFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const DeleteOrdersDocument = gql`
    mutation deleteOrders($orderIds: [String]!) {
        deleteOrders(orderIds: $orderIds) {
            success
            message
        }
    }
`;
export type DeleteOrdersMutationFn = Apollo.MutationFunction<DeleteOrdersMutation, DeleteOrdersMutationVariables>;

/**
 * __useDeleteOrdersMutation__
 *
 * To run a mutation, you first call `useDeleteOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrdersMutation, { data, loading, error }] = useDeleteOrdersMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useDeleteOrdersMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteOrdersMutation, DeleteOrdersMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteOrdersMutation, DeleteOrdersMutationVariables>(DeleteOrdersDocument, options);
}
export type DeleteOrdersMutationHookResult = ReturnType<typeof useDeleteOrdersMutation>;
export type DeleteOrdersMutationResult = Apollo.MutationResult<DeleteOrdersMutation>;
export type DeleteOrdersMutationOptions = Apollo.BaseMutationOptions<
    DeleteOrdersMutation,
    DeleteOrdersMutationVariables
>;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($order: InputOrderUpdate!) {
        updateOrderStatus(order: $order) {
            success
            message
        }
    }
`;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(
        UpdateOrderStatusDocument,
        options
    );
}
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
>;
export const UpdateOrderCommentDocument = gql`
    mutation updateOrderComment($order: InputCommentOrder!) {
        updateOrderComment(order: $order) {
            success
            message
        }
    }
`;
export type UpdateOrderCommentMutationFn = Apollo.MutationFunction<
    UpdateOrderCommentMutation,
    UpdateOrderCommentMutationVariables
>;

/**
 * __useUpdateOrderCommentMutation__
 *
 * To run a mutation, you first call `useUpdateOrderCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderCommentMutation, { data, loading, error }] = useUpdateOrderCommentMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateOrderCommentMutation, UpdateOrderCommentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateOrderCommentMutation, UpdateOrderCommentMutationVariables>(
        UpdateOrderCommentDocument,
        options
    );
}
export type UpdateOrderCommentMutationHookResult = ReturnType<typeof useUpdateOrderCommentMutation>;
export type UpdateOrderCommentMutationResult = Apollo.MutationResult<UpdateOrderCommentMutation>;
export type UpdateOrderCommentMutationOptions = Apollo.BaseMutationOptions<
    UpdateOrderCommentMutation,
    UpdateOrderCommentMutationVariables
>;
export const GetPackageDocument = gql`
    query getPackage($packageId: String!) {
        getPackage(packageId: $packageId) {
            success
            message
            result {
                ...PackageInfo
            }
        }
    }
    ${PackageInfoFragmentDoc}
`;

/**
 * __useGetPackageQuery__
 *
 * To run a query within a React component, call `useGetPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageQuery({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useGetPackageQuery(baseOptions: Apollo.QueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
}
export function useGetPackageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
}
export type GetPackageQueryHookResult = ReturnType<typeof useGetPackageQuery>;
export type GetPackageLazyQueryHookResult = ReturnType<typeof useGetPackageLazyQuery>;
export type GetPackageQueryResult = Apollo.QueryResult<GetPackageQuery, GetPackageQueryVariables>;
export const GetPackagesDocument = gql`
    query getPackages($filters: InputPagingRequest) {
        getPackages(filters: $filters) {
            success
            message
            result {
                docs {
                    ...PackageInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${PackageInfoFragmentDoc}
`;

/**
 * __useGetPackagesQuery__
 *
 * To run a query within a React component, call `useGetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPackagesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
}
export function useGetPackagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
}
export type GetPackagesQueryHookResult = ReturnType<typeof useGetPackagesQuery>;
export type GetPackagesLazyQueryHookResult = ReturnType<typeof useGetPackagesLazyQuery>;
export type GetPackagesQueryResult = Apollo.QueryResult<GetPackagesQuery, GetPackagesQueryVariables>;
export const CreatePackageDocument = gql`
    mutation createPackage($package: InputPackageCreate!) {
        createPackage(package: $package) {
            success
            message
            result {
                ...PackageInfo
            }
        }
    }
    ${PackageInfoFragmentDoc}
`;
export type CreatePackageMutationFn = Apollo.MutationFunction<CreatePackageMutation, CreatePackageMutationVariables>;

/**
 * __useCreatePackageMutation__
 *
 * To run a mutation, you first call `useCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageMutation, { data, loading, error }] = useCreatePackageMutation({
 *   variables: {
 *      package: // value for 'package'
 *   },
 * });
 */
export function useCreatePackageMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePackageMutation, CreatePackageMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePackageMutation, CreatePackageMutationVariables>(CreatePackageDocument, options);
}
export type CreatePackageMutationHookResult = ReturnType<typeof useCreatePackageMutation>;
export type CreatePackageMutationResult = Apollo.MutationResult<CreatePackageMutation>;
export type CreatePackageMutationOptions = Apollo.BaseMutationOptions<
    CreatePackageMutation,
    CreatePackageMutationVariables
>;
export const UpdatePackageDocument = gql`
    mutation updatePackage($package: InputPackageUpdate!) {
        updatePackage(package: $package) {
            success
            message
            result {
                ...PackageInfo
            }
        }
    }
    ${PackageInfoFragmentDoc}
`;
export type UpdatePackageMutationFn = Apollo.MutationFunction<UpdatePackageMutation, UpdatePackageMutationVariables>;

/**
 * __useUpdatePackageMutation__
 *
 * To run a mutation, you first call `useUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageMutation, { data, loading, error }] = useUpdatePackageMutation({
 *   variables: {
 *      package: // value for 'package'
 *   },
 * });
 */
export function useUpdatePackageMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePackageMutation, UpdatePackageMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePackageMutation, UpdatePackageMutationVariables>(UpdatePackageDocument, options);
}
export type UpdatePackageMutationHookResult = ReturnType<typeof useUpdatePackageMutation>;
export type UpdatePackageMutationResult = Apollo.MutationResult<UpdatePackageMutation>;
export type UpdatePackageMutationOptions = Apollo.BaseMutationOptions<
    UpdatePackageMutation,
    UpdatePackageMutationVariables
>;
export const DeletePackagesDocument = gql`
    mutation deletePackages($packageIds: [String]!) {
        deletePackages(packageIds: $packageIds) {
            success
            message
        }
    }
`;
export type DeletePackagesMutationFn = Apollo.MutationFunction<DeletePackagesMutation, DeletePackagesMutationVariables>;

/**
 * __useDeletePackagesMutation__
 *
 * To run a mutation, you first call `useDeletePackagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackagesMutation, { data, loading, error }] = useDeletePackagesMutation({
 *   variables: {
 *      packageIds: // value for 'packageIds'
 *   },
 * });
 */
export function useDeletePackagesMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePackagesMutation, DeletePackagesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeletePackagesMutation, DeletePackagesMutationVariables>(DeletePackagesDocument, options);
}
export type DeletePackagesMutationHookResult = ReturnType<typeof useDeletePackagesMutation>;
export type DeletePackagesMutationResult = Apollo.MutationResult<DeletePackagesMutation>;
export type DeletePackagesMutationOptions = Apollo.BaseMutationOptions<
    DeletePackagesMutation,
    DeletePackagesMutationVariables
>;
export const GetPackageLocationDocument = gql`
    query getPackageLocation($packageLocationId: String!) {
        getPackageLocation(packageLocationId: $packageLocationId) {
            success
            message
            result {
                ...PackageLocationInfo
            }
        }
    }
    ${PackageLocationInfoFragmentDoc}
`;

/**
 * __useGetPackageLocationQuery__
 *
 * To run a query within a React component, call `useGetPackageLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageLocationQuery({
 *   variables: {
 *      packageLocationId: // value for 'packageLocationId'
 *   },
 * });
 */
export function useGetPackageLocationQuery(
    baseOptions: Apollo.QueryHookOptions<GetPackageLocationQuery, GetPackageLocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPackageLocationQuery, GetPackageLocationQueryVariables>(
        GetPackageLocationDocument,
        options
    );
}
export function useGetPackageLocationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPackageLocationQuery, GetPackageLocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPackageLocationQuery, GetPackageLocationQueryVariables>(
        GetPackageLocationDocument,
        options
    );
}
export type GetPackageLocationQueryHookResult = ReturnType<typeof useGetPackageLocationQuery>;
export type GetPackageLocationLazyQueryHookResult = ReturnType<typeof useGetPackageLocationLazyQuery>;
export type GetPackageLocationQueryResult = Apollo.QueryResult<
    GetPackageLocationQuery,
    GetPackageLocationQueryVariables
>;
export const GetPackageLocationsDocument = gql`
    query getPackageLocations($filter: InputPagingRequest) {
        getPackageLocations(filter: $filter) {
            success
            message
            result {
                ...PackageLocationInfo
            }
        }
    }
    ${PackageLocationInfoFragmentDoc}
`;

/**
 * __useGetPackageLocationsQuery__
 *
 * To run a query within a React component, call `useGetPackageLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPackageLocationsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetPackageLocationsQuery, GetPackageLocationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPackageLocationsQuery, GetPackageLocationsQueryVariables>(
        GetPackageLocationsDocument,
        options
    );
}
export function useGetPackageLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPackageLocationsQuery, GetPackageLocationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPackageLocationsQuery, GetPackageLocationsQueryVariables>(
        GetPackageLocationsDocument,
        options
    );
}
export type GetPackageLocationsQueryHookResult = ReturnType<typeof useGetPackageLocationsQuery>;
export type GetPackageLocationsLazyQueryHookResult = ReturnType<typeof useGetPackageLocationsLazyQuery>;
export type GetPackageLocationsQueryResult = Apollo.QueryResult<
    GetPackageLocationsQuery,
    GetPackageLocationsQueryVariables
>;
export const CreateCustomBusinessDocument = gql`
    mutation createCustomBusiness($customBusiness: InputCustomBusinessCreate!) {
        createCustomBusiness(customBusiness: $customBusiness) {
            success
            message
            result {
                ...PackageCustomizeInfo
            }
        }
    }
    ${PackageCustomizeInfoFragmentDoc}
`;
export type CreateCustomBusinessMutationFn = Apollo.MutationFunction<
    CreateCustomBusinessMutation,
    CreateCustomBusinessMutationVariables
>;

/**
 * __useCreateCustomBusinessMutation__
 *
 * To run a mutation, you first call `useCreateCustomBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomBusinessMutation, { data, loading, error }] = useCreateCustomBusinessMutation({
 *   variables: {
 *      customBusiness: // value for 'customBusiness'
 *   },
 * });
 */
export function useCreateCustomBusinessMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCustomBusinessMutation, CreateCustomBusinessMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCustomBusinessMutation, CreateCustomBusinessMutationVariables>(
        CreateCustomBusinessDocument,
        options
    );
}
export type CreateCustomBusinessMutationHookResult = ReturnType<typeof useCreateCustomBusinessMutation>;
export type CreateCustomBusinessMutationResult = Apollo.MutationResult<CreateCustomBusinessMutation>;
export type CreateCustomBusinessMutationOptions = Apollo.BaseMutationOptions<
    CreateCustomBusinessMutation,
    CreateCustomBusinessMutationVariables
>;
export const UploadCustomBusinessFileDocument = gql`
    mutation uploadCustomBusinessFile($file: Upload!, $customBusinessId: String!) {
        uploadCustomBusinessFile(file: $file, customBusinessId: $customBusinessId) {
            success
        }
    }
`;
export type UploadCustomBusinessFileMutationFn = Apollo.MutationFunction<
    UploadCustomBusinessFileMutation,
    UploadCustomBusinessFileMutationVariables
>;

/**
 * __useUploadCustomBusinessFileMutation__
 *
 * To run a mutation, you first call `useUploadCustomBusinessFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCustomBusinessFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCustomBusinessFileMutation, { data, loading, error }] = useUploadCustomBusinessFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      customBusinessId: // value for 'customBusinessId'
 *   },
 * });
 */
export function useUploadCustomBusinessFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadCustomBusinessFileMutation,
        UploadCustomBusinessFileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadCustomBusinessFileMutation, UploadCustomBusinessFileMutationVariables>(
        UploadCustomBusinessFileDocument,
        options
    );
}
export type UploadCustomBusinessFileMutationHookResult = ReturnType<typeof useUploadCustomBusinessFileMutation>;
export type UploadCustomBusinessFileMutationResult = Apollo.MutationResult<UploadCustomBusinessFileMutation>;
export type UploadCustomBusinessFileMutationOptions = Apollo.BaseMutationOptions<
    UploadCustomBusinessFileMutation,
    UploadCustomBusinessFileMutationVariables
>;
export const UploadPackageFileDocument = gql`
    mutation uploadPackageFile($file: Upload!, $packageId: String!) {
        uploadPackageFile(file: $file, packageId: $packageId) {
            success
        }
    }
`;
export type UploadPackageFileMutationFn = Apollo.MutationFunction<
    UploadPackageFileMutation,
    UploadPackageFileMutationVariables
>;

/**
 * __useUploadPackageFileMutation__
 *
 * To run a mutation, you first call `useUploadPackageFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPackageFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPackageFileMutation, { data, loading, error }] = useUploadPackageFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useUploadPackageFileMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadPackageFileMutation, UploadPackageFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadPackageFileMutation, UploadPackageFileMutationVariables>(
        UploadPackageFileDocument,
        options
    );
}
export type UploadPackageFileMutationHookResult = ReturnType<typeof useUploadPackageFileMutation>;
export type UploadPackageFileMutationResult = Apollo.MutationResult<UploadPackageFileMutation>;
export type UploadPackageFileMutationOptions = Apollo.BaseMutationOptions<
    UploadPackageFileMutation,
    UploadPackageFileMutationVariables
>;
export const UpdatePackageLocationDocument = gql`
    mutation updatePackageLocation($packageLocation: InputPackageLocationUpdate!) {
        updatePackageLocation(packageLocation: $packageLocation) {
            success
        }
    }
`;
export type UpdatePackageLocationMutationFn = Apollo.MutationFunction<
    UpdatePackageLocationMutation,
    UpdatePackageLocationMutationVariables
>;

/**
 * __useUpdatePackageLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePackageLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageLocationMutation, { data, loading, error }] = useUpdatePackageLocationMutation({
 *   variables: {
 *      packageLocation: // value for 'packageLocation'
 *   },
 * });
 */
export function useUpdatePackageLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePackageLocationMutation, UpdatePackageLocationMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePackageLocationMutation, UpdatePackageLocationMutationVariables>(
        UpdatePackageLocationDocument,
        options
    );
}
export type UpdatePackageLocationMutationHookResult = ReturnType<typeof useUpdatePackageLocationMutation>;
export type UpdatePackageLocationMutationResult = Apollo.MutationResult<UpdatePackageLocationMutation>;
export type UpdatePackageLocationMutationOptions = Apollo.BaseMutationOptions<
    UpdatePackageLocationMutation,
    UpdatePackageLocationMutationVariables
>;
export const RemovePackageFileDocument = gql`
    mutation removePackageFile($packageId: String!) {
        removePackageFile(packageId: $packageId) {
            success
        }
    }
`;
export type RemovePackageFileMutationFn = Apollo.MutationFunction<
    RemovePackageFileMutation,
    RemovePackageFileMutationVariables
>;

/**
 * __useRemovePackageFileMutation__
 *
 * To run a mutation, you first call `useRemovePackageFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePackageFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePackageFileMutation, { data, loading, error }] = useRemovePackageFileMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useRemovePackageFileMutation(
    baseOptions?: Apollo.MutationHookOptions<RemovePackageFileMutation, RemovePackageFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemovePackageFileMutation, RemovePackageFileMutationVariables>(
        RemovePackageFileDocument,
        options
    );
}
export type RemovePackageFileMutationHookResult = ReturnType<typeof useRemovePackageFileMutation>;
export type RemovePackageFileMutationResult = Apollo.MutationResult<RemovePackageFileMutation>;
export type RemovePackageFileMutationOptions = Apollo.BaseMutationOptions<
    RemovePackageFileMutation,
    RemovePackageFileMutationVariables
>;
export const GetProfileDocument = gql`
    query getProfile($id: String!) {
        getProfile(id: $id) {
            success
            message
            result {
                ...UserInfo
            }
        }
    }
    ${UserInfoFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($filters: InputPagingRequest) {
        getUsers(filters: $filters) {
            success
            message
            result {
                docs {
                    ...UserWithAuthorizationInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${UserWithAuthorizationInfoFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: InputUserCreate!) {
        createUser(user: $user) {
            success
            message
            result {
                ...UserInfo
            }
        }
    }
    ${UserInfoFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($user: InputUserUpdate!) {
        updateUser(user: $user) {
            success
            message
            result {
                ...UserInfo
            }
        }
    }
    ${UserInfoFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($user: InputUserId!) {
        deleteUser(user: $user) {
            success
            message
            result {
                ...UserInfo
            }
        }
    }
    ${UserInfoFragmentDoc}
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($user: InputUserId!) {
        removeUser(user: $user) {
            success
            message
            result {
                ...UserInfo
            }
        }
    }
    ${UserInfoFragmentDoc}
`;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRemoveUserMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RecoverUserDocument = gql`
    mutation recoverUser($user: InputUserId!) {
        recoverUser(user: $user) {
            success
            message
            result {
                ...UserInfo
            }
        }
    }
    ${UserInfoFragmentDoc}
`;
export type RecoverUserMutationFn = Apollo.MutationFunction<RecoverUserMutation, RecoverUserMutationVariables>;

/**
 * __useRecoverUserMutation__
 *
 * To run a mutation, you first call `useRecoverUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverUserMutation, { data, loading, error }] = useRecoverUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRecoverUserMutation(
    baseOptions?: Apollo.MutationHookOptions<RecoverUserMutation, RecoverUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RecoverUserMutation, RecoverUserMutationVariables>(RecoverUserDocument, options);
}
export type RecoverUserMutationHookResult = ReturnType<typeof useRecoverUserMutation>;
export type RecoverUserMutationResult = Apollo.MutationResult<RecoverUserMutation>;
export type RecoverUserMutationOptions = Apollo.BaseMutationOptions<RecoverUserMutation, RecoverUserMutationVariables>;
export const GetWritersDocument = gql`
    query getWriters($filters: InputPagingRequest) {
        getWriters(filters: $filters) {
            success
            message
            result {
                docs {
                    ...WriterInfo
                }
                totalDocs
                limit
                hasPrevPage
                hasNextPage
                page
                totalPages
                offset
                prevPage
                nextPage
                pagingCounter
                meta
            }
        }
    }
    ${WriterInfoFragmentDoc}
`;

/**
 * __useGetWritersQuery__
 *
 * To run a query within a React component, call `useGetWritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWritersQuery(baseOptions?: Apollo.QueryHookOptions<GetWritersQuery, GetWritersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWritersQuery, GetWritersQueryVariables>(GetWritersDocument, options);
}
export function useGetWritersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWritersQuery, GetWritersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWritersQuery, GetWritersQueryVariables>(GetWritersDocument, options);
}
export type GetWritersQueryHookResult = ReturnType<typeof useGetWritersQuery>;
export type GetWritersLazyQueryHookResult = ReturnType<typeof useGetWritersLazyQuery>;
export type GetWritersQueryResult = Apollo.QueryResult<GetWritersQuery, GetWritersQueryVariables>;
export const GetWriterDocument = gql`
    query getWriter($writerId: String!) {
        getWriter(writerId: $writerId) {
            success
            message
            result {
                ...WriterInfo
            }
        }
    }
    ${WriterInfoFragmentDoc}
`;

/**
 * __useGetWriterQuery__
 *
 * To run a query within a React component, call `useGetWriterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWriterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWriterQuery({
 *   variables: {
 *      writerId: // value for 'writerId'
 *   },
 * });
 */
export function useGetWriterQuery(baseOptions: Apollo.QueryHookOptions<GetWriterQuery, GetWriterQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWriterQuery, GetWriterQueryVariables>(GetWriterDocument, options);
}
export function useGetWriterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWriterQuery, GetWriterQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWriterQuery, GetWriterQueryVariables>(GetWriterDocument, options);
}
export type GetWriterQueryHookResult = ReturnType<typeof useGetWriterQuery>;
export type GetWriterLazyQueryHookResult = ReturnType<typeof useGetWriterLazyQuery>;
export type GetWriterQueryResult = Apollo.QueryResult<GetWriterQuery, GetWriterQueryVariables>;
export const CreateWriterDocument = gql`
    mutation createWriter($writer: InputWriterCreate!) {
        createWriter(writer: $writer) {
            success
            message
            result {
                ...WriterInfo
            }
        }
    }
    ${WriterInfoFragmentDoc}
`;
export type CreateWriterMutationFn = Apollo.MutationFunction<CreateWriterMutation, CreateWriterMutationVariables>;

/**
 * __useCreateWriterMutation__
 *
 * To run a mutation, you first call `useCreateWriterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWriterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWriterMutation, { data, loading, error }] = useCreateWriterMutation({
 *   variables: {
 *      writer: // value for 'writer'
 *   },
 * });
 */
export function useCreateWriterMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateWriterMutation, CreateWriterMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateWriterMutation, CreateWriterMutationVariables>(CreateWriterDocument, options);
}
export type CreateWriterMutationHookResult = ReturnType<typeof useCreateWriterMutation>;
export type CreateWriterMutationResult = Apollo.MutationResult<CreateWriterMutation>;
export type CreateWriterMutationOptions = Apollo.BaseMutationOptions<
    CreateWriterMutation,
    CreateWriterMutationVariables
>;
export const UpdateWriterDocument = gql`
    mutation updateWriter($writer: InputWriterUpdate!) {
        updateWriter(writer: $writer) {
            success
            message
            result {
                ...WriterInfo
            }
        }
    }
    ${WriterInfoFragmentDoc}
`;
export type UpdateWriterMutationFn = Apollo.MutationFunction<UpdateWriterMutation, UpdateWriterMutationVariables>;

/**
 * __useUpdateWriterMutation__
 *
 * To run a mutation, you first call `useUpdateWriterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWriterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWriterMutation, { data, loading, error }] = useUpdateWriterMutation({
 *   variables: {
 *      writer: // value for 'writer'
 *   },
 * });
 */
export function useUpdateWriterMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateWriterMutation, UpdateWriterMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateWriterMutation, UpdateWriterMutationVariables>(UpdateWriterDocument, options);
}
export type UpdateWriterMutationHookResult = ReturnType<typeof useUpdateWriterMutation>;
export type UpdateWriterMutationResult = Apollo.MutationResult<UpdateWriterMutation>;
export type UpdateWriterMutationOptions = Apollo.BaseMutationOptions<
    UpdateWriterMutation,
    UpdateWriterMutationVariables
>;
export const DeleteWritersDocument = gql`
    mutation deleteWriters($writerIds: [String]!) {
        deleteWriters(writerIds: $writerIds) {
            success
            message
        }
    }
`;
export type DeleteWritersMutationFn = Apollo.MutationFunction<DeleteWritersMutation, DeleteWritersMutationVariables>;

/**
 * __useDeleteWritersMutation__
 *
 * To run a mutation, you first call `useDeleteWritersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWritersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWritersMutation, { data, loading, error }] = useDeleteWritersMutation({
 *   variables: {
 *      writerIds: // value for 'writerIds'
 *   },
 * });
 */
export function useDeleteWritersMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteWritersMutation, DeleteWritersMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteWritersMutation, DeleteWritersMutationVariables>(DeleteWritersDocument, options);
}
export type DeleteWritersMutationHookResult = ReturnType<typeof useDeleteWritersMutation>;
export type DeleteWritersMutationResult = Apollo.MutationResult<DeleteWritersMutation>;
export type DeleteWritersMutationOptions = Apollo.BaseMutationOptions<
    DeleteWritersMutation,
    DeleteWritersMutationVariables
>;
export const UploadWriterFileDocument = gql`
    mutation uploadWriterFile($file: [Upload]!, $writerId: String!) {
        uploadWriterFile(file: $file, writerId: $writerId) {
            success
        }
    }
`;
export type UploadWriterFileMutationFn = Apollo.MutationFunction<
    UploadWriterFileMutation,
    UploadWriterFileMutationVariables
>;

/**
 * __useUploadWriterFileMutation__
 *
 * To run a mutation, you first call `useUploadWriterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWriterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWriterFileMutation, { data, loading, error }] = useUploadWriterFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      writerId: // value for 'writerId'
 *   },
 * });
 */
export function useUploadWriterFileMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadWriterFileMutation, UploadWriterFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadWriterFileMutation, UploadWriterFileMutationVariables>(
        UploadWriterFileDocument,
        options
    );
}
export type UploadWriterFileMutationHookResult = ReturnType<typeof useUploadWriterFileMutation>;
export type UploadWriterFileMutationResult = Apollo.MutationResult<UploadWriterFileMutation>;
export type UploadWriterFileMutationOptions = Apollo.BaseMutationOptions<
    UploadWriterFileMutation,
    UploadWriterFileMutationVariables
>;
export const RemoveWriterFileDocument = gql`
    mutation removeWriterFile($writerId: String!) {
        removeWriterFile(writerId: $writerId) {
            success
        }
    }
`;
export type RemoveWriterFileMutationFn = Apollo.MutationFunction<
    RemoveWriterFileMutation,
    RemoveWriterFileMutationVariables
>;

/**
 * __useRemoveWriterFileMutation__
 *
 * To run a mutation, you first call `useRemoveWriterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWriterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWriterFileMutation, { data, loading, error }] = useRemoveWriterFileMutation({
 *   variables: {
 *      writerId: // value for 'writerId'
 *   },
 * });
 */
export function useRemoveWriterFileMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveWriterFileMutation, RemoveWriterFileMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveWriterFileMutation, RemoveWriterFileMutationVariables>(
        RemoveWriterFileDocument,
        options
    );
}
export type RemoveWriterFileMutationHookResult = ReturnType<typeof useRemoveWriterFileMutation>;
export type RemoveWriterFileMutationResult = Apollo.MutationResult<RemoveWriterFileMutation>;
export type RemoveWriterFileMutationOptions = Apollo.BaseMutationOptions<
    RemoveWriterFileMutation,
    RemoveWriterFileMutationVariables
>;
