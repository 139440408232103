import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Breadcrumb } from 'antd';

import BreadcrumbRoutes, { IBreadcrumbsRoute, IBreadcrumbs } from './BreadcrumbRoutes';
import styles from './BreadcrumbLayout.module.scss';

const { Title } = Typography;

const BreadcrumbLayout = () => {
    const { pathname } = useLocation();
    const currentBreadCrumb = BreadcrumbRoutes.find((crumb: IBreadcrumbsRoute) => pathname.includes(crumb.key));
    return (
        <div className={styles.wrapper}>
            <Breadcrumb>
                {currentBreadCrumb?.breadcrumbs?.map((breadCrumb: IBreadcrumbs) => (
                    <Breadcrumb.Item key={breadCrumb.key}>
                        {breadCrumb.href ? <Link to={breadCrumb.href}>{breadCrumb.title}</Link> : breadCrumb.title}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
            <Title level={4} style={{ marginTop: 16 }}>
                {currentBreadCrumb?.breadcrumbs?.slice(-1)[0]?.title}
            </Title>
        </div>
    );
};

export default BreadcrumbLayout;
