import config from '../config';

export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_TIME_FORMAT = 'hh:mm:ss';
export const DEFAULT_TIME_FORMAT_SHORT_12 = 'hh:mm';
export const DEFAULT_TIME_FORMAT_SHORT_24 = 'HH:mm';
export const DEFAULT_DATE_FORMAT_SERVER = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT_SERVER = 'HH:mm:ss';

export const DAY_LIST = [
    { label: 'T2', value: 'Monday' },
    { label: 'T3', value: 'Tuesday' },
    { label: 'T4', value: 'Wednesday' },
    { label: 'T5', value: 'Thursday' },
    { label: 'T6', value: 'Friday' },
    { label: 'T7', value: 'Saturday' },
    { label: 'CN', value: 'Sunday' },
];

export enum roleTypes {
    ADMIN = 'ADMIN',
    CUSTOMER = 'CUSTOMER',
}

export const MEDIAS = [
    { id: 'newspaper', name: 'Newspaper' },
    { id: 'magazine', name: 'Magazine' },
    { id: 'television', name: 'Television' },
    { id: 'broadcast', name: 'Broadcast' },
    { id: 'new_media', name: 'New Media' },
    { id: 'website', name: 'Website' },
    { id: 'weibo', name: 'Weibo' },
    { id: 'wechat_public_account', name: 'WeChat public account' },
    { id: 'headline_number', name: 'Headline number' },
    { id: 'twitter', name: 'Twitter' },
    { id: 'facebook', name: 'Facebook' },
    { id: 'youtube', name: 'YouTube' },
    { id: 'linkedin', name: 'LinkedIn' },
];

export const STATUS = {
    completed: 'Completed',
    process: 'Processing',
    failed: 'Failed',
};

export const SOCIAL = {
    facebook: 'https://www.facebook.com/FortuneTimesSg',
    linkedIn: 'https://www.linkedin.com/company/53270083',
    mail: 'mailto:info@fortunetimes.sg',
    weChat: 'https://u.wechat.com/kIUPWn3wZwArhreTckXamVQ',
    shareData: {
        title: 'Tuihand',
        text: 'The most representative news planning expert in the Asia-Pacific region',
        url: config.CLIENT_HTTP_URI,
    },
};

export const RECAPTCHA = {
    key: config.RECAPTCHA_KEY,
    url: `${config.RECAPTCHA_URL}${config.RECAPTCHA_KEY}`,
};

export const newsCategory = [
    'Political',
    'Finance',
    'Economics',
    'Sports',
    'Property',
    'Entertainment',
    'Automotive',
    'Fashion',
    'Scientific',
    'Food',
];

export const CAPTCHA_EXCLUSION = ['http://8.214.21.181:8000'];
