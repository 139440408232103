export const routes = {
    root: () => `/`,
    admin: {
        login: () => `/admin/login`,
        content: () => `/admin/content`,
        registrationManagement: {
            business: {
                list: () => `/admin/business-registration/list`,
                detail: () => `/admin/business-registration/detail`,
                new: () => `/admin/business-registration/new`,
            },
            media: {
                list: () => `/admin/media-registration/list`,
                detail: () => `/admin/media-registration/detail`,
                new: () => `/admin/media-registration/new`,
            },
            writer: {
                list: () => `/admin/writer-registration/list`,
                detail: () => `/admin/writer-registration/detail`,
                new: () => `/admin/writer-registration/new`,
            },
            user: {
                list: () => `/admin/user-registration/list`,
                detail: () => `/admin/user-registration/detail`,
                new: () => `/admin/user-registration/new`,
            },
        },
        orderManagement: {
            list: () => `/admin/order/list`,
            detail: () => `/admin/order/detail`,
            edit: () => `/admin/order/edit`,
        },
        packageManagement: {
            list: () => `/admin/package/list`,
            detail: () => `/admin/package/detail`,
            edit: () => `/admin/package/edit`,
            new: () => `/admin/package/new`,
        },
        packageLocationManagement: {
            list: () => `/admin/package-location/list`,
        },
        moderateComments: {
            list: () => `/admin/moderate-comments/list`,
            new: () => `/admin/moderate-comments/new`,
        },
    },
    user: {
        login: () => `/login`,
        facebookCallback: () => `/auth/facebook/callback`,
        linkedinCallback: () => `/auth/linkedin/callback`,
        register: () => `/register`,
        confirmAccount: () => `/confirm-account`,
        resetPassword: () => `/reset-password`,
        changeEmail: () => `/change-email`,
        resetEmail: () => `/reset-email`,
        homepage: () => `/homepage`,
        contact: () => `/contact`,
        company: () => `/company`,
        account: () => `/account`,
        order: {
            detail: () => `/order/detail`,
        },
        package: {
            detail: () => `/package/detail`,
            list: () => `/package/list`,
            customize: () => `/package/customize`,
        },
        media: () => `/media`,
        writer: () => `/writer`,
        payment: () => `/payment`,
        paypalCallback: () => `/payment/paypal/callback`,
        cart: () => `/cart`,
        speech: () => `/speech`,
    },
};
