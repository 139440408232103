import { InMemoryCache, makeVar } from '@apollo/client';

const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                auth: {
                    read() {
                        return authVar();
                    },
                },
            },
        },
    },
});

export const authVar = makeVar<any>({ isLoggedIn: false });

export default cache;
