import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
    DashboardOutlined,
    FileSearchOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    BookOutlined,
} from '@ant-design/icons';
import cn from 'classnames';

import { routes } from 'routes';

import styles from './Sidebar.module.scss';

const { SubMenu } = Menu;
const { Sider } = Layout;
interface IMenu {
    key: string;
    icon?: React.ComponentType<any>;
    href?: string;
    title: string;
    children?: Array<IMenu>;
}

const menus: IMenu[] = [
    {
        key: '1',
        icon: DashboardOutlined,
        href: routes.admin.content(),
        title: 'Content management',
    },
    {
        key: '2',
        icon: TeamOutlined,
        title: 'Registration management',
        children: [
            {
                key: '3',
                href: routes.admin.registrationManagement.business.list(),
                title: 'Business Registration',
            },
            {
                key: '4',
                href: routes.admin.registrationManagement.writer.list(),
                title: 'Writer Registration',
            },
            {
                key: '5',
                href: routes.admin.registrationManagement.media.list(),
                title: 'Media Registration',
            },
            {
                key: '6',
                href: routes.admin.registrationManagement.user.list(),
                title: 'User Registration',
            },
        ],
    },
    {
        key: '7',
        icon: BookOutlined,
        href: routes.admin.orderManagement.list(),
        title: 'Order management',
    },
    {
        key: '8',
        icon: UnorderedListOutlined,
        href: routes.admin.packageManagement.list(),
        title: 'Package management',
    },
    {
        key: '9',
        icon: UnorderedListOutlined,
        href: routes.admin.packageLocationManagement.list(),
        title: 'Package location management',
    },
    {
        key: '10',
        icon: FileSearchOutlined,
        title: 'Moderate Comments',
        children: [
            {
                key: '11',
                href: routes.admin.moderateComments.list(),
                title: 'Comments List',
            },
            {
                key: '12',
                href: routes.admin.moderateComments.new(),
                title: 'New Comments',
            },
        ],
    },
];

export interface SidebarProps {
    collapsed: boolean;
}

const pathFilter = ['', 'admin', 'list', 'new', 'detail'];

const getPathCategory = (path: string) => {
    if (!path) return path;
    const splitPath = path.split('/');
    const filteredPath = splitPath.filter((path) => !pathFilter.includes(path));
    return filteredPath[0];
};

const Sidebar: FunctionComponent<SidebarProps> = ({ collapsed }) => {
    const { pathname } = useLocation();
    const pathCategory = getPathCategory(pathname);
    const menuActive = [
        ...menus,
        ...menus
            .filter((menu) => Boolean(menu.children))
            .map((menu) => menu.children)
            .flat(),
    ].find((menu?: any) => {
        const correctCategory = getPathCategory(menu.href) === pathCategory;
        if (pathCategory === 'moderate-comments') {
            if (correctCategory && pathname?.includes('list') && menu?.href?.includes('list')) {
                return true;
            } else if (correctCategory && pathname?.includes('new') && menu?.href?.includes('new')) {
                return true;
            }
            return false;
        }
        return correctCategory;
    });
    const currentActiveParent = menus.find((menu) =>
        !menu.children ? false : !!menu.children.find((child) => (!menuActive ? false : child.key === menuActive.key))
    );
    const selectedKeys = menuActive ? [menuActive.key] : undefined;

    return (
        <Sider width={300} breakpoint="lg" trigger={null} collapsible collapsed={collapsed} className="admin-sider">
            <div className={styles.brand}>
                {collapsed ? <div className={styles.spacer} /> : <div className={styles.name}>TuiHAND</div>}
            </div>
            <Menu
                mode="inline"
                defaultSelectedKeys={[menus[0].key]}
                defaultOpenKeys={[currentActiveParent ? currentActiveParent.key : '']}
                selectedKeys={selectedKeys}
                className={cn('admin-menu', styles.menu)}
            >
                {menus.map(({ icon: Icon, children, ...item }) => {
                    if (!children) {
                        return (
                            <Menu.Item key={item.key} icon={Icon && <Icon />}>
                                <Link to={item?.href || `#`}>{item.title}</Link>
                            </Menu.Item>
                        );
                    } else {
                        return (
                            <SubMenu key={item.key} icon={Icon && <Icon />} title={item.title}>
                                {children.map(({ icon: Icon, ...item }) => {
                                    return (
                                        <Menu.Item key={item.key} icon={Icon && <Icon />}>
                                            <Link to={item?.href || `#`}>{item.title}</Link>
                                        </Menu.Item>
                                    );
                                })}
                            </SubMenu>
                        );
                    }
                })}
            </Menu>
        </Sider>
    );
};

export default Sidebar;
