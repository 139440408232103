import React, { FunctionComponent } from 'react';
import { Layout, Button, notification, Popover } from 'antd';
import { LinkedinFilled, FacebookFilled, MailFilled, ShareAltOutlined, WechatFilled } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import Content from 'components/common/WeChatPopoverContent';
import { SOCIAL } from 'constants/index';
import { localS } from 'utils/helpers';
import { routes } from 'routes';
import logo from 'assets/images/logo_white.png';

import styles from './Footer.module.scss';

const Footer: FunctionComponent = () => {
    const history = useHistory();

    const _handlePressRelease = () => {
        if (localS.get('business-registration')) {
            notification.warn({
                message: 'You already finished register business information.',
            });
        } else {
            history.push(routes.user.company());
        }
    };

    const _handleShareAlt = () => {
        if (navigator.share) {
            navigator.share(SOCIAL.shareData);
        } else {
            window.location.href = SOCIAL.shareData.url;
        }
    };

    return (
        <Layout.Footer className={cn(styles.footer, { [styles.mobile]: isMobile })}>
            <div className={styles.top}>
                <div className={styles.left}>
                    <div className={styles.content}>
                        <img className={styles.logo} src={logo} />
                    </div>
                    <div className={styles.content}>
                        The most comprehensive journalist and online media publishing network in the Asia-Pacific region
                    </div>
                    <div className={styles.content}>
                        <Button
                            type="primary"
                            shape="round"
                            className={styles.startNow}
                            size="large"
                            onClick={_handlePressRelease}
                        >
                            Start Now
                        </Button>
                    </div>
                </div>
                <div className={styles.center}>
                    <div className={styles.title}>Contact Info</div>
                    <div className={styles.content}>Fortune Times Pte Ltd, 53 Cantonment Road, Singapore 089753</div>
                    <a>Google Map</a>
                    <div className={styles.content}>
                        T // 63364236
                        <br />E // info@FortuneTimes.sg
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.title}>Working Hours</div>
                    <div className={styles.content}>
                        Mon-Fri: 8am-5pm
                        <br />
                        Weekend: 8am-4pm
                    </div>

                    <div className={styles.title}>Follow Us</div>
                    <div className={styles.content}>
                        <Popover placement="bottomRight" content={Content} trigger="click">
                            <a>
                                <WechatFilled className={styles.socialItem} />
                            </a>
                        </Popover>

                        <a href={SOCIAL.linkedIn} target="_blank" rel="noreferrer">
                            <LinkedinFilled className={styles.socialItem} />
                        </a>
                        <a href={SOCIAL.facebook} target="_blank" rel="noreferrer">
                            <FacebookFilled className={styles.socialItem} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div>
                    &copy; 2021 FortuneTimes | <a>Privacy Policy</a> | <a>Terms & Conditions</a> |{' '}
                    <Link to={routes.user.contact()} className={styles.topLink}>
                        Contact Us
                    </Link>
                </div>
                <br />
                <div className={styles.social}>
                    <Popover placement="top" content={Content} trigger="click">
                        <a>
                            <WechatFilled className={styles.socialItem} />
                        </a>
                    </Popover>
                    <a href={SOCIAL.linkedIn} target="_blank" rel="noreferrer">
                        <LinkedinFilled className={styles.socialItem} />
                    </a>
                    <a href={SOCIAL.facebook} target="_blank" rel="noreferrer">
                        <FacebookFilled className={styles.socialItem} />
                    </a>
                    <a href={SOCIAL.mail} target="_blank" rel="noreferrer">
                        <MailFilled className={styles.socialItem} />
                    </a>
                    <a href={SOCIAL.shareData.url} onClick={_handleShareAlt} target="_blank" rel="noreferrer">
                        <ShareAltOutlined className={styles.socialItem} />
                    </a>
                </div>
            </div>
        </Layout.Footer>
    );
};

export default Footer;
