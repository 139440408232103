import React, { FunctionComponent } from 'react';
import { Avatar, Dropdown, Layout, Menu, Space, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { localS } from 'utils/helpers';
import { useAuth } from 'utils/hooks';
import { useLogoutMutation } from 'services/graphql/generated';
import styles from './Header.module.scss';

export interface HeaderProps {
    collapsed: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({ collapsed }) => {
    const [auth, setAuth] = useAuth();
    const [hookLogout] = useLogoutMutation({
        onCompleted() {
            localS.clear();
            setAuth({ isLoggedIn: false });
            notification.success({ message: 'Logout successfully.' });
        },
        onError: () => {
            notification.error({ message: 'Something happened!' });
        },
    });

    const menu = (
        <Menu>
            <Menu.Item danger onClick={() => hookLogout()}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header className={`${styles.header} ${styles.fixed} ${collapsed && styles.collapsed}`}>
            <div className={styles.wrapper}>
                <Dropdown overlay={menu} overlayStyle={{ width: 120, minWidth: 120 }} placement="bottomRight">
                    <span>
                        <Space>
                            <Avatar src={auth?.user?.avatar} icon={<UserOutlined />} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
                                <span style={{ fontSize: 12, lineHeight: '16px' }}>{auth?.user?.fullName}</span>
                                <span style={{ fontSize: 12, lineHeight: '16px' }}>ID: {auth?.user?.id}</span>
                            </div>
                        </Space>
                    </span>
                </Dropdown>
            </div>
        </Layout.Header>
    );
};

export default Header;
